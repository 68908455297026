export const GET_BANNERS_BY_PAGE = `
query GetBanner($page:String!){
    bannersList(filter:{
      page:{
        pageId:{
          _expressions:{
            value:$page
          }
        }
      }
    }){
      items{
        page {
          _path
          _variation
          pageId
          pageName
          isActive
        }
        title{
          html
          plaintext
        }
        
        subtitle{
          html
          plaintext
        }
        image{
          ... on ImageRef{
            _authorUrl
            _publishUrl
          }
        }
        description1{
          html
          plaintext
        }
        description2{
          html
          plaintext
        }
        referenceLinkTitle
        referenceLinkUrl
      }
    }
  }`

export default {
    GET_BANNERS_BY_PAGE
}