export const setLocalStorage = (key, value) => {
  window.localStorage.setItem(key, value);
}

export const getLocalStorage = (key) => {
  let item = window.localStorage.getItem(key) !== null;
  return item && window.localStorage.getItem(key);
}

export const removeLocalStorage = (key) => {
  window.localStorage.removeItem(key);
}

export const urlToDiv = () => {
  let hash = window.location?.hash;
  if (!hash) {
    window.scrollTo(0, 0);
  }
  else {
    window.scrollTo(0, 0);
    const scrollTosection = document.querySelector(hash);
    if (scrollTosection) {
      scrollTosection.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
    }
  }
}


const isHEX = (ch) => "0123456789abcdef".includes(ch.toLowerCase());

export const isGuidValid = (guid) => {
  guid = guid.replaceAll("-", "");
  return guid.length === 32 && [...guid].every(isHEX);
};

export const parseFileResponseAndDownload = async (response) => {
  let fileName = null;
  const contentDisposition = response.headers["content-disposition"];
  if (contentDisposition) {
    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = fileNameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, "");
    }
  }

  if (fileName) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  }
}

export const replaceBreakWithNextline = (str) => {
  if(str?.includes('<br />')) {
    return str?.replaceAll('<br />', '\n').trim().split('\n')
  }
  else if(str?.includes('\n\n')) {
    return str?.replaceAll('\n\n', '\n').trim().split('\n')
  }
  else {
    return str?.trim().split('\n')
  }

}