import React, { useEffect, useState, createRef } from "react";
import { Col, Modal, ModalHeader, Row } from "reactstrap";
import Banner from "../../components/banner/Banner.Component";
import CardComponent from "../../components/cards/Card.Component";
import StorefrontMenuComponent from "../../components/storefrontmenu/StorefrontMenu.Component";
import ErrorBoundary from "../../components/error-boundary/ErrorBoundary";
import ModalComponent from "../../components/modal/Modal.Component";
import CapabilityDrawer from "../../components/CapabilityDrawer/CapabilityDrawer.component";
import { useNavigate } from "react-router";
import {
  storefrontScreenTabs,
  storefrontViewScreenTabs,
  storefrontTechnology,
} from "../../constants/commonConstants";
import { emptyCapabilities, Group } from "../../constants/images";
import Close from "../../assets/images/common/closeFeedback.png";

import "./StoreFront.scss";
import { POST_CALL, POST_GRAPHQL , PUT_CALL} from "../../services/httpService";
import {
  removeHypensWithSpace,
  sortByKey,
  getCurrentMonthName,
  getCurrentYear,
  getCurrentMonthNameFromDate,
  getCurrentYearFromDate,
  removeSpaceWithHypens,
} from "../../utils";
import {
  SubSectorsQuery,
  TechEnablersQuery,
} from "../../services/graphql-queries";
import { useLocation, useParams} from "react-router-dom";
import { download_icon } from "../../constants/images";
import Loader from "../../components/loader";
import html2canvas from "html2canvas";
import ErrorContent from "../../components/error-content";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveDataCard,
  setActiveValuechain,
  setBVMaturityList,
  setCurrentSubSector,
  setDapMaturityList,
  setDCMaturityList,
  setEcoMaturityList,
  setMaturityList,
  setNewCapabilityList,
  setNewBvMaturityList,
  setNewTechMaturityList,
  setNewDapMaturityList,
  setNewEcoMaturityList,
  setStorefrontActiveTab,
  setStorefrontViewTab,
  setTechMaturityList,
  toggleTheModal,
  setActiveSubsectorSpecificCapabilities,
  setActiveValueChainSpecificCapabilities,
  setActiveSubsectorSpecificCapabilitiesDescriptions,
  setActiveSubsectorSpecificCapabilitiesBenefits,
  setBookmarkList,
  setSubsectorList,
  setIsBookmarkCreated
} from "../../store/actions/storefrontAction";
import { GET_CAMPAIGN_BY_INDUSTRY } from "../../services/graphql-queries/techEnablers";
import { getLocalStorage, setLocalStorage } from "../../utils/utilities";
import { actionTracking } from "../../utils/omniture";
import { pageNames } from "../../constants/omnitureConstants";
import Filter from "../../components/filter/Filter.Component";
import ValueChainCard from "../../components/ValueChainCard/ValueChainCard.Component";
import CoverPageTemplate from "../../components/exportDigiprintTemplates/CoverPageTemplate.Component";
import { BOOKMARK_API_URL } from "../../constants/apiConstants";
import { notification } from "antd";

function StoreFront(props) {
  const [error, setError] = useState(false);
  const [showLeft, setShowLeft] = useState(false);
  const [coverTemplateImage, setCoverTemplateImage]=useState();
  
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navId = document.getElementById('navbar');
  navId?.classList?.remove('navbarTransparent');
  navId?.classList?.add('navbar')

  const sfReducer = useSelector((state) => ({
    subsectorSpecificCapabilities: state.storefrontReducer.subsectorSpecificCapabilities,
    valuechainSpecificCapabilities: state.storefrontReducer.valueChainSpecificCapabilities,
    capabilityDescriptions: state.storefrontReducer.subsectorSpecificCapabilitiesDescriptions,
    benefitsOrValue: state.storefrontReducer.subsectorSpecificCapabilitiesBenefits,
    subsectorList: state.storefrontReducer.subsectorList,
    currentSubsector: state.storefrontReducer.currentSubSector,
    item: state.storefrontReducer
}))

  let selectedCapabilityList = sfReducer.item.capabilityList;
  let bookmarkList = sfReducer.item.bookmarkList;
  let isBookmarkCreated = sfReducer.item.isBookmarkCreated;
  const [api, bookmarkMsg] = notification.useNotification();

  const totalCapabilities = 10;
  const totalSelected = ()=>{
    let totalCount = 0;
    selectedCapabilityList.forEach(valueChain =>{
      valueChain.capabilityList.forEach(() => totalCount++)
    }
    );
    return totalCount;
  }
  const navReducer = useSelector((state) => ({
    email: state.navigationReducer.userEmail,
  }));

  useEffect(() => {
    if (navReducer.email !== "") {
      // pageView(pageNames.STOREFRONT_PAGE, navReducer.email);
    }
  }, [navReducer.email]);

  const ref = createRef(null);
  const [currentStorefront, setCurrentStorefront] = useState({});
  const { subSectorName } = useParams();
  const [loading, setLoading] = useState(false);
  const [storeFrontLoading, setStoreFrontLoading] = useState(false);
  const [subSector, setSubSector] = useState({});
  const [valuChainData, setValuChainData] = useState([]);
  let updatedPathInput = {}
  const [updateDateValue, setUpdatedDateValue] = useState("Updated ...")
  useEffect(() => {

  }, [updateDateValue])

  const download = async (image, { name = "img", extension = "jpg" } = {}) => {
    if (!props.accessToken) {
      return null;
    }

    const apiHeaders = new Headers();
    apiHeaders.append("Authorization", "Bearer " + props.accessToken);
    apiHeaders.append("Content-type", "application/json");
    image = image.split("data:image/jpeg;base64,")[1];

    let apiData = { id: 0, imageBase: image };

    const options = {
      method: "POST",
      headers: apiHeaders,
      body: JSON.stringify(apiData),
    };
    let userDetailApiUrl =
      window.CONFIG.WEBAPI.base_url + "/api/pdf/screenshot";
    let pdfFileName = "";
    fetch(userDetailApiUrl, options)
      .then((response) => {
        pdfFileName = response.headers
          .get("content-disposition")
          .split(";")
          .find((n) => n.includes("filename="))
          .replace("filename=", "")
          .trim();
        return response.blob();
      })
      .then((blobObj) => {
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blobObj);
        link.download = pdfFileName;
        link.click();
      })
      .catch((error) => {
        setError(true);
        console.log("Error", error);
      });
  };

  const downloadScreenshot = () => {
    window.scrollTo(0, 0);
    document.getElementById("download-btn").style.visibility = "hidden";
    setLoading(true);
    html2canvas(document.body,
      {
        height: document.documentElement.scrollHeight,
        scrollX: 0,
        scrollY: 0
      }
    ).then(async (canvas) => {
      let img = canvas.toDataURL("image/jpeg");
      await download(img);
      setLoading(false);
      let elemDownloadBtn = document.getElementById("download-btn");
      if (elemDownloadBtn) {
        elemDownloadBtn.style.visibility = "visible";
      }
    });
  };

  const setNewCapability = (newCapabilityList)=>{
    dispatch(setNewCapabilityList(newCapabilityList));
  }

  const setUpdatedBookmarkList =  async (bookmarkItem)=>{
    let foundBookmarkIndex=-1;
    const newBookmarkList =  bookmarkList.map((item,index)=>{
      if(item.subsectorName===bookmarkItem.subsectorName) foundBookmarkIndex = index;
        return item;
    });

    foundBookmarkIndex === -1 && api.open({
      message: "Storefront has been bookmarked to 'My DigiPrint'",
      placement: "bottom",
      style: {backgroundColor: '#26890D', color: 'white', width:'430px',fontSize:'14px', fontStyle:'normal', fontWeight:'600', fontFamily:'Open Sans', height:'48px', borderRadius:'0px',
       padding:'13px 14px 15px 20px'},
      duration: '2',
      // btn: <img src={Close} alt="close"/>
    });

    foundBookmarkIndex >=  0 ? newBookmarkList.splice(foundBookmarkIndex,1) : newBookmarkList.push(bookmarkItem);
    try {
      let response="";
      if(isBookmarkCreated) response = await PUT_CALL(
          BOOKMARK_API_URL, { "userEmailId": navReducer.email, "bookmarkedSubSectorsLink": newBookmarkList.map(bookmarkItem=>bookmarkItem._path)},
          {
              accessToken: props.accessToken,
          },
          {}
      );
      else{
        response = await POST_CALL(
          BOOKMARK_API_URL, { "userEmailId": navReducer.email, "bookmarkedSubSectorsLink": newBookmarkList.map(bookmarkItem=>bookmarkItem._path)},
          {
              accessToken: props.accessToken,
          },
          {}
      );
      }
      if(response && (response.status === 201 || response.status === 200)){
        dispatch(setBookmarkList(newBookmarkList));
        dispatch(setIsBookmarkCreated(true));
      }
    } catch (err) {
      console.log(" download err ", err);
    }
  }

  const removeCapability = (index,valueChainIndex,selectedCapabilities) => {
    if(selectedCapabilities[valueChainIndex].capabilityList.length <= 1){
        selectedCapabilities.splice(valueChainIndex,1);
    } else {
        selectedCapabilities[valueChainIndex].capabilityList.splice(index,1);
    }
    dispatch(setNewCapabilityList(selectedCapabilities));
}

  useEffect(() => {
    if (subSectorName) {
      if (props.accessToken !== undefined) {
        resetDataState();
        dispatch(setCurrentSubSector(subSectorName));
        setLocalStorage("CurrentSubSector", subSectorName);
        Get_Data();
        Get_Enablers_data();
      }
    }
  }, [props.accessToken, location.pathname]);

 

  useEffect(() => {
    dispatch(toggleTheModal(false));
    let currentSubSector = getLocalStorage('CurrentSubSector');
    let currentActiveTab = getLocalStorage('SFActiveTab')
    if (subSectorName === currentSubSector && currentActiveTab === storefrontScreenTabs.SV) {
      dispatch(setStorefrontActiveTab(storefrontScreenTabs.SV));
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
    }, 3000);
  }, [showLeft])

  const resetDataState = () => {
    setSubSector({});
    setValuChainData([]);
  };

  const updateActiveValueChain = (e, enabler) => {
    let tempSet = new Set(sfReducer.item.activeValueChain);

    if (e.target.checked) {
      tempSet.add(enabler.name);
      navReducer.email !== "" &&
        actionTracking(
          pageNames.STOREFRONT_PAGE,
          navReducer.email,
          "Enabler ON: " + enabler.name,
          "Click"
        );
    } else {
      tempSet.delete(enabler.name);
      navReducer.email !== "" &&
        actionTracking(
          pageNames.STOREFRONT_PAGE,
          navReducer.email,
          "Enabler OFF: " + enabler.name,
          "Click"
        );
    }

    dispatch(setActiveValuechain([...tempSet]));
    dispatch(setActiveDataCard([...tempSet]));
  };

  const Get_Data = async () => {
    window.scroll(0, 0);
    setLoading(true);
    try {
      let modifiedSubSectorName = removeHypensWithSpace(subSectorName);
      let responseData = await POST_GRAPHQL(
        SubSectorsQuery.GET_SUB_SECTOR_BY_NAME,
        { subSectorName: modifiedSubSectorName },
        { accessToken: props.accessToken },
        `GET_SUB_SECTOR_BY_NAME_${subSectorName}`
      );

      if (responseData && responseData.data && responseData.data.data) {
        let subsectorList = responseData.data.data.subsectorList.items;
        if (subsectorList && subsectorList?.length > 0) {
          dispatch(setSubsectorList(subsectorList));
          setSubSector(subsectorList[0]);
          GET_CAMPAIGN_DATA(subsectorList[0]);
          Get_Valuchain_Data(subsectorList[0]);
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log("err ", err);
    }
  };

  const Get_Enablers_data = async () => {
    window.scroll(0, 0);
    let currentMaturityList = [];
    try {
      let responseData = await POST_GRAPHQL(
        TechEnablersQuery.GET_ENABLERS_LIST,
        {},
        { accessToken: props.accessToken },
        "GET_ENABLERS_LIST"
      );

      if (responseData && responseData.data && responseData.data.data) {
        let techenablertypeList =
          responseData.data.data.technologyEnablementList.items;

        let deloitteassetList = responseData.data.data.maturityLevelList.items;
        // let deloitteCampaignsList =
        //   responseData.data.data.deloitteCampaignsList.items;
        let ecosystemAccelerationList =
          responseData.data.data.ecosystemAccelerationList.items;
        let businessValueList = responseData.data.data.businessValueList.items;

        if (techenablertypeList && techenablertypeList.length > 0) {
          let displayOrderedTechenablertypeList = sortByKey(
            techenablertypeList,
            "displayOrder"
          );

          dispatch(setTechMaturityList(displayOrderedTechenablertypeList));
          if (sfReducer.item.storefrontViewTab === "Technology Enablement") {
            currentMaturityList = displayOrderedTechenablertypeList;
          }
        }

        if (deloitteassetList && deloitteassetList.length > 0) {
          let displayOrderedDAList = sortByKey(
            deloitteassetList,
            "displayOrder"
          );

          dispatch(setDapMaturityList(displayOrderedDAList));
          if (
            sfReducer.item.storefrontViewTab === "Deloitte’s Asset Portfolio"
          ) {
            currentMaturityList = displayOrderedDAList;
          }
        }

        if (ecosystemAccelerationList && ecosystemAccelerationList.length > 0) {
          let displayOrderedEAList = sortByKey(
            ecosystemAccelerationList,
            "displayOrder"
          );

          dispatch(setEcoMaturityList(displayOrderedEAList));
          if (sfReducer.item.storefrontViewTab === "Ecosystem Acceleration") {
            currentMaturityList = displayOrderedEAList;
          }
        }

        if (businessValueList && businessValueList.length > 0) {
          let displayOrderedbusinessValueList = sortByKey(
            businessValueList,
            "displayOrder"
          );

          dispatch(setBVMaturityList(displayOrderedbusinessValueList));
          if (sfReducer.item.storefrontViewTab === "Business Value") {
            currentMaturityList = displayOrderedbusinessValueList;
          }
        }
        dispatch(setMaturityList(currentMaturityList));
      }
    } catch (err) {
      setError(true);
      console.log("err ", err);
    }
  };

  const Get_Valuchain_Data = async (selectedSubsector) => {
    setStoreFrontLoading(true);
    let orderedValueChainFinal = [];
    try {
      let responseData = await POST_GRAPHQL(
        SubSectorsQuery.GET_SUB_SECTOR_BY_NAME_WITH_VALUCHAIN,
        { subSectorName: selectedSubsector.subsectorName },
        { accessToken: props.accessToken },
        `GET_SUB_SECTOR_BY_NAME_WITH_VALUCHAIN_${selectedSubsector.subsectorName}`
      );
      if (responseData && responseData.data && responseData.data.data) {
        let valuechainList = responseData.data.data.valuechainList.items;
        let capabilityList = responseData.data.data.capabilityList.items;
        if (valuechainList && valuechainList.length > 0) {
          let displayOrderedValueChainList = sortByKey(
            valuechainList,
            "displayOrder"
          );

          let valueChainWithCaps = [];

          displayOrderedValueChainList.forEach((valueChain) => {
            let valueChainTmp = valueChain;
            let capsTemp = [];
            capsTemp = capabilityList.filter(
              (element) =>
                valueChain &&
                valueChain._path &&
                element.valuechain &&
                element.valuechain._path === valueChain._path
            );
            valueChainTmp.capabilities = sortByKey(capsTemp, "displayOrder");
            valueChainWithCaps.push(valueChainTmp);
          });

          orderedValueChainFinal = sortByKey(valueChainWithCaps, "displayOrder");
          setValuChainData(orderedValueChainFinal);

          const subsectorSpecificCapabilities = orderedValueChainFinal.map((item) => {
            return [...item.capabilities];
          })

          const capabilityBusinessValueObj = {}
          const subsectorSpecificCapabilityDescriptions = {}
          const subsectorSpecificCapabilityBenefits = {}

          subsectorSpecificCapabilities.flat().map((capability) => {
            let technologyEnablementArr = []
            capabilityBusinessValueObj[capability.capabilityName] = capability.businessValue.map((item) => item.name)
            capability?.technologyEnablement.map((item) => {
              technologyEnablementArr.push(item.name)
              return false
            })
            subsectorSpecificCapabilityDescriptions[capability.capabilityName] = [capability.capabilityDescription.plaintext,capability.valuechain.valueChainName, technologyEnablementArr, capability.ecoPartnerSolutions]
            subsectorSpecificCapabilityBenefits[capability.capabilityName] = capability.benefitOrValue.plaintext
            return false
          })


          dispatch(setActiveSubsectorSpecificCapabilitiesDescriptions(subsectorSpecificCapabilityDescriptions))
          dispatch(setActiveSubsectorSpecificCapabilities(capabilityBusinessValueObj))
          dispatch(setActiveSubsectorSpecificCapabilitiesBenefits(subsectorSpecificCapabilityBenefits))
        }
      }
      buildUpdateData(orderedValueChainFinal, selectedSubsector)
      setStoreFrontLoading(false);
    } catch (err) {
      setStoreFrontLoading(false);
      setError(true);
      console.log("err ", err);
    }
  };

  const GET_CAPABILITY = async (valueChainName) => {
    try {
      let responseData = await POST_GRAPHQL(
        SubSectorsQuery.GET_CAPS_BY_VALUE_CHAIN,
        { valueChainName: valueChainName },
        { accessToken: props.accessToken },
        `GET_CAPS_BY_VALUE_CHAIN${valueChainName}`
      );

      if (responseData && responseData.data && responseData.data.data) {
        return responseData.data.data.capabilityList.items
      }
      return []
    } catch (e) {

    }
  }

  const buildUpdateData = async (valueChainInputData, selectedSubsector) => {
    if (valueChainInputData.length > 0 || (selectedSubsector && selectedSubsector._path)) {
      let pathArray = [];
      valueChainInputData.forEach(valueChain => {
        if (valueChain._path) {
          pathArray.push(valueChain._path);
        }


        if (valueChain.capabilities && valueChain.capabilities.length > 0) {
          valueChain.capabilities.forEach(capability => {
            if (capability._path) {
              pathArray.push(capability._path);
            }

          });
        }
      })

      if (selectedSubsector && selectedSubsector._path) {
        pathArray.push(selectedSubsector._path)
        updatedPathInput = {
          SubsectorAssetPath: selectedSubsector._path,
          PathList: pathArray
        }

        try {
          let URL = '/api/aem/subsector/updatedinfo';
          let response = await POST_CALL(
            URL, updatedPathInput,
            {
              accessToken: props.accessToken,
            }
          );
          if (response && response.data) {
            let date = response.data.lastPublishedDateTime ? response.data.lastPublishedDateTime : new Date();

            let updateDateString = `Updated ${getCurrentMonthNameFromDate(date)} ${getCurrentYearFromDate(date)} `
            setUpdatedDateValue(prev => updateDateString)
          }

        } catch (e) {
          let updateDateString = `Updated ${getCurrentMonthNameFromDate(new Date())} ${getCurrentYearFromDate(new Date())} `
          setUpdatedDateValue(prev => updateDateString)
        }
      }
    }
  }

  const GET_CAMPAIGN_DATA = async (selectedSubsector) => {
    try {
      if (
        selectedSubsector &&
        selectedSubsector.sector &&
        selectedSubsector.sector.industry &&
        selectedSubsector.sector.industry.industryName
      ) {
        let industryName = selectedSubsector.sector.industry.industryName;

        let responseData = await POST_GRAPHQL(
          GET_CAMPAIGN_BY_INDUSTRY,
          { industryName },
          { accessToken: props.accessToken },
          `GET_CAMPAIGN_BY_INDUSTRY_${industryName} `
        );
        if (responseData && responseData.data && responseData.data.data) {
          let deloitteCampaignsList =
            responseData.data.data.deloitteCampaignsList.items;

          let displayOrderedCampaignsList = sortByKey(
            deloitteCampaignsList,
            "displayOrder"
          );

          dispatch(setDCMaturityList(displayOrderedCampaignsList));
          if (sfReducer.item.storefrontViewTab === "Deloitte Campaigns") {
            dispatch(setMaturityList(displayOrderedCampaignsList));
          }
        }
      }
    } catch (err) {
      setError(true);
      console.log("err ", err);
    }
  };

  const check_maturity_name_selected = (name) => {
    return sfReducer.item.activeDataCard.includes(name);
  };

  const updateSubMenuSelected = (groupName) => {
    dispatch(setStorefrontViewTab(groupName));
    navReducer.email !== "" &&
      actionTracking(
        pageNames.STOREFRONT_PAGE,
        navReducer.email,
        "Maturity Tab: " + groupName
      );

    //update value valuce chain maturity list data
    if (groupName == "Technology Enablement") {
      dispatch(setMaturityList(sfReducer.item.techMaturityList));
    } else if (groupName == "Deloitte’s Asset Portfolio") {
      dispatch(setMaturityList(sfReducer.item.dapMaturityList));
    } else if (groupName == "Business Value") {
      dispatch(setMaturityList(sfReducer.item.bvMaturityList));
    } else if (groupName == "Deloitte Campaigns") {
      dispatch(setMaturityList(sfReducer.item.dcMaturityList));
    } else if (groupName == "Ecosystem Acceleration") {
      dispatch(setMaturityList(sfReducer.item.ecoMaturityList));
    } else if (groupName == "Industry Evolution") {
      dispatch(setMaturityList(sfReducer.item.ieMaturityList));
    }
  };

  const renderCards = (menu, source = null) => {

    try {
      let len = menu?.length;
      let col1 = [],
        col2 = [],
        col3 = [];
      if (source === 1) {
        // It is for Storefront View
        col1 = iterateOverCards(0, len, menu, col1);
        col2 = iterateOverCards(1, len, menu, col2);
        col3 = iterateOverCards(2, len, menu, col3);
      } else {
        // It is for Core Business Area
        col1 = iterateCBAOverCards(0, len, menu, col1);
        col2 = iterateCBAOverCards(1, len, menu, col2);
        col3 = iterateCBAOverCards(2, len, menu, col3);
      }

      return (
        <Row style={{ display: 'contents' }} >
          {col1}
          {col2}
          {col3}
        </Row>
      );
    } catch (err) {
      console.log("error occured during rendering SF cards", err);
    }
  };

  const iterateCBAOverCards = (start, len, sf, col) => {
    for (let i = start; i < len; i = i + 3) {
      sf[i] !== undefined &&
        col.push(
          <div
            lg="4"
            key={`key - ${i} `}
            className="value-chain-wrapper"
            onClick={() => {
              dispatch(toggleTheModal(!sfReducer.item.toggleModal));
              setCurrentStorefront(sf[i]);
              navReducer.email !== "" &&
                actionTracking(
                  pageNames.STOREFRONT_PAGE,
                  navReducer.email,
                  sf[i].valueChainName,
                  "Click"
                );
            }}
          >
            <CardComponent
              props={{
                title: sf[i].valueChainName,
                text: sf[i].valueChainDescription.html,
                footer: `${sf[i].capabilities.length} Capabilities`,
                logo:
                  sf[i].valueChainIcon && sf[i].valueChainIcon._imageBase64
                    ? sf[i].valueChainIcon._imageBase64
                    : Group,
                index: i + 1,
              }}
              hasFooter={true}
              hasLogo={true}
            />
          </div>
        );
    }
    return <Col className="col col-md-4">{col}</Col>;
  };

  const filterCaps = (caps) => {
    let result = [];
    caps.map((item) => {
      if (sfReducer.item.storefrontViewTab === "Industry Evolution") {
        if (
          item.capabilityType &&
          item.capabilityType.capabilityTypeName === "Future"
        ) {
          result.push(item);
        }
      } else {
        result.push(item);
      }
    });
    return result;
  };

  const iterateOverCards = (start, len, sm, col) => {

    for (let i = start; i < len; i = i + 3) {
      sm[i] !== undefined &&
        col.push(
          <div lg="4" key={`key - ${i} `} className="value-chain-wrapper">
            {/* <StorefrontMenuComponent
              props={{
                title: sm[i].valueChainName,
                text: filterCaps(sm[i].capabilities),
                logo:
                  sm[i].valueChainIcon && sm[i].valueChainIcon._imageBase64
                    ? sm[i].valueChainIcon._imageBase64
                    : Group,
                CardData: sfReducer.item.activeDataCard,
                activeTab: sfReducer.item.storefrontViewTab,
                maturityListData: sfReducer.item.maturityList,
                index: i + 1,
              }}
              hasFooter={true}
              hasLogo={true}
            /> */}
            {<ValueChainCard props={{
              valueChainName: sm[i].valueChainName,
              capabilities: filterCaps(sm[i].capabilities),
              navigateToCapabilityPage: navigateToCapabilityPage,
              setNewCapability:setNewCapability,
              selectedCapabilityList: selectedCapabilityList,
              newBvMaturityList: Array.from(sfReducer?.item?.newBvMaturityList),
              newTechMaturityList: Array.from(sfReducer?.item?.newTechMaturityList),
              newDapMaturityList: Array.from(sfReducer?.item?.newDapMaturityList),
              newEcoMaturityList: Array.from(sfReducer?.item?.newEcoMaturityList),
              bvmaturityList: Array.from(sfReducer?.item?.bvMaturityList.map((item) => { return item.name })),
              techMaturityList: Array.from(sfReducer?.item?.techMaturityList.map((item) => { return item.name })),
              dapMaturityList: Array.from(sfReducer?.item?.dapMaturityList.map((item) => { return item.name })),
              ecoMaturityList: Array.from(sfReducer?.item?.ecoMaturityList.map((item) => { return item.name }))
            }} />}
          </div>
        );
    }
    return <Col >{col}</Col>;
  };

  const navigateTo = (valueChainName, capabilityName, capabilities) => {
    let modifiedValuchainPath = removeSpaceWithHypens(valueChainName);

    const capabilityBusinessValueObj = {}
    capabilities.map((capability) => 
      capabilityBusinessValueObj[capability.capabilityName] = capability.businessValue.map((item) => item.name)
    )

    dispatch(setActiveValueChainSpecificCapabilities(capabilityBusinessValueObj))

    let modifiedCapsPath = removeSpaceWithHypens(capabilityName);
    if (navReducer.email !== "") {
      actionTracking(
        pageNames.STOREFRONT_PAGE,
        navReducer.email,
        "Selected Capability : " + capabilityName
      );
    }
    navigate(`/capabilities/${modifiedValuchainPath}/${modifiedCapsPath}`);
  };

  const navigateToCapabilityPage = (valueChainName, capabilityName, capabilities) => {
    navigateTo(valueChainName,capabilityName, capabilities)
  }

  const navigateToTemplatePage = () => {
    navigate('/digiprint-preview');
  }

  const setUpdatedMaturityList = (options, type) => {
    switch (type) {
      case "Business Value":
        dispatch(setNewBvMaturityList(options))
        break;
      case "Technology Enablement":
        dispatch(setNewTechMaturityList(options))
        break;
      case "Asset Maturity":
        dispatch(setNewDapMaturityList(options))
        break;
      case "Third-Party Solutions":
        dispatch(setNewEcoMaturityList(options))
        break;
      case "Reset":
        {
          dispatch(setNewBvMaturityList(options))
          dispatch(setNewTechMaturityList(options))
          dispatch(setNewDapMaturityList(options))
          dispatch(setNewEcoMaturityList(options))
          break;
        }
      default: break;
    }
  }

  const pdfCanvas = async (id) => {
    const input = document.getElementById(id);
    input.style.width = "1280px";
    input.style.height = "832px";
    input.style.marginTop = '-1px'
    let val = await html2canvas(input,{useCORS:true}).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      return imgData;
    });
    return val;
  };

  useEffect(()=>{
    setCoverTemplateImage(pdfCanvas('capability-template-01'));
  },[subSector])

  return (
    <ErrorBoundary>
      <div ref={ref}>
        <div className="storefront-back">
          {loading && <Loader />}

          {!loading && subSector && Object?.keys(subSector)?.length == 0 && (
            <div className="d-flex-justify-center">
              <h5>No records found</h5>
            </div>
          )}
          {error && (
            <ErrorContent message="There was an error in loading this content." />
          )}
          {bookmarkMsg}
          {!loading &&
            subSector &&
            !error &&
            Object?.keys(subSector)?.length > 0 && (
              <div className="banner-layout">
                <Banner
                  props={{
                    bookmarkList:bookmarkList,
                    setUpdatedBookmarkList:setUpdatedBookmarkList,
                    footer: updateDateValue,
                    haveBookmarkOption:true,
                    subSector:subSector,
                    email:navReducer.email,
                    accessToken: props.accessToken,
                  }}
                  hasLogo={true}
                  hasBreadCrumb={true}
                  hasFooter={true}
                />
              </div>
            )}

          {!loading && storeFrontLoading && <Loader />}

          <div style={{ display: "flex", width: '98%' }}>
            <div>
              {valuChainData?.length > 0 && <Filter showLeft={showLeft} setShowLeft={setShowLeft} sfReducer={sfReducer} setUpdatedMaturityList={(e, t) => setUpdatedMaturityList(e, t)} />}
            </div>
            <div className="container-fluid storefront-level-card">
              {valuChainData?.length > 0 && <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px', margin: '1rem 0rem', fontFamily:'Open Sans' }}>
                <div> &nbsp; Select up to 10 capabilities to build a Digiprint</div>
              </div>}
              {valuChainData?.length === 0 && !loading && !storeFrontLoading ? (
                <div className="d-block m-auto text-center empty-capabilities">
                  <img src={emptyCapabilities} />
                  <p className="no-vc-heading">No value chains available</p>
                  <p className="no-vc-description">
                    For this sector/subsector no value chains <br />
                    and capabilities are available
                  </p>
                </div>
              ) : (
                <div className="value-chain-wrapper" style={showLeft ? { marginLeft: '-5.2rem' } : {}}>{renderCards(valuChainData, 1)}</div>
              )}
            </div>
          </div>
          <CoverPageTemplate subsectorData = {subSector}/>
          <div><CapabilityDrawer selectedCapabilities = {selectedCapabilityList} totalCapabilities = {totalCapabilities} totalSelected= {totalSelected()} coverTemplateImage={coverTemplateImage} 
          navigateToTemplatePage={navigateToTemplatePage} removeCapability={ removeCapability } sfReducer={sfReducer}/></div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default StoreFront;
