import {
  storefrontViewScreenTabs,
} from "../../constants/commonConstants";
import {
  SET_STOREFRONT_ACTIVE_TAB,
  RESET_STOREFRONT_ACTIVE_TAB,
  SET_STOREFRONT_VIEW_TAB,
  SET_MATURITY_LIST_DATA,
  SET_NEW_BV_MATURITY_LIST_DATA,
  SET_NEW_TECH_MATURITY_LIST_DATA,
  SET_NEW_DAP_MATURITY_LIST_DATA,
  SET_NEW_ECO_MATURITY_LIST_DATA,
  SET_ACTIVE_VALUE_CHAIN,
  SET_ACTIVE_CARD_DATA,
  SET_CURRENT_SUBSECTOR,
  TECH_SET_MATURITY_LIST_DATA,
  DAP_SET_MATURITY_LIST_DATA,
  DC_SET_MATURITY_LIST_DATA,
  ECO_SET_MATURITY_LIST_DATA,
  BV_SET_MATURITY_LIST_DATA,
  IE_SET_MATURITY_LIST_DATA,
  TOGGLE_MODAL,
  SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES,
  SET_ACTIVE_VALUECHAIN_SPECIFIC_CAPABILITIES,
  SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES_DESCRIPTIONS,
  SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES_BENEFITS,
  SET_NEW_CAPABILITY_LIST_DATA,
  SET_BOOKMARK_LIST_DATA,
  SET_SUBSECTOR_LIST_DATA,
  SET_IS_BOOKMARK_CREATED
} from "../types";
const initialState = {
  storefrontViewTab: storefrontViewScreenTabs[0].groupName,
  maturityList: [],
  newBvMaturityList: [],
  newTechMaturityList: [],
  newDapMaturityList: [],
  newEcoMaturityList: [],
  techMaturityList: [],
  dapMaturityList: [],
  dcMaturityList: [],
  ecoMaturityList: [],
  bvMaturityList: [],
  ieMaturityList: [],
  activeValueChain: [],
  activeDataCard: [],
  currentSubSector: "",
  toggleModal: false,
  subsectorSpecificCapabilities: {},
  valueChainSpecificCapabilities: {},
  subsectorSpecificCapabilitiesDescriptions: {},
  subsectorSpecificCapabilitiesBenefits: {},
  capabilityList: [],
  bookmarkList:[],
  isBookmarkCreated:false
};

const storefrontReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case SET_STOREFRONT_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload,
      };
    case RESET_STOREFRONT_ACTIVE_TAB:
      return {
        ...state,
        storefrontViewTab: storefrontViewScreenTabs[0].groupName,
        maturityList: [],
        activeValueChain: [],
        activeDataCard: [],
        techMaturityList: [],
        dapMaturityList: [],
        dcMaturityList: [],
        ecoMaturityList: [],
        bvMaturityList: [],
        ieMaturityList: [],
      };
    case SET_STOREFRONT_VIEW_TAB:
      return {
        ...state,
        storefrontViewTab: payload,
      };
    case SET_MATURITY_LIST_DATA:
      return {
        ...state,
        maturityList: payload,
      };
    case SET_NEW_BV_MATURITY_LIST_DATA:
      return {
        ...state,
        newBvMaturityList: payload,
      };
    case SET_NEW_TECH_MATURITY_LIST_DATA:
      return {
        ...state,
        newTechMaturityList: payload,
      };
    case SET_NEW_DAP_MATURITY_LIST_DATA:
      return {
        ...state,
        newDapMaturityList: payload,
      };
    case SET_NEW_ECO_MATURITY_LIST_DATA:
      return {
        ...state,
        newEcoMaturityList: payload,
      };
    case SET_NEW_CAPABILITY_LIST_DATA:
      return {
        ...state,
        capabilityList: payload
      };
    case SET_BOOKMARK_LIST_DATA:
        return {
          ...state,
          bookmarkList: payload
        }
    case SET_SUBSECTOR_LIST_DATA:
      return {
        ...state,
        subsectorList: payload
      }
    case SET_IS_BOOKMARK_CREATED:
        return {
          ...state,
          isBookmarkCreated: payload
        }
    case TECH_SET_MATURITY_LIST_DATA:
      return {
        ...state,
        techMaturityList: payload,
      };
    case DAP_SET_MATURITY_LIST_DATA:
      return {
        ...state,
        dapMaturityList: payload,
      };
    case DC_SET_MATURITY_LIST_DATA:
      return {
        ...state,
        dcMaturityList: payload,
      };
    case ECO_SET_MATURITY_LIST_DATA:
      return {
        ...state,
        ecoMaturityList: payload,
      };
    case BV_SET_MATURITY_LIST_DATA:
      return {
        ...state,
        bvMaturityList: payload,
      };
    case IE_SET_MATURITY_LIST_DATA:
      return {
        ...state,
        ieMaturityList: payload,
      };
    case SET_ACTIVE_VALUE_CHAIN:
      return {
        ...state,
        activeValueChain: payload,
      };
    case SET_ACTIVE_CARD_DATA:
      return {
        ...state,
        activeDataCard: payload,
      };
    case SET_CURRENT_SUBSECTOR:
      return {
        ...state,
        currentSubSector: payload,
      };
    case TOGGLE_MODAL:
      return {
        ...state,
        toggleModal: payload,
      };
    case SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES: 
      return {
        ...state,
        subsectorSpecificCapabilities: payload,
      };
    case SET_ACTIVE_VALUECHAIN_SPECIFIC_CAPABILITIES: 
      return {
        ...state,
        valueChainSpecificCapabilities: payload,
      };
    case SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES_DESCRIPTIONS: 
    return {
      ...state,
      subsectorSpecificCapabilitiesDescriptions: payload,
    };
    case SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES_BENEFITS: 
    return {
      ...state,
      subsectorSpecificCapabilitiesBenefits: payload,
    };
    default:
      return state;
  }
};

export default storefrontReducer;
