import React, { useEffect, useState } from "react";
import classes from "./AboutCapabilityCard.module.scss"
import Tag from "../tag";
function AboutCapabilityCard( props ) {

  const { businessValue, technologyEnablement, deloitteAssets, maturityLevel } = props.capability;
  const [maturityFromDeloitteAssets, setMaturityFromDeloitteAssets] = useState()

  useEffect(() => {
    if(deloitteAssets?.length > 0) {
      let tempMaturity = ''
      deloitteAssets.map((item) => {
        if(item?.deloitteAssetPortfolio !== null && item?.deloitteAssetPortfolio.name === 'Market Ready' && (tempMaturity === '' || tempMaturity === 'POC or Initial Investment' || tempMaturity === 'Whitespace')) {
          tempMaturity = '1+ Market Ready Assets'
        }
        else if(item?.deloitteAssetPortfolio !== null && item?.deloitteAssetPortfolio.name === 'POC or Initial Investment' && (tempMaturity === '' || tempMaturity === 'Whitespace')) {
          tempMaturity = 'POC or Initial Investment'
        }
        else if(item?.deloitteAssetPortfolio !== null && item?.deloitteAssetPortfolio.name === 'Whitespace' && tempMaturity === '') {
          tempMaturity = 'Whitespace'
        }
        return false;
      })
      setMaturityFromDeloitteAssets(tempMaturity)
    }
  }, [])

  /*
  capability maturity is being derived from deloitte assets, but in case none of the deloitte assets have a maturity level, 
  then the capability maturity value has to be displayed.
  */
  return (
    <>
    {Object.keys(props.capability).length > 0 &&
      <div className={classes.container}>
        <div className={classes.about}><strong>About This Capability</strong></div>
        {businessValue?.length>0 && <div className={classes.header}>Business Value</div>}
        {
          businessValue?.map(item=>
            <Tag className={classes.aboutTag} closable={false}>{ item.name }</Tag>
          )
        }
        {technologyEnablement?.length > 0 && <div className={classes.header}>Technologies</div>}
        {
          technologyEnablement?.map(item=>
            <Tag className={classes.aboutTag} closable={false}>{ item.name }</Tag>
          )
        } 
        
        <div className={classes.header}>Capability Maturity</div>       
       { (maturityFromDeloitteAssets?.length > 0) 
        ?
        <div><div className={ maturityFromDeloitteAssets === "Whitespace" ? classes.maturityFirstWhitespace : maturityFromDeloitteAssets === "POC or Initial Investment" ? classes.maturityFirstPOC : classes.maturityFirstMarketready} ></div>
          <div className={ maturityFromDeloitteAssets === "POC or Initial Investment" ? classes.maturityMiddlePOC : maturityFromDeloitteAssets === "1+ Market Ready Assets" ? classes.maturityMiddleMarketready : classes.maturityMiddleUnSelected}></div>
          <div className={ maturityFromDeloitteAssets === "1+ Market Ready Assets" ? classes.maturityRightSelected : classes.maturityRightUnSelected}></div>
          <div className={ classes.maturity}>{maturityFromDeloitteAssets}</div>
        </div>
        :
        <div><div className={ maturityLevel?.name === "Whitespace" ? classes.maturityFirstWhitespace : maturityLevel?.name === "POC or Initial Investment" ? classes.maturityFirstPOC : classes.maturityFirstMarketready} ></div>
          <div className={ maturityLevel?.name === "POC or Initial Investment" ? classes.maturityMiddlePOC : maturityLevel?.name === "1+ Market Ready Assets" ? classes.maturityMiddleMarketready : classes.maturityMiddleUnSelected}></div>
          <div className={ maturityLevel?.name === "1+ Market Ready Assets" ? classes.maturityRightSelected : classes.maturityRightUnSelected}></div>
          <div className={ classes.maturity}>{maturityLevel?.name}</div>
        </div>
        }
        
      </div>
    }
    </>
  );
}

export default AboutCapabilityCard;
