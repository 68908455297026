import React from "react";
import PropTypes from "prop-types";
import styles from "./Tag.module.scss";
import { CloseOutlined } from "@ant-design/icons";

function Tag(props) {
  const { closeable, onClose, children, className, ...rest } = props;

  const handleCloseClick = (e) => {
    if (onClose) {
      onClose();
    }
  };

  const renderClose = () => (
    <button className={styles.close} onClick={handleCloseClick}>
      <CloseOutlined />
    </button>
  );

  const classNames = [styles.tag];
  if (className) {
    classNames.push(className);
  }
  if (closeable) {
    classNames.push(styles.closeable);
  }

  return (
    <span {...rest} className={classNames.join(" ")}>
      <span className={styles.content}>{children}</span>
      {closeable && renderClose()}
    </span>
  );
}

Tag.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  closeable: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Tag;
