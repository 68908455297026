export const GET_READY_TO_GET_STARTED = `
query GetReadyToGetStarted{
  homeGetStartedList(filter:{
    isactive:{
      _expressions:{
        value: true
      }
    }
  }){
    items{
      _path
      displayorder
      fieldLabel{
        header
        description{
          plaintext
        }
      }
      heading
      isactive
      description{
        plaintext
        html
      }
    }
  }
}
`

export default {
  GET_READY_TO_GET_STARTED
}