import React, { useEffect, useState } from 'react';
import FaqSection from '../../components/FaqSection/FaqSection.Component';
import { POST_GRAPHQL } from '../../services/httpService';
import faqs, { GET_NEW_FAQS_LIST  } from './../../services/graphql-queries/faqs';
import { sortByKey } from './../../utils'
import './Faq.scss';

const Faq = (props) => {

  const [loading, setLoading] = useState(true);
  const [faqsData, setFaqsData] = useState([]);

  const navId = document.getElementById('navbar');
  navId?.classList?.remove('navbarTransparent');
  navId?.classList?.add('navbar')

  useEffect(() => {
    GET_FAQS()
  },[])

  const GET_FAQS = async () => {
    setLoading(true);
    try {
      let responseData = await POST_GRAPHQL(
        GET_NEW_FAQS_LIST ,
        {},
        { accessToken: props.accessToken },
        "GET_NEW_FAQS_LIST"
      );

      if (responseData && responseData.data && responseData.data.data) {
        let faqList = responseData.data.data.faqList;
        //console.log('response ', faqList)

        if (faqList && faqList.items) {
          let faqObj = {}
          // let ordereditems = sortByKey(faqList.items, "displayOrder");
          // setFaqsData(ordereditems);
          faqList.items.map((item) => {
            if(!Object.keys(faqObj).includes(item.selectFaqSection.header)) {
              faqObj[item.selectFaqSection.header] = {
                description: item.selectFaqSection.description.plaintext,
                displayOrder: item.selectFaqSection.displayOrder,
                faqs: [{
                  question: item.faqQuestion.plaintext,
                  answer: item.faqAnswer.html,
                  displayOrder: item.displayOrder
                }]
              }
            }
            else {
              faqObj[item.selectFaqSection.header]?.faqs.push({
                question: item.faqQuestion.plaintext,
                answer: item.faqAnswer.html,
                displayOrder: item.displayOrder
              })
            }
          })          
          setFaqsData(setFaqListOrder(faqObj))
        }
      }
      setLoading(false);
      //urlToDiv();
    } catch (err) {
      setLoading(false);
      console.log("err ", err);
    }
  };

  const setFaqListOrder = (obj) => {
    let arr = []   

    Object.keys(obj).map((item) => {
      let temp = {}
      temp.title = item
      temp.displayOrder = obj[item].displayOrder
      temp.description = obj[item].description
      temp.faqs = obj[item].faqs
      arr.push(temp)
    })

    const tempArr = [].concat(arr)
    .sort((a,b) => a.displayOrder > b.displayOrder ? 1 : -1)
    .map((item) => {return item})

    return tempArr;
  }

  return (
    <div style={{paddingTop: '2.8rem'}}>
      <div className='header'>Frequenty Asked Questions</div>
      <div style={{backgroundColor: '#f4f4f4'}}>
        {(!loading && Object.keys(faqsData).length > 0) &&      
          faqsData.map((item, index) => (
            <>
              {<FaqSection key={index} title={item.title} description={item.description} faqs={item.faqs}/>}
              <hr style={{ color: '#D0D0CE' }}/>
            </>
          ))         
        }
      </div>
    </div>
  )
}

export default Faq