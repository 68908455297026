import React from "react";

function Logout() {
    return (
        <>
            <div style={{ padding: '10px' }}>
                <span>You have been logged out!</span>
                <br /><br />
                <button className='btn btn-success' style={{ backgroundColor: '#87BD1F' }} onClick={() => { window.location.href = "/"; }} >Home</button>
            </div>
        </>
    );
}

export default Logout;