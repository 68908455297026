import React from 'react'

function ErrorContent(props) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red', height: '150px' }}>
            {
                props.message ? <h4>{props.message}</h4> : <h4>There was an error in loading this content.</h4>
            }
        </div>
    )
}

export default ErrorContent