export const GET_DIGIPRINT_INFO = `
query GetDigiprintInfo {
  digiprintInfoItemsList(filter: {
    isActive: {
      _expressions:{
        value: true
      }
    }
  }){
   _references{
     ... on HomePageItemsModel{
       header,
       description{
         plaintext
       }
     }
   }
   items{
     description{plaintext}
     image{
       ... on ImageRef{
             _authorUrl
             _publishUrl
           }
     }
     isActive
     header
   }
 }
 }
`

export default {
    GET_DIGIPRINT_INFO
}