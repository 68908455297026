import React, { useEffect } from "react";
import { DefaultBannerBlack } from "../../constants/images";
import { BsBookmark, BsBookmarkFill } from 'react-icons/bs';
import classes from "./Banner.module.scss";
import { Col, Row } from "reactstrap";
import { BookmarkQuery } from "../../services/graphql-queries";
import { POST_GRAPHQL } from "../../services/httpService";
import { setBookmarkList, setIsBookmarkCreated } from "../../store/actions/storefrontAction";
import { useDispatch } from "react-redux";

const Banner = ({
  props,
  hasBreadCrumb = null,
}) => {
  const { bookmarkList, setUpdatedBookmarkList, haveBookmarkOption, footer, subSector, email } = props || {};
  useEffect(() => {
  }, [props]);

  useEffect(() => {
    if (props.accessToken !== undefined) {
      GET_BOOKMARK_LIST();
    }
  }, [props.accessToken, email]);
  const dispatch = useDispatch();

  const GET_BOOKMARK_LIST = async () => {
    try {
        let responseData = await POST_GRAPHQL(  
            BookmarkQuery.GET_BOOKMARK_LIST,
            { email: email }, 
            { accessToken: props.accessToken }, 
            true,
            "GET_BOOKMARK_LIST"
        );
        if (responseData && responseData.data && responseData.data.data) {
            let bookmarkList = responseData.data.data.savedBookmarksList;

        if (bookmarkList && bookmarkList?.items?.length>0) {
          dispatch(setBookmarkList(bookmarkList.items[0].subSector));
          if (bookmarkList.items.length > 0)
            dispatch(setIsBookmarkCreated(true));
          else
            dispatch(setIsBookmarkCreated(false));
        }
      }
    } catch (err) {
      console.log("err ", err);
    }
  };


  const title = subSector.subsectorName;
  const image = subSector.subsectorImage &&
    subSector.subsectorImage._publishUrl
    ? subSector.subsectorImage._publishUrl
    : "";
  const sectorName = subSector.sector && subSector.sector.sectorName
    ? subSector.sector.sectorName
    : "";
  const subSectorName = subSector.subsectorName
    ? subSector.subsectorName
    : "";

  return (
    <div className={classes.bannerBackground}>
      <Row style={{ backgroundImage: `url(${image !== "" ? image : DefaultBannerBlack})` }} className={classes.backgroundImage}>
        <Col lg="12" className={classes.bannerContent}>
          {hasBreadCrumb && (
            <div className={classes.breadcrumbTitle}>
              {sectorName} <span className={classes.slash}> / </span>
              {subSectorName}
            </div>
          )}

          <span className={classes.bannerTitle}>{title}</span>
          {
            haveBookmarkOption && <span>
              {
                bookmarkList?.filter(item => item.subsectorName === title).length > 0 ?
                  <span onClick={() => { setUpdatedBookmarkList(subSector) }} id="filledBookmarked"><BsBookmarkFill className={classes.filledBookmark} size={26} /></span>
                  :
                  <span onClick={() => { setUpdatedBookmarkList(subSector) }} id="bookmarked"><BsBookmark className={classes.bookmark} size={26} /></span>
              }
            </span>
          }

          <p
            className={classes.bannerFooter}
          >
            Last Updated: {footer?.replace("Updated", "")}
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Banner;
