export const GET_FAQS_LIST = `
{
  faqList(filter:{
    isActive:{
      _expressions:{
        value:true
      }
    }
    isDeleted:{
      _expressions:{
        value:false
      }
    }
       
  }){
    items{
      faqQuestion{
        html
      }
      faqAnswer{
        html
      }
      page{
        pageId
        pageName
      }
      isActive
      isDeleted
      displayOrder
    }
  }
}
  `

export const GET_NEW_FAQS_LIST = `
{ 
  faqList(filter:{
    isActive:{
      _expressions:{
        value:true
      }
    }
    selectFaqSection:{
        isActive:{
          _expressions: {
            value: true
          }
        }
      }
      _logOp:AND
  }) {
    items {
        isActive
        selectFaqSection {
          isActive
          header
          description {
            plaintext
          }
          displayOrder
        }
        faqQuestion {
          plaintext
          html
        }
        faqAnswer {
          plaintext
          html
        }
        displayOrder
      }
    }
  }

`

export default {
  GET_FAQS_LIST
}