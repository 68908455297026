import { Button, Badge, ConfigProvider } from 'antd';
import React, { useState } from 'react'
import CollapsibleCard from './../collapsible-card/CollapsibleCard.Component';
import filterIcon from "../../assets/images/storefront-view/filter-img.png"
import tealFilterIcon from "../../assets/images/storefront-view/filter-dark-img.png"


import PreviewTag from './../tag/PreviewTag.Component';
import styles from "./Filter.module.scss"

function Filter(props) {

    const [showDiv, setShowDiv] = useState(true)
    const [reset, setReset] = useState(false)
    const [filterToBeRemoved, setFilterToBeRemoved] = useState("")
    const { showLeft, setShowLeft, sfReducer, setUpdatedMaturityList} = props;
    const transitionProperties = !showDiv ? { marginLeft: '-250rem' } : {}

    const maturityListsArray = [sfReducer.item.bvMaturityList, sfReducer.item.techMaturityList, sfReducer.item.dapMaturityList] //, sfReducer.item.ecoMaturityList to be included later whe data is available
    let options = [...sfReducer.item.newBvMaturityList, ...sfReducer.item.newTechMaturityList, ...sfReducer.item.newDapMaturityList, ...sfReducer.item.newEcoMaturityList]
    let optionsBv = Array.from(sfReducer.item.newBvMaturityList);
    let optionsTech = Array.from(sfReducer.item.newTechMaturityList);
    let optionsDap = Array.from(sfReducer.item.newDapMaturityList);
    let optionsEco = Array.from(sfReducer.item.newEcoMaturityList);

    // let persistedFilterValues = sessionStorage.getItem("filter_array");
    // if(persistedFilterValues.length > 0)
    //     dispatch(setNewMaturityList(persistedFilterValues))

    const persistFiltersInSessionStorage = (filterArr) => {
        //sessionStorage.setItem("filter_array", filterArr);
    }

    const removeFilter = (item) => {
        setFilterToBeRemoved(item)
        if (options.includes(item)) {
            options.splice(options.indexOf(item), 1)
        }
        if (optionsBv.includes(item)) {
            optionsBv.splice(optionsBv.indexOf(item), 1)
            setUpdatedMaturityList(optionsBv, "Business Value")
        }
        if (optionsTech.includes(item)) {
            optionsTech.splice(optionsTech.indexOf(item), 1)
            setUpdatedMaturityList(optionsTech, "Technology Enablement")
        }
        if (optionsDap.includes(item)) {
            optionsDap.splice(optionsDap.indexOf(item), 1)
            setUpdatedMaturityList(optionsDap, "Asset Maturity")
        }
        if (optionsEco.includes(item)) {
            optionsEco.splice(optionsEco.indexOf(item), 1)
            setUpdatedMaturityList(optionsEco, "Third-Party Solutions")
        }
    }

    const resetFilters = () => {
        setReset(true)
        setUpdatedMaturityList([], "Reset");
    }



    return (
        <div style={{ marginTop: "0rem", marginLeft: "2rem"}}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#0d8390',
                        colorBorder: '#000000'
                    },
                }}
            >
                <Badge count={showDiv ? 0 : options.length} color="white" style={{ marginLeft: "3rem", borderRadius: "4px", borderColor: "#D0D0CE", color: "#75787B" }}>
                    <Button onClick={() => {setShowDiv(!showDiv); setShowLeft(!showLeft)}} className={(!showDiv) ? styles.filterButtonWithCount : styles.filterButton}>
                        {(!showDiv)
                            ?
                            <img src={tealFilterIcon} alt='filter icon' width="15px" height="15px" />
                            :
                            <img src={filterIcon} alt='filter icon' width="30px" height="30px" />
                        }
                    </Button>
                </Badge>
                {options.length > 0 && <div className={styles.previewArea} style={transitionProperties}>
                        <div>
                             <div style={{display: 'flex', justifyContent: 'space-between', fontSize: "13px", margin: "1rem 0rem", fontWeight: 'bolder' }}>
                                <div>Filtered By:</div>   
                                <div id="reset-filters" onClick={resetFilters} style={{color: 'rgb(13,131,144)',  textDecoration: 'underline', marginRight: '3.4rem', cursor: 'pointer'}}>Reset Filters</div>
                            </div> 
                            <PreviewTag names={options} removeFilter={removeFilter} />
                        </div>
                </div>}
                {showDiv && options.length !== 0 && <hr style={{ marginTop: '2rem', borderColor: '#9fa0a6', width: '83%' }} />}
                {(maturityListsArray.length !== 0) && <div className={styles.filterDiv} style={transitionProperties}>
                    {maturityListsArray.map((item, index) => {
                        return <div key={index} style={{ marginBottom: "1rem" }}>
                            <CollapsibleCard removeFilterSelection={filterToBeRemoved} changeReset={() => setReset(false)} 
                            reset={reset} persistFiltersInSessionStorage={persistFiltersInSessionStorage} maturityList={item} index={index}
                            sfReducer={sfReducer}
                            setUpdatedMaturityList={setUpdatedMaturityList} />
                            <hr style={{ marginTop: '2rem', borderColor: '#9fa0a6' }} />
                        </div>
                    })
                }
                </div>}
            </ConfigProvider>
        </div>
    )
}

export default Filter