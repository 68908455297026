import { admin_navbar_menu } from "../../constants/commonConstants";
import { SET_ADMIN_MENU } from "../types";
const initialState = {
  menu: admin_navbar_menu.MASTER_DATA,
};

const adminReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case SET_ADMIN_MENU:
      return {
        menu: payload,
      };
    default:
      return state;
  }
};

export default adminReducer;
