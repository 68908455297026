import React from 'react'
import { Row, Col } from 'reactstrap';

import DiagonalArrow from './../../assets/images/common/diagonalWiredArrow.svg'
import styles from './SupportingMaterials.module.scss'

const SupportingMaterials = (props) => {
    const {additionalLinksExternal, additionalLinksInternal} = props;
    let additionalLinksExternalArr=additionalLinksExternal[0];
    let additionalLinksInternalArr=additionalLinksInternal[0];
    if(additionalLinksExternalArr?.url) {
        additionalLinksExternalArr=additionalLinksExternalArr.url.split('\n');
        additionalLinksExternalArr=additionalLinksExternalArr.slice(0,additionalLinksExternalArr.length-1);
        additionalLinksExternalArr=additionalLinksExternalArr.map((link,index)=>{
            link=link.replace('<a',`<a id="supportingMaterialsExternal${index}" target='_blank'`);
            return link;
        });
    }
    if(additionalLinksInternalArr?.url) {
        additionalLinksInternalArr=additionalLinksInternalArr.url.split('\n');
        additionalLinksInternalArr=additionalLinksInternalArr.slice(0,additionalLinksInternalArr.length-1);
        additionalLinksInternalArr=additionalLinksInternalArr.map((link,index)=>{
            link=link.replace('<a',`<a id="supportingMaterialsInternal${index}"`);
            return link;
        })
    }

    return (
       <Row style={{marginLeft:'1px'}}>
            {
                additionalLinksExternalArr?.map((item,index)=><Col md={4} lg={4} className={styles.cardContainer} key={item.title}
                onClick={()=>{document?.getElementById(`supportingMaterialsExternal${index}`)?.click()}}>
                    <div className={styles.type}>External</div>
                    <div className={styles.title} dangerouslySetInnerHTML={{ __html:  item}}></div>
                    <div className={styles.wired}>Wired<img style={{marginLeft:'14px', marginTop:'-3px'}} src={DiagonalArrow} alt="arrow"/></div>
                </Col>)
            }
                        {
                additionalLinksInternalArr?.map((item,index)=><Col md={4} lg={4} className={styles.cardContainer} key={item.title}
                    onClick={()=>{document?.getElementById(`supportingMaterialsInternal${index}`)?.click()}}>
                    <div className={styles.type}>Internal</div>
                    <div className={styles.title} dangerouslySetInnerHTML={{ __html:  item}}></div>
                    <div className={styles.wired}>Wired<img style={{marginLeft:'14px', marginTop:'-3px'}} src={DiagonalArrow} alt="arrow"/></div>
                </Col>)
            }
       </Row>
    )
}

export default SupportingMaterials