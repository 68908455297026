import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BookmarkQuery } from "../../services/graphql-queries";
import { POST_GRAPHQL } from "../../services/httpService";
import MyDigiPrints from "./MyDigiPrints.component";
import { setBookmarkList, setIsBookmarkCreated } from "../../store/actions/storefrontAction";
import { removeSpaceWithHypens } from "../../utils";
import { useNavigate } from "react-router-dom";

function MyDigiPrintsIndex(props) {
    const dispatch = useDispatch();
    const sfReducer = useSelector((state) => ({
        item: state.storefrontReducer,
    }));

    const navReducer = useSelector((state) => ({
        email: state.navigationReducer.userEmail,
      }));    

    const userDataReducers = useSelector((state) => state.navigationReducer);
    let accessToken = userDataReducers?.loggedUserData?.accessToken;


    useEffect(() => {
        if (accessToken !== undefined) {
            GET_BOOKMARK_LIST();
        }
    }, [accessToken, navReducer.email]);


    const GET_BOOKMARK_LIST = async () => {
        try {
            let responseData = await POST_GRAPHQL(  
                BookmarkQuery.GET_BOOKMARK_LIST,
                { email: navReducer.email }, 
                { accessToken: accessToken },
                "GET_BOOKMARK_LIST",
                true
            );
            if (responseData && responseData.data && responseData.data.data) {
                let bookmarkList = responseData.data.data.savedBookmarksList;

                if (bookmarkList && bookmarkList?.items?.length > 0) {
                    dispatch(setBookmarkList(bookmarkList.items[0].subSector));
                    if (bookmarkList.items.length > 0)
                        dispatch(setIsBookmarkCreated(true));
                    else
                        dispatch(setIsBookmarkCreated(false));
                }
            }
        } catch (err) {
            console.log("err ", err);
        }
    };

    const navId = document.getElementById('navbar');
    navId?.classList?.remove('navbarTransparent');
    navId?.classList?.add('navbar')
    const bookmarkList = sfReducer.item.bookmarkList;
    const navigate = useNavigate();
    const navigateToStoreFrontPage=(subsectorName)=>{
        navigate("/storefront/"+removeSpaceWithHypens(subsectorName)); 
    }
    return <MyDigiPrints bookmarkList={bookmarkList} navigateToStoreFrontPage={navigateToStoreFrontPage} />
}

export default MyDigiPrintsIndex;
