import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Spinner } from "reactstrap";
import { PrivateRoute } from "../../routes/private-route";
import { PublicRoute } from "../../routes/public-route";
import AuthLayout from "../layout/AuthLayout";
import AdminLayout from "../layout/AdminLayout";
import Home from "../../views/main/Home";
import Sector from "../../views/Sector/index";
import StoreFront from "../../views/store-front";
import { LoginCallback } from "../../msal/components/login-callback";
import { LogoutCallback } from "../../msal/components/logout-callback";
import Logout from "../../msal/components/logout";
import { SilentRenew } from "../../msal/components/silent-renew";
import Capabilities from "../../views/capabilities";
import ErrorLogs from "../../views/error-logs";
import adminsettings from "../../components/layout/adminsettings/Adminsettings";
import ErrorPage from "../../views/error-page/ErrorPage";
import RedisCache from "../layout/adminsettings/RedisCache";
import Search from "../../views/search";
import DigiprintExport from './../../views/digiprint-export/index'
import FaqsSection from '../../views/main/components/FaqsSection/index'
import MyDigiPrintsIndex from "../../views/MyDigiPrints/index";
import Contacts from "../../views/contacts";
import Faq from "../../views/faq";

const RouteData = [
    { path: "/", component: Home, isAuthRequired: true },
    { path: "/sectors/:industryName", component: Sector, isAuthRequired: true },
    { path: "/adminsettings", component: adminsettings, isAuthRequired: true },
    { path: "/mydigiprints", component: MyDigiPrintsIndex, isAuthRequired: true },
    { path: "/rediscache", component: RedisCache, isAuthRequired: true },
    { path: "/errorpage", component: ErrorPage, isAuthRequired: true, layout: "Auth" },
    { path: "/storefront/:subSectorName", component: StoreFront, isAuthRequired: true },
    { path: "/capabilities/:valueChain/:capability", component: Capabilities, isAuthRequired: true },
    { path: "/search", component: Search, isAuthRequired: true },
    { path: "/error", component: ErrorLogs, isAuthRequired: false },
    { path: "/login-callback", component: LoginCallback, isAuthRequired: false },
    { path: "/logout", component: Logout, isAuthRequired: false, layout: "Auth" },
    { path: "/digiprint-preview", component: DigiprintExport, isAuthRequired: true},
    { path: "/contacts", component: Contacts, isAuthRequired: true},
    { path: "/faq", component: Faq, isAuthRequired: true},
    { path: "/logout-callback", component: LogoutCallback, isAuthRequired: false },
    { path: "/silent-refresh", component: SilentRenew, isAuthRequired: false },
];

const AppRoutes = (props) => {
    return (
        <>
            <Suspense
                fallback={
                    <>
                        <Spinner color="success" type="grow">
                            Loading...
                        </Spinner>
                    </>
                }
            >
                <Routes>
                    {RouteData.map((item, index) => {
                        return item.isAuthRequired ? (
                            <Route
                                key={index + 1}
                                path={item.path}
                                element={
                                    <>
                                        {item.layout == "Auth" ? (
                                            <AuthLayout>
                                                <PrivateRoute component={item.component} />
                                            </AuthLayout>
                                        ) : (
                                            <AdminLayout>
                                                <PrivateRoute component={item.component} />
                                            </AdminLayout>
                                        )}
                                    </>
                                }
                            />
                        ) : (
                            <Route
                                key={index + 1}
                                path={item.path}
                                element={
                                    <>
                                        {item.layout == "Auth" ? (
                                            <AuthLayout>
                                                <PublicRoute component={item.component} />
                                            </AuthLayout>
                                        ) : (
                                            <AdminLayout>
                                                <PublicRoute component={item.component} />
                                            </AdminLayout>
                                        )}
                                    </>
                                }
                            ></Route>
                        );
                    })}
                </Routes>
            </Suspense>{" "}
        </>
    );
};

export default AppRoutes;
