import { combineReducers } from "redux";
import navigationReducer from "./reducers/navigationReducer";
import storefrontReducer from "./reducers/storefrontReducer";
import adminReducer from "./reducers/adminReducer";

export default combineReducers({
  navigationReducer,
  storefrontReducer,
  adminReducer
});
