import React, { useEffect } from "react";
import classes from './CapabilityDetailsTabs.module.scss';
const CapabilityDetailsTabs = ({props}) => {
    const { tabs, activeTab, setActiveTab } = props;
    useEffect(() => {
        window.addEventListener('scroll', isSticky);

        const tabId = tabs.length>0 && document.getElementById(activeTab);
        tabId && tabId.classList.add(classes.tabNameSelected)

        return () => {
            window.removeEventListener('scroll', isSticky);
        };

    }, []);

    const isSticky = (e) => {
        const header = document.getElementById('myHeader');

        window.pageYOffset+46> header.offsetTop ? header.classList.add(classes.sticky) : header.classList.remove(classes.sticky);
    };

    return <div className={classes.tabsContainer} id="myHeader">
        {
            tabs.map(tab => {
                const tabId = document.getElementById(tab);
                tabId && activeTab !== tab && tabId.classList.remove(classes.tabNameSelected);
                return <div style={{ display: 'inline-block' }}
                    onClick={() => {
                        const tabId = document.getElementById(tab);
                        tabId.classList.remove(classes.tabNameHover);
                        tabId.classList.add(classes.tabNameSelected)
                        setActiveTab(tab)
                    }}
                    onMouseEnter={(e) => { const tabId = document.getElementById(tab); tabId.classList.add(classes.tabNameHover) }}
                    onMouseLeave={(e) => { const tabId = document.getElementById(tab); activeTab !== tab && tabId.classList.remove(classes.tabNameHover) }}>
                    <div key={tab} id={tab} className={classes.tabName}>
                        {tab}
                    </div>
                </div>
            })
        }
    </div>
}

export default CapabilityDetailsTabs