import {
  SET_ACTIVE_NAVIGATION,
  SET_LOGGED_USER_DATA,
  SET_IS_ADMIN,
  SET_USER_EMAIL
} from "../types";

export const setNavigationMenu = (menu) => async (dispatch) => {
  await dispatch({
    type: SET_ACTIVE_NAVIGATION,
    payload: menu,
  });
};

export const setUserGraphData = (payload) => async (dispatch) => {
  await dispatch({
    type: SET_LOGGED_USER_DATA,
    payload: payload,
  });
};

export const setIsAdmin = (isAdmin) => async (dispatch) => {
  await dispatch({
    type: SET_IS_ADMIN,
    payload: isAdmin,
  });
};

export const setUserEmail = (userEmail) => async (dispatch) => {
  await dispatch({
    type: SET_USER_EMAIL,
    payload: userEmail,
  });
};
