import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Sector.scss";
import Banner from "../../components/banner/Banner.Component";
import { upArrow, downArrow } from "../../constants/images";
import {pageNames} from '../../constants/omnitureConstants';
import CardComponent from "../../components/cards/Card.Component";
import { Row, Col } from "reactstrap";
import { IndustriesQuery } from "../../services/graphql-queries";
import { POST_GRAPHQL } from "../../services/httpService";
import ErrorBoundary from "../../components/error-boundary/ErrorBoundary";
import {
  removeHypensWithSpace,
  removeSpaceWithHypens,
  sortByKey,
} from "../../utils";
import Loader from "../../components/loader";
import { useDispatch, useSelector } from "react-redux";
import { resetStorefrontActiveTab, setNewCapabilityList } from "../../store/actions/storefrontAction";
import { actionTracking, pageView } from "../../utils/omniture";


const Sector = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { industryName } = useParams();

  const [loading, setLoading] = useState(false);
  const [industry, setIndustry] = useState(null);
  const [sectors, setSectors] = useState([]);
  const [collapsed, setCollapsed] = useState(new Set());

  const navReducer = useSelector((state) => ({
    email: state.navigationReducer.userEmail,
  }));
  useEffect(() => {
    if (navReducer.email !== "") {
      pageView(pageNames.SECTORS_PAGE, navReducer.email);
    }
  }, [navReducer.email]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(resetStorefrontActiveTab());
    if (props.accessToken != undefined) {
      if (industryName) {
        Get_Industy();
      }
    }
  }, [props.accessToken]);

  const Get_Industy = async () => {
    setLoading(true);
    try {
      let modifiedindustryName = removeHypensWithSpace(industryName);
      let responseData = await POST_GRAPHQL(
        IndustriesQuery.GET_INDUSTY_BY_NAME_WITH_SECTOR_SUBSECTOR,
        { industryName: modifiedindustryName },
        { accessToken: props.accessToken },
        `GET_INDUSTY_BY_NAME_WITH_SECTOR_SUBSECTOR_${industryName}`
      );

      if (responseData && responseData.data && responseData.data.data) {
        let industryList = responseData.data.data.industryList.items;
        let sectorList = responseData.data.data.sectorList.items;
        let subsectorList = responseData.data.data.subsectorList.items;

        if (industryList && industryList.length > 0) {
          setIndustry(industryList[0]);
          if (industryList && industryList.length > 0) {
            let displayOrderedSectorList = sortByKey(
              sectorList,
              "displayOrder"
            );
            let sectorWithSubSector = [];
            displayOrderedSectorList.forEach((sectorData) => {
              let sectorTmp = sectorData;
              let subSectorTemp = [];
              subSectorTemp = subsectorList.filter(
                (element) =>
                  element.sector &&
                  sectorData._path &&
                  element.sector._path == sectorData._path
              );
              sectorTmp.subSectors = sortByKey(subSectorTemp, "displayOrder");
              sectorWithSubSector.push(sectorTmp);
            });

            setSectors(sectorWithSubSector);
          }
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("err ", err);
    }
  };

  const toggle = (i) => {
    if (navReducer.email !== "") {
      actionTracking(
        pageNames.SECTORS_PAGE,
        navReducer.email,
        "Collapse Sector",
        "Click"
      );
    }
    if (collapsed.has(i)) {
      let newSet = new Set(collapsed);
      newSet.delete(i);
      setCollapsed(newSet);
    } else {
      let newSet = new Set(collapsed);
      newSet.add(i);
      setCollapsed(newSet);
    }
  };
  const navigateTo = (path) => {
    let modifiedPath = removeSpaceWithHypens(path);
    if (navReducer.email !== "") {
      actionTracking(
        pageNames.SECTORS_PAGE,
        navReducer.email,
        "Selected Sub Sector : " + path
      );
    }
    let url = window.location.href;
    url = url.split("/");
    url = url[url.length -1];
    url !== modifiedPath && dispatch(setNewCapabilityList([]));
    navigate(`/storefront/${modifiedPath}`);
  };

  return (
    <ErrorBoundary>
      {loading && (
        <div className="d-flex-justify-center">
          {" "}
          <Loader />{" "}
        </div>
      )}
      {!loading && industry && (
        <div className="select-industry-sector-back">
          <div className="banner-layout">
            <Banner
              props={{
                title: removeHypensWithSpace(industryName),
                description: industry?.industryDescription,
                image: industry?.industryImage?._imageBase64,
                logo: industry?.industryIcon?._imageBase64,
                haveBookmarkOption:false,
                email:navReducer.email
              }}
              hasLogo={true}
            />
          </div>

          {sectors.map((sector, index) => (
            <div key={index} className="container-fluid sector-level-card">
              <div onClick={() => toggle(index)} className="sector-title">
                <div className="d-inline">{sector.sectorName}</div>
                <div className="d-inline float-end">
                  <img
                    src={!collapsed.has(index) ? upArrow : downArrow}
                    alt=""
                  />
                </div>

                <hr />
              </div>
              <div
                style={{ display: !collapsed.has(index) ? "block" : "none" }}
              >
                <Row>
                  {sector.subSectors.map((subSector, i) => (
                    <Col
                      lg="4"
                      key={`key-${i}`}
                      id={`sector-subsector-${subSector.subsectorName}`}
                      onClick={() =>subSector.isActive &&
                        sector.isActive &&
                        navigateTo(subSector.subsectorName)
                      }
                    >
                      <CardComponent
                        props={{
                          title: subSector.subsectorName,
                          text: subSector.subsectorDescription.html,
                        }}
                        hasLink={true}
                        linkText="Select Sub-Sector"
                        isActive={sector.isActive && subSector.isActive}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          ))}
        </div>
      )}
      {!loading && !industry && (
        <div className="d-flex-justify-center">
          <h5>No records found.</h5>
        </div>
      )}
    </ErrorBoundary>
  );
};

export default Sector;
