import React, { useEffect, useState } from "react";
import classes from "./SalesCollaterals.module.scss"
import Pdf from "../../assets/images/common/pdf.svg"
import Video from "../../assets/images/common/video.svg"
import Ppt from "../../assets/images/common/ppt.svg"
import { Row, Col } from 'reactstrap';
function SalesCollaterals( props ) {
    const { collaterals, isDeloitteAssetsPage } = props;
    const [ currCollaterals, setCurrCollaterals] = useState([]);


    useEffect(()=>{
        let arr=[]
        arr=collaterals.map((item,index)=>{
        item.url= item.url.replace('<a',`<a target="_blank" id="collateral${index}"`);
        return item;
        })
        setCurrCollaterals(arr);
    },[])

    return (
        <Row style={{marginLeft:'2px'}}>
            {
                currCollaterals?.map((item,index)=>{
                    return <Col md={isDeloitteAssetsPage?12:6} style={isDeloitteAssetsPage?{marginLeft:'-12px', cursor:'pointer'}:{cursor:'pointer', }} lg={isDeloitteAssetsPage?12:6}
                    onClick={()=>{document?.getElementById(`collateral${index}`)?.click()}}  key={item.title}>
                        <img className={classes.icon} src={item.fileType==='pdf'?Pdf:item.fileType==='pptx'?Ppt:Video} alt={item.fileType}/>
                        <div className={classes.title} style={isDeloitteAssetsPage?{marginTop:'12px'}:{marginTop:'16px'}}>{item.title}</div>
                        <div style={{display:'none'}} dangerouslySetInnerHTML={{__html:item.url}}></div>
                    </Col>
                })
            }
        </Row>
    );
}

export default SalesCollaterals;
