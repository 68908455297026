import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "reactstrap";
import greenDot from "../../../../assets/images/common/green.dot.png";
import logo from "../../../../assets/images/common/logo.png";
import sideArrowBlack from "../../../../assets/images/common/side-arrow-black.png";
import sideArrowWhite from "../../../../assets/images/common/side-arrow-white.png";
import user from "../../../../assets/images/common/user.png";
import SubmitFeedback from "../../../../assets/images/feedback/submitFeedback.svg"

import { navigationLinks } from "../../../../constants/commonConstants";
import { IndustriesQuery } from "../../../../services/graphql-queries";
import { POST_GRAPHQL } from "../../../../services/httpService";
import {
  setIsAdmin,
  setNavigationMenu,
  setUserEmail,
} from "../../../../store/actions/navigationAction";
import { removeSpaceWithHypens, sortByKey } from "../../../../utils";
import "./Navbar.scss";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import logout from "../../../../assets/images/common/logout.png";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { Learnmore1Image } from "../../../../constants/images";
import {
  resetStorefrontActiveTab,
  setNewCapabilityList,
  toggleTheModal,
} from "../../../../store/actions/storefrontAction";
import Loader from "../../../loader";
import { removeLocalStorage } from "../../../../utils/utilities";
import { actionTracking } from "../../../../utils/omniture";
import { pageNames } from "../../../../constants/omnitureConstants";
import FeedbackModal from "../../../FeedbackModal/FeedbackModal.Component";

function Navbar() {
  const { instance } = useMsal();
  const isMsalAuthenticated = useIsAuthenticated();
  const currentAccount = instance.getActiveAccount();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState({});
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [industryData, setIndustryData] = useState([]);
  const [feedbackModal, setFeedbackModal] = useState(false);

  const [loggedUserFullName, setLoggedUserFullName] = useState("");
  const [adminTabStatus, setAdminTabStatus] = useState("");
  const [loggedUserEmail, setLoggedUserEmail] = useState("");
  const [userPhotoBase64, setUserPhotoBase64] = useState(user);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Capability Info");
  const navbarTabs = [
                {name: "My DigiPrints", path: '/mydigiprints'}, 
                {name: "Contacts", path: '/contacts'},
                {name: "FAQ", path: '/faq'},
                {name:"Admin", path:'/adminsettings'}
              ];

  useEffect(() => {
    //fetchLoggedUserData();
    dispatch(setNavigationMenu(navigationLinks.Home));
  }, []);

  

  useEffect(() => {
    if (
      location.pathname !== "/" &&
      location.pathname !== "/adminsettings" &&
      location.pathname !== "/rediscache"
    ) {
      dispatch(setNavigationMenu(navigationLinks.Storefronts));
    } else if (location.pathname !== "/") {
      window.scrollTo(0, 0);
      dispatch(setNavigationMenu(navigationLinks.Admin));
      dispatch(resetStorefrontActiveTab());
      removeLocalStorage("SFActiveTab");
      removeLocalStorage("CurrentSubSector");
    } else if (location.pathname !== "/Contacts") {
      dispatch(setNavigationMenu(navigationLinks.Contacts));
    }
     else {
      dispatch(setNavigationMenu(navigationLinks.Home));
      dispatch(resetStorefrontActiveTab());
      removeLocalStorage("SFActiveTab");
      removeLocalStorage("CurrentSubSector");
    }
  }, [location]);
  const navigationReducer = useSelector((state) => ({
    link: state.navigationReducer,
  }));

  const userDataReducers = useSelector((state) => state.navigationReducer);

  const sfReducer = useSelector((state) => ({
    item: state.storefrontReducer,
  }));

  useEffect(() => {
    let webApiUserData = userDataReducers?.loggedUserData;

    if (webApiUserData && Object.keys(webApiUserData).length > 0) {
      if (webApiUserData.picture) {
        setUserPhotoBase64("data:image/jpeg;base64," + webApiUserData.picture);
      } else {
        setUserPhotoBase64(user);
      }
      setLoggedUserEmail(webApiUserData.email);
      dispatch(setUserEmail(webApiUserData.email));
      setLoggedUserFullName(webApiUserData.fullName);
      setAdminTabStatus(webApiUserData.isAdmin);
      fetchIndustries(webApiUserData.accessToken);
      dispatch(setIsAdmin(webApiUserData.isAdmin));
    }
  }, [userDataReducers?.loggedUserData]);

  useEffect(() => { }, [adminTabStatus]);

  const fetchIndustries = async (accessToken) => {
    setPageIsLoading(true);
    try {
      let responseData = await POST_GRAPHQL(
        IndustriesQuery.GET_INDUSTRY_LIST_WITH_SECTOR_SUBSECTOR,
        {},
        { accessToken: accessToken },
        "GET_INDUSTRY_LIST_WITH_SECTOR_SUBSECTOR"
      );

      if (responseData && responseData.data && responseData.data.data) {
        let industryList = responseData.data.data.industryList.items;
        let sectorList = responseData.data.data.sectorList.items;
        let subsectorList = responseData.data.data.subsectorList.items;

        if (industryList && sectorList && subsectorList) {
          let industriesValue = sortByKey(industryList, "displayOrder");
          let sectorsData = sectorList;
          let subSectorsData = subsectorList;
          let finalData = [];
          let sectorsTempData = [];
          sectorsData.forEach((element) => {
            let newValue = {
              ...element,
            };

            let subSectors = [];
            subSectors = subSectorsData.filter(
              (subSector) =>
                subSector &&
                subSector.sector &&
                element._path &&
                subSector.sector._path == element._path
            );
            newValue.subSectors = subSectors;
            sectorsTempData.push(newValue);
          });

          industriesValue.forEach((industry) => {
            let industryTemp = {
              ...industry,
            };
            let sectorsList = [];
            sectorsList = sectorsTempData.filter(
              (sector) =>
                sector &&
                sector.industry &&
                industry._path &&
                sector.industry._path == industry._path
            );

            industryTemp.sectors = sectorsList;
            finalData.push(industryTemp);
          });

          setIndustryData(sortByKey(finalData, "displayOrder"));
        }
      }

      setPageIsLoading(false);
    } catch (err) {
      setPageIsLoading(false);
      console.log("Navbar  err ", err);
    }
  };

  const changeSelectedTab = (industry) => {
    if (navigationReducer.link.userEmail !== "") {
      actionTracking(
        "Storefront Popup",
        navigationReducer.link.userEmail,
        "Industry: " + industry.industryName
      );
    }
    setSelectedIndustry(industry);
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect({ account: currentAccount }).catch((e) => {
      console.error(e);
    });
  };

  const removeInActiveIndustries = (industryData) => {
    return industryData.filter((industry, index1) => {
      let anyActiveSector = false;
      if (!industry.isActive) return false;
      industryData[index1].sectors = industry.sectors.filter((sector, index2) => {
        let anyActiveSubSector = false;
        industryData[index1].sectors[index2].subSectors = sector.subSectors.filter(subSector => {
          if (subSector.isActive) anyActiveSubSector = true;
          return subSector.isActive
        })
        if (!anyActiveSubSector && sector.isActive) sector.isActive = false
        if (sector.isActive)
          anyActiveSector = true;
        return sector.isActive;
      })
      if (!anyActiveSector && industry.isActive) industry.isActive = false;
      return industry.isActive;
    })
  }
  const clearSelection = () => {
    if (document.selection && document.selection.empty) {
      document.selection.empty();
    } else if (window.getSelection) {
      var sel = window.getSelection();
      sel.removeAllRanges();
    }
  };

  const navigateTo = (path, isStoreRedirect = false) => {
    let modifiedPath = removeSpaceWithHypens(path);
    if (sfReducer.item.currentSubSector !== modifiedPath) {
      dispatch(resetStorefrontActiveTab());
    }

    if (navigationReducer.link.userEmail !== "") {
      actionTracking(
        "Storefront Popup",
        navigationReducer.link.userEmail,
        "SubSector: " + path
      );
    }

    if (isStoreRedirect) {
      let url = window.location.href;
      url = url.split("/");
      url = url[url.length -1];

      url !== modifiedPath && dispatch(setNewCapabilityList([]));
      navigate(`/storefront/${modifiedPath}`);

    } else {
      navigate(`/sectors/${modifiedPath}`);
    }
  };
  const url=window.location.href;

  return (
    <>
      <div className="align-middle row navbar" id="navbar">
        {/* Deloitte Logo Area */}
        <div className={`col-lg-2 col-sm-4 ${toggle && "icon-up"}`} id="logo"   
        style={(url.split("/")[url.split("/").length-2]==="storefront") || 
        navbarTabs.map(tab=>tab.path).includes("/"+url.split("/")[url.split("/").length-1]) ?{
          marginTop:`${navbarTabs.map(tab=>tab.path).includes("/"+url.split("/")[url.split("/").length-1]) && !toggle ?'-14px':'0px'}`
        }:{paddingTop:`${toggle?'8px':'5px'}`}}>
          <img src={logo} alt="" className="deloitte-logo" />
          <img src={greenDot} alt="" className="deloitte-green-dot" />
          <span className="separator">|</span>
          <span className="digiPrint-title"
            onClick={() => {
              dispatch(setNavigationMenu(navigationLinks.Home));
              navigationReducer.link.userEmail !== "" &&
                actionTracking(
                  pageNames.NAV_BAR,
                  navigationReducer.link.userEmail,
                  "Home"
                );
              setToggle(false);
              setSelectedIndustry({});
              navigate("/");
            }}>DigiPrint</span>
        </div>
        <div className="col-lg-8 nav-links" id="navigation" style={{display:'flex', justifyContent:'flex-start'}}>
            <div
              className={`${navigationReducer?.link?.menu === navigationLinks.Storefronts && url.split("/")[url.split("/").length-2]==="storefront"
                ? `${!toggle && "tabNameSelected"} nav-link-inactive`
                : "nav-link-inactive"
                } ${toggle && "storefront-menu-up"}`}
                style={toggle && (url.split("/")[url.split("/").length-2]==="storefront") || 
                navbarTabs.map(tab=>tab.path).includes("/"+url.split("/")[url.split("/").length-1]) ?{height:'50px'}:{}}
              onClick={() => {
                dispatch(toggleTheModal(false));
                navigationReducer.link.userEmail !== "" &&
                  actionTracking(
                    pageNames.NAV_BAR,
                    navigationReducer.link.userEmail,
                    "Storefronts Popup",
                    "Click"
                  );
                !toggle && setFeedbackModal(false);
                setToggle(!toggle);
              }}
              onMouseEnter={(e) => { const tabId = document.getElementById('Industry'); !toggle && url.split("/")[url.split("/").length-2]!=="storefront" && tabId.classList.add("tabNameHover") }}
              onMouseLeave={(e) => { const tabId = document.getElementById("Industry");   tabId.classList.remove("tabNameHover") }}
            >
                <div className={`${navigationReducer?.link?.menu === navigationLinks.Storefronts && url.split("/")[url.split("/").length-2]==="storefront" ?'':'tabName'} ${toggle && "storefront-menu-openmodal"}`} id="Industry">
                  Industry
              </div>
            </div>
              {
                navbarTabs.map(tab => {
                  if(tab.name=== 'Admin' && !adminTabStatus) return ''
                  const tabId = document.getElementById(tab.name);
                  let url= window.location.href;
                  url=url.split("/");
                  url=url[ url.length-1 ];

                  tabId && "/"+url !== tab.path && tabId.classList.remove("tabNameSelected");
                  tabId && "/"+url === tab.path && tabId.classList.add("tabNameSelected");
                  return <div  className={`nav-link-inactive`}
                    onClick={() => {
                      const tabId = document.getElementById(tab.name);
                      tabId.classList.remove("tabNameHover");
                      tabId.classList.add("tabNameSelected")
                      setToggle(false);
                      if(tab.name==="Admin"){
                        window.scrollTo(0, 0);
                        dispatch(setNavigationMenu(navigationLinks.Admin));
                        setSelectedIndustry({})
                        navigationReducer.link.userEmail !== "" &&
                          actionTracking(
                            pageNames.NAV_BAR,
                            navigationReducer.link.userEmail,
                            "Admin"
                          );
                      }
                      navigate(tab.path)
                    }}
                    onDoubleClick={(e) => tab.name === 'admin' && clearSelection()}

                    onMouseEnter={(e) => { const tabId = document.getElementById(tab.name); tabId.classList.add("tabNameHover") }}
                    onMouseLeave={(e) => { const tabId = document.getElementById(tab.name); tabId.classList.remove("tabNameHover") }}>
                    <div key={tab.name} id={tab.name} className={`tabName ${tab.name==='admin' && toggle && "opened-modal-nav-link-active home-up"}`} >
                      {tab.name}
                    </div>
                  </div>
                })
              }
          </div>
        
        <div
          className={`col-lg-4 navbar-login-user-popup ${toggle && "icon-up"}`}
          id="user"
          onClick={() => {
            navigationReducer.link.userEmail !== "" &&
              actionTracking(
                pageNames.NAV_BAR,
                navigationReducer.link.userEmail,
                "Avatar",
                "Click"
              );
          }}
        >
          <img onClick={()=>{!feedbackModal && setToggle(false); setFeedbackModal(!feedbackModal)}} src={SubmitFeedback} className='giveFeedback' alt="feedback"/>
          <UncontrolledDropdown>
            <DropdownToggle tag="div">
              <img
                src={userPhotoBase64}
                alt=""
                className="user-logo align-middle"
              />
            </DropdownToggle>
            <DropdownMenu className="nav-user-menu">
              <div className="row">
                <div className="col-lg-3">
                  <img
                    src={userPhotoBase64}
                    alt=""
                    className="navbar-login-userpopup-image"
                  />
                </div>
                <div className="col-lg-9">
                  <div
                    style={{
                      color: "blue",
                      marginTop: "-3px",
                      fontSize: "13px",
                    }}
                  >
                    <p className="navbar-login-user-name">
                      {loggedUserFullName}
                    </p>
                    <p className="navbar-login-user-email">{loggedUserEmail}</p>
                  </div>
                </div>
              </div>
              <p className="navbar-login-user-popup-bottom"></p>
              <div
                className="row"
                onClick={() => {
                  navigationReducer.link.userEmail !== "" &&
                    actionTracking(
                      pageNames.NAV_BAR,
                      navigationReducer.link.userEmail,
                      "Logout"
                    );

                  handleLogoutRedirect();
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="col-lg-3">
                  <center>
                    <img
                      src={logout}
                      alt=""
                      className="user-navbar-logout-icon"
                    />
                  </center>
                </div>
                <div className="col-lg-9">
                  <p className="user-navbar-logout-icon1">Log Out</p>
                </div>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      <Modal
        className="navbar-modal"
        size="lg"
        isOpen={toggle}
        toggle={() => { setToggle(!toggle); setSelectedIndustry({}) }}
      >
        {pageIsLoading && (
          <div className={`row sub-sector-section-${Object.keys(selectedIndustry).length > 0 ? "selected" :
            "unselected"}`}>
            {" "}
            <Loader />
          </div>
        )}
        {!pageIsLoading && industryData && industryData.length > 0 && (
          <div className={`row sub-sector-section-${Object.keys(selectedIndustry).length > 0 ? "selected" :
            "unselected"}`}>
            <div className={`border-right storefront-popup-left-${Object.keys(selectedIndustry).length > 0 ? "selected" :
              "unselected"}`}>
              <p className="sub-sectors-box sub-sectors-header">Industries</p>

              {removeInActiveIndustries(industryData).map((industry, index) => {
                return industry.isActive ?
                  <p
                    key={"key-" + index}
                    className={`${selectedIndustry.industryName === industry.industryName
                      ? "sub-sectors-text-active" :
                      industry.isActive ? "sub-sectors-text-inactive" : "sub-sectors-text-disabled"
                      } `}
                    onClick={() =>
                      industry.isActive && changeSelectedTab(industry)
                    }
                  >
                    {industry.industryName}
                    {selectedIndustry.industryName === industry.industryName ? (
                      <img src={sideArrowWhite} className="side-arrow" alt="" />
                    ) : (
                      <img src={sideArrowBlack} className="side-arrow" alt="" />
                    )}
                  </p>
                  : ''
              })}
            </div>
            {
              Object.keys(selectedIndustry).length > 0 && <div className="storefront-popup-right">
                <p className="sub-sectors-box sub-sectors-header">
                  {selectedIndustry ? selectedIndustry.industryName : ''}
                </p>
                <div className="sub-sectors-overflow sub-sectormenu-dropdown">
                  {selectedIndustry &&
                    selectedIndustry.sectors &&
                    selectedIndustry.sectors.map((sector, index) => (
                      <div key={"key-" + index}>
                        <p className="sub-sectors-heading">{sector.sectorName}</p>
                        {sector &&
                          sector.subSectors &&
                          sector.subSectors.map((subSector, subSectorIndex) => (
                            <p
                              key={"key-" + subSectorIndex}
                              className={
                                !subSector.isActive || !sector.isActive
                                  ? "sub-sectors-sub-text-inactive sub-sectors-inactive"
                                  : "sub-sectors-sub-text-inactive"
                              }
                              onClick={() => {
                                if (subSector.isActive && sector.isActive) {
                                  setToggle(!toggle);
                                  navigateTo(subSector.subsectorName, true);
                                  setActiveTab('Industry')
                                  setSelectedIndustry({});
                                }
                              }}
                            >
                              {subSector.subsectorName}
                            </p>
                          ))}
                      </div>
                    ))}
                </div>
              </div>
            }
          </div>
        )}
        {!pageIsLoading && industryData && industryData.length == 0 && (
          <div className={`row sub-sector-section-${Object.keys(selectedIndustry).length > 0 ? "selected" :
            "unselected"}`}>
            <div className="justify-center">
              <h4>No records found</h4>
            </div>
          </div>
        )}
      </Modal>
      <FeedbackModal setFeedbackModal={setFeedbackModal} feedbackModal={feedbackModal}/>
    </>
  );
}

export default Navbar;
