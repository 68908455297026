import { ConvertAssetsToJsonData, AssetsDataFilterByKey, groupBy, sortByKey } from './assets_data_converter';
import { removeHypensWithSpace, removeSpaceWithHypens } from './url_slug'

const getCurrentMonthName = () => {
    return new Date().toLocaleString("default", { month: "long" });
}

const getCurrentYear = () => {
    return new Date().getFullYear();
}

const getCurrentMonthNameFromDate = (date) => {
    return new Date(date).toLocaleString("default", { month: "long" });
}

const getCurrentYearFromDate = (date) => {
    return new Date(date).getFullYear();
}
export {
    ConvertAssetsToJsonData,
    AssetsDataFilterByKey,
    groupBy,
    sortByKey,
    removeHypensWithSpace,
    removeSpaceWithHypens,
    getCurrentMonthName,
    getCurrentYear,
    getCurrentMonthNameFromDate,
    getCurrentYearFromDate
}