import React from "react";
import classes from "./SelectionTemplate.module.scss";
import { Row, Col } from 'reactstrap';
import { removeHypensWithSpace } from "../../../utils";

function SelectionTemplate(props) {
    var { selectedCapabilities, selectedBusinessValue, index, subsectorName } = props;
    const allBusinessValues = ['Business Growth', 'Customer Experience', 'Ops Efficiency', 'Risk & Regulatory', 'Workforce & Culture'];

    const allBusinessValuesColor = ['#0D8390', 'black', '#86BC25', '#DA291C', '#007CB0'];

    return (
        <div className={classes.container} id={`capability-template-5${index}`} style={{ width: '1280px !important' ,position:'absolute', top:'-99999px', left:'-99999px'}}>
            <div className={classes.digiprint}>{removeHypensWithSpace(subsectorName) +" Capabilities"}</div>
            <Row className={classes.containerBody}>
                {
                    selectedCapabilities.map((valueChain, index) => {
                        return <Col className={`col-md-4 col-lg-4`} style={{ padding: '0px 10px 20px 10px' }} key={valueChain.valueChainName} >
                            <div className={classes.card}>
                                <div className={classes.valueChain}>{valueChain.valueChainName}</div>
                                <div className={classes.capabilityNameContainer}>
                                    {
                                        valueChain.capabilityList.map((capability, index) =>
                                            <div key={capability.name}
                                                className={classes.capabilityContainer} style={capability.businessValue.map(value => value.name.replace('and', '&')).includes(selectedBusinessValue) ?
                                                    { color: allBusinessValuesColor[allBusinessValues.indexOf(selectedBusinessValue)] } : { color: selectedBusinessValue ? '#BBBCBC' : 'rgba(0, 0, 0, 0.7)' }}>
                                                <div className={classes.capabilityName}>{capability.capabilityName}</div>
                                            </div>)

                                    }
                                </div>
                            </div>
                        </Col>
                    })
                }
            </Row>
            <div className={classes.selectionContainer}>
                {
                    allBusinessValues.map((value, index) =>
                        <div className={classes.selectionName} style={value === selectedBusinessValue ? { background: allBusinessValuesColor[index], borderRadius: '100px', color: 'white' } : {}} key={index}>{value}</div>
                    )
                }
            </div>
        </div>
    );
}

export default SelectionTemplate;
