import React, { useEffect, useState } from "react";
import ExpandCollapseIcon from "../ExpandCollapseIcon/ExpandCollapseIcon.component";
import classes from "./CapabilityDrawer.module.scss";
import { Col, Row } from "reactstrap";
import { Space } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import DigiprintExport from "../../views/digiprint-export";

function CapabilityDrawer( props ) {

    const { totalCapabilities, totalSelected, removeCapability, sfReducer, coverTemplateImage} = props;
    const { selectedCapabilities } = props || []
    const [ isActive, setIsActive ] = useState(true);
    const transitionProperties =  totalSelected === 0 ? { marginBottom: -100 }: { marginBottom: 0 }
    useEffect(()=>{
        if( totalSelected===0 )
            setIsActive(true);
    },[selectedCapabilities, totalSelected]);

    return <div  className={ classes.container } style={ transitionProperties }>
        {
            totalSelected>0 && <div className={ classes.containerHeader }>
                <span onClick={()=>setIsActive(!isActive)} id="expandCollapseArrow"><ExpandCollapseIcon isActive = { isActive } isDark= {false}/></span>
                <span className={ classes.selected }> { totalSelected }/{ totalCapabilities } capabilities selected</span>
                <DigiprintExport sfReducer={sfReducer} coverTemplateImage={coverTemplateImage}/>
            </div>
        }
        {
            !isActive && totalSelected>0 && <Row className={ classes.containerBody}>
            {
                    selectedCapabilities.map((valueChain,index)=>{
                        var valueChainIndex = index;
                        return <Col className="col-md-4 col-lg-4" key={valueChain.valueChainName} >
                            <div className={classes.valueChain}>{ valueChain.valueChainName }</div>
                            {
                               valueChain.capabilityList.map((capability,index)=> 
                                    <div  key={capability.capabilityName} className={classes.capabilityContainer}>
                                        <div className={classes.capabilityName}>{capability.capabilityName}</div>
                                        <div  id={capability.capabilityName+index} onClick={() => { removeCapability(index,valueChainIndex, selectedCapabilities) }} className={classes.minusIcon}><Space><MinusOutlined  /></Space></div>
                                    </div>)
                            }
                        </Col>
                    })
            }
            </Row>
        }  
       
    </div>

}

export default CapabilityDrawer;
