import React, { useEffect, useState } from 'react'
import { POST_GRAPHQL, POST_CALL } from "../../services/httpService";
import { ContactusQuery } from '../../services/graphql-queries';
import { urlToDiv } from '../../utils/utilities';
import { sortByKey } from '../../utils';
import ContactCard from '../../components/ContactCard/ContactCard.Component'
import ContactsPageNavbar from '../../components/ContactsPageNavbar/Navbar.Component'
import './Contacts.scss'
import Loader from '../../components/loader';

const Contacts = (props) => {

    const [loading, setLoading] = useState(true)
    const [contactsData, setContactsData] = useState([])
    const [contactDetailLoading, setContactDetailLoading] = useState(false)
    const [contactTab, setContactTab] = useState({});
    const [contactNavTabs, setContactNavTabs] = useState([]);
    const [digiprintLeadershipContacts, setDigiprintLeadershipContacts] = useState([])
    const [digiprintLeadershipContactDetails, setDigiprintLeadershipContactsDetails] = useState([])

    const [industryContacts, setIndustryContacts] = useState([])
    const [industryContactDetails, setIndustryContactDetails] = useState([])

    const navId = document.getElementById('navbar');
    navId?.classList?.remove('navbarTransparent');
    navId?.classList?.add('navbar')

    useEffect(() => {
        GET_DATA();
    }, [])

    const GET_DATA = async () => {
        setLoading(true)
        try {
          let responseData = await POST_GRAPHQL(ContactusQuery.GET_CONTACT_LIST, {}, { accessToken: props.accessToken }, 'GET_CONTACT_LIST');
    
          if (responseData && responseData.data && responseData.data.data) {
            let contactgroupList = responseData.data.data.contactgroupList;
            console.log('contactgroupList ', contactgroupList)
            if (contactgroupList && contactgroupList.items) {
              let orderList = sortByKey(contactgroupList.items, 'displayOrder')
              setContactsData(orderList)
              console.log('orderList ', orderList)
              GET_Contact_Detail(orderList[0], orderList[0].contactGroupName)
              setDigiprintLeadershipContacts(orderList[0].contacts)

              let tempNavArray = orderList?.map((contact) => {
                return contact.contactGroupName
              })
              let index = tempNavArray.indexOf('DigiPrint Owner')
              tempNavArray.splice(index, 1).sort();
              orderList.map((item) => {
                if(item.contactGroupName === tempNavArray.sort()[0]) {
                    console.log('item ', item)
                    GET_Contact_Detail(item, item.contactGroupName)
                    setContactTab(item)
                }
                return false
              })
              setContactNavTabs(tempNavArray)
              console.log('tempNavArray ', tempNavArray.sort())
            }
          }
    
          setLoading(false);
          urlToDiv();
        } catch (err) {
            setLoading(false)
            console.log('err ', err);
        }
    }

    const GET_Contact_Detail = async (selectedContactGroup, contactGroupName) => {
        console.log(' selectedContactGroup ', selectedContactGroup)
        setContactTab(selectedContactGroup)
        setIndustryContactDetails([])
        setContactDetailLoading(true)
        try {
          let selectedUserEmails = [];
          let contacts = selectedContactGroup.contacts;
          contacts?.map(item => {
            if (item.contactEmail) {
              selectedUserEmails.push(item.contactEmail)
            }  
          })
    
          let url = '/api/user/details'
          let responseData = await POST_CALL(url, selectedUserEmails, { accessToken: props.accessToken });
    
          let responseResult = responseData.data;
          if (responseResult && responseResult.length > 0) {
            let contactResult = [];
    
            responseResult.forEach(element => {
              let contactGroupData = contacts.filter(item => item.contactEmail && item.contactEmail.toLowerCase() === element.email.toLowerCase())[0];
              let contactData = element;
              contactData.subSector = contactGroupData && contactGroupData.subSector ? contactGroupData.subSector : null;
              contactResult.push(contactData)
            }); 
            
            if(contactGroupName === 'DigiPrint Owner') {
                setDigiprintLeadershipContactsDetails(contactResult)
                console.log('contactResult for digiprint owner', contactResult) 
            }
            else {
                console.log('contactResult for industry ', selectedContactGroup , contactResult) 
                setIndustryContactDetails(contactResult)
            }
          }   
          setContactDetailLoading(false)   
        } catch (err) {
          setContactDetailLoading(false)
          console.log('err ', err);
        }
    }

    const navigateToTab = (industryName) => {
        contactsData.map((contactObj) => {
            if(contactObj.contactGroupName === industryName) {
                GET_Contact_Detail(contactObj)
                //return contactObj
            }
            return false;
        })
    }

    return (
        <div style={{paddingTop: '2.8rem'}}>
            <div className='header'>Our Team</div>
            <div style={{padding: '40px', backgroundColor: '#F4F4F4'}}>
                <div className='sub-header'>Digiprint Leadership</div>
                <div style={{marginBottom:'80px', display: 'flex', gap:'32px'}}>
                    {digiprintLeadershipContactDetails?.length > 0 && 
                    digiprintLeadershipContactDetails?.map((item) => (
                        <ContactCard contactPersonImg={item.picture} name={item.fullName} designation={item.jobTitle} email={item.email} industryFlag="false"/>
                    ))}
                </div>
                <ContactsPageNavbar selectedTab = {navigateToTab} activeTab={contactTab.contactGroupName} tabs={contactNavTabs}/>
                <div style={{marginBottom: '40px', marginTop: '40px', display: 'flex', flexWrap: 'wrap', gap:'32px'}}>
                    {
                        contactDetailLoading && <Loader/>
                    }
                    {industryContactDetails.length > 0 && 
                    industryContactDetails.map((item) => (
                        <ContactCard contactPersonImg={item.picture} name={item.fullName} designation={item.jobTitle} email={item.email} industryFlag="true" subsectorArr={[item.subSector.subsectorName]}/>
                    )) }
                </div>
            </div>
        </div>
    )
}

export default Contacts