import React from 'react'
import { Collapse } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { setNewBvMaturityList, setNewTechMaturityList, setNewDapMaturityList, setNewEcoMaturityList } from '../../store/actions/storefrontAction';
import styles from './CollapsibleCard.module.scss'
import CheckboxGroup from '../checkbox-group/CheckboxGroup.Component';
import ExpandCollapseIcon from "../ExpandCollapseIcon/ExpandCollapseIcon.component";

const { Panel } = Collapse;

let nameOfProperty = "";
let countObj = {
    "Business Value": [],
    "Technology Enablement": [],
    "Asset Maturity": [],
    "Third-Party Solutions": []
};
function CollapsibleCard(props) {

    const { sfReducer, setUpdatedMaturityList }  = props;
    const dispatch = useDispatch();

    if(props.reset === true) {
        countObj = {
            "Business Value": [],
            "Technology Enablement": [],
            "Asset Maturity": [],
            "Third-Party Solutions": []
        };
    }

    if(props.removeFilterSelection) {
        if(countObj["Business Value"].includes(props.removeFilterSelection)) {
            countObj["Business Value"].splice(countObj["Business Value"].indexOf(props.removeFilterSelection), 1)
        }
        else if(countObj["Technology Enablement"].includes(props.removeFilterSelection)) {
            countObj["Technology Enablement"].splice(countObj["Technology Enablement"].indexOf(props.removeFilterSelection), 1)
        }
        else if(countObj["Asset Maturity"].includes(props.removeFilterSelection)) {
            countObj["Asset Maturity"].splice(countObj["Asset Maturity"].indexOf(props.removeFilterSelection), 1)
        }
        else if(countObj["Third-Party Solutions"].includes(props.removeFilterSelection)) {
            countObj["Third-Party Solutions"].splice(countObj["Third-Party Solutions"].indexOf(props.removeFilterSelection), 1)
        }
    }

    let options = [...sfReducer.item.newBvMaturityList, ...sfReducer.item.newTechMaturityList, ...sfReducer.item.newDapMaturityList, ...sfReducer.item.newEcoMaturityList]
    props.persistFiltersInSessionStorage(options)

    let property;
    if(props.index === 0) {
        nameOfProperty = "Business Value";
        property = <div style={{fontFamily: 'Open Sans Semibold', fontSize:'14px'}}>Business Value {countObj[nameOfProperty].length > 0 && <span>({countObj[nameOfProperty].length})</span>}</div>
    }
    else if(props.index === 1) {
        nameOfProperty = "Technology Enablement";
        property = <div style={{fontFamily: 'Open Sans Semibold', fontSize:'14px'}}>Technology Enablement {countObj[nameOfProperty].length > 0 && <span>({countObj[nameOfProperty].length})</span>}</div>
    }
    else if(props.index === 2) {
        nameOfProperty = "Asset Maturity";
        property = <div style={{fontFamily: 'Open Sans Semibold', fontSize:'14px'}}>Deloitte Asset Portfolio {countObj[nameOfProperty].length > 0 && <span>({countObj[nameOfProperty].length})</span>}</div>
    }
    // else if(props.index === 3) {
    //     nameOfProperty = "Third-Party Solutions";
    //     property = <div style={{fontFamily: 'Open Sans'}}><strong>Third-Party Solutions {countObj[nameOfProperty].length > 0 && <span>({countObj[nameOfProperty].length})</span>}</strong></div>
    // }  to be included later whe data is available

    let tempSet = new Set([...sfReducer.item.newBvMaturityList, ...sfReducer.item.newTechMaturityList, ...sfReducer.item.newDapMaturityList, ...sfReducer.item.newEcoMaturityList])
    let tempSetBV = new Set(sfReducer.item.newBvMaturityList)
    let tempSetTech = new Set(sfReducer.item.newTechMaturityList)
    let tempSetMaturity = new Set(sfReducer.item.newDapMaturityList)
    let tempSetEco = new Set(sfReducer.item.newEcoMaturityList)

    const onChange = (value, action, keyName) => {
        props.changeReset()
        if (action === 'add') {
            countObj[keyName].push(value)
            tempSet.add(value)
            switch(keyName)
            {
                case "Business Value":
                    tempSetBV.add(value)
                    break;
                case "Technology Enablement":
                    tempSetTech.add(value)
                    break;
                case "Asset Maturity":
                    tempSetMaturity.add(value)
                    break;
                case "Third-Party Solutions":
                    tempSetEco.add(value)
                    break;

            }
        }
        else {
            countObj[keyName].splice(countObj[keyName].indexOf(value), 1)
            tempSet.delete(value)
            switch(keyName)
            {
                case "Business Value":
                    tempSetBV.delete(value)
                    break;
                case "Technology Enablement":
                    tempSetTech.delete(value)
                    break;
                case "Asset Maturity":
                    tempSetMaturity.delete(value)
                    break;
                case "Third-Party Solutions":
                    tempSetEco.delete(value)
                    break;

            }
        }
        switch(keyName)
            {
                case "Business Value":
                    dispatch(setNewBvMaturityList(tempSetBV))
                    break;
                case "Technology Enablement":
                    dispatch(setNewTechMaturityList(tempSetTech))
                    break;
                case "Asset Maturity":
                    dispatch(setNewDapMaturityList(tempSetMaturity))
                    break;
                case "Third-Party Solutions":
                    dispatch(setNewEcoMaturityList(tempSetEco))
                    break;

            }
    }

    return (
        <div>    
            <Collapse collapsible="icon" className={styles.headerClass} defaultActiveKey={[0, 1, 2, 3]} expandIconPosition="end" ghost
            expandIcon={({ isActive }) => <ExpandCollapseIcon isActive={isActive} isDark={true}/>}>
                <Panel header={property} key={props.index}>
                    { props.maturityList.length !== 0
                    ? 
                    props.maturityList.map((item) => {
                        return  <div style={{padding: "5px", marginLeft: "5px", fontSize: "12px"}}>
                                    <CheckboxGroup nameOfProperty={nameOfProperty} specKey={props.specKey} name={item.name} options={options} onChange={onChange}/>
                                </div>
                    })
                    :
                    <p>no properties to display</p>
                    }
                </Panel>
            </Collapse>
        </div>
    )
}

export default CollapsibleCard