import {
  SET_STOREFRONT_ACTIVE_TAB,
  RESET_STOREFRONT_ACTIVE_TAB,
  SET_STOREFRONT_VIEW_TAB,
  SET_MATURITY_LIST_DATA,
  SET_ACTIVE_VALUE_CHAIN,
  SET_ACTIVE_CARD_DATA,
  SET_CURRENT_SUBSECTOR,
  TECH_SET_MATURITY_LIST_DATA,
  DAP_SET_MATURITY_LIST_DATA,
  DC_SET_MATURITY_LIST_DATA,
  ECO_SET_MATURITY_LIST_DATA,
  BV_SET_MATURITY_LIST_DATA,
  IE_SET_MATURITY_LIST_DATA,
  TOGGLE_MODAL,
  SET_NEW_MATURITY_LIST_DATA,
  SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES,
  SET_ACTIVE_VALUECHAIN_SPECIFIC_CAPABILITIES,
  SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES_DESCRIPTIONS,
  SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES_BENEFITS,
  SET_NEW_CAPABILITY_LIST_DATA,
  SET_NEW_BV_MATURITY_LIST_DATA,
  SET_NEW_TECH_MATURITY_LIST_DATA,
  SET_NEW_DAP_MATURITY_LIST_DATA,
  SET_NEW_ECO_MATURITY_LIST_DATA,
  SET_BOOKMARK_LIST_DATA,
  SET_SUBSECTOR_LIST_DATA,
  SET_IS_BOOKMARK_CREATED
} from "../types";

export const setStorefrontActiveTab = (activeTab) => async (dispatch) => {
  await dispatch({
    type: SET_STOREFRONT_ACTIVE_TAB,
    payload: activeTab,
  });
};

export const resetStorefrontActiveTab = () => async (dispatch) => {
  await dispatch({
    type: RESET_STOREFRONT_ACTIVE_TAB,
  });
};

export const setStorefrontViewTab = (storefrontViewTab) => async (dispatch) => {
  await dispatch({
    type: SET_STOREFRONT_VIEW_TAB,
    payload: storefrontViewTab,
  });
};

export const setMaturityList = (maturityList) => async (dispatch) => {
  await dispatch({
    type: SET_MATURITY_LIST_DATA,
    payload: maturityList,
  });
};

export const setActiveValuechain = (activeValueChain) => async (dispatch) => {
  await dispatch({
    type: SET_ACTIVE_VALUE_CHAIN,
    payload: activeValueChain,
  });
};

export const setActiveDataCard = (activeDataCard) => async (dispatch) => {
  await dispatch({
    type: SET_ACTIVE_CARD_DATA,
    payload: activeDataCard,
  });
};

export const setCurrentSubSector = (currentSubSector) => async (dispatch) => {
  await dispatch({
    type: SET_CURRENT_SUBSECTOR,
    payload: currentSubSector,
  });
};

export const setTechMaturityList = (techMaturityList) => async (dispatch) => {
  await dispatch({
    type: TECH_SET_MATURITY_LIST_DATA,
    payload: techMaturityList,
  });
};

export const setDapMaturityList = (dapMaturityList) => async (dispatch) => {
  await dispatch({
    type: DAP_SET_MATURITY_LIST_DATA,
    payload: dapMaturityList,
  });
};

export const setDCMaturityList = (dcMaturityList) => async (dispatch) => {
  await dispatch({
    type: DC_SET_MATURITY_LIST_DATA,
    payload: dcMaturityList,
  });
};

export const setEcoMaturityList = (ecoMaturityList) => async (dispatch) => {
  await dispatch({
    type: ECO_SET_MATURITY_LIST_DATA,
    payload: ecoMaturityList,
  });
};

export const setBVMaturityList = (bvMaturityList) => async (dispatch) => {
  await dispatch({
    type: BV_SET_MATURITY_LIST_DATA,
    payload: bvMaturityList,
  });
};

export const setIEMaturityList = (ieMaturityList) => async (dispatch) => {
  await dispatch({
    type: IE_SET_MATURITY_LIST_DATA,
    payload: ieMaturityList,
  });
};

export const toggleTheModal = (toggleModal) => async (dispatch) => {
  await dispatch({
    type: TOGGLE_MODAL,
    payload: toggleModal,
  });
};

export const setNewBvMaturityList = (maturityList) => async (dispatch) => {
  await dispatch({
    type: SET_NEW_BV_MATURITY_LIST_DATA,
    payload: maturityList,
  });
}

export const setActiveSubsectorSpecificCapabilities = (allCapabilities) => async (dispatch) => {
  await dispatch({
    type: SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES,
    payload: allCapabilities,
  });
} 

export const setActiveValueChainSpecificCapabilities = (allCapabilities) => async (dispatch) => {
  await dispatch({
    type: SET_ACTIVE_VALUECHAIN_SPECIFIC_CAPABILITIES,
    payload: allCapabilities,
  });
} 

export const setActiveSubsectorSpecificCapabilitiesDescriptions = (item) => async (dispatch) => {
  await dispatch({
    type:  SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES_DESCRIPTIONS,
    payload: item,
  });
} 

export const setActiveSubsectorSpecificCapabilitiesBenefits = (item) => async (dispatch) => {
  await dispatch({
    type:  SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES_BENEFITS,
    payload: item,
  });
} 
export const setNewTechMaturityList = (maturityList) => async (dispatch) => {
  await dispatch({
    type: SET_NEW_TECH_MATURITY_LIST_DATA,
    payload: maturityList,
  });
}

export const setNewDapMaturityList = (maturityList) => async (dispatch) => {
  await dispatch({
    type: SET_NEW_DAP_MATURITY_LIST_DATA,
    payload: maturityList,
  });
}

export const setNewEcoMaturityList = (maturityList) => async (dispatch) => {
  await dispatch({
    type: SET_NEW_ECO_MATURITY_LIST_DATA,
    payload: maturityList,
  });
}

export const setNewCapabilityList = (capabilityList) => async (dispatch) => {
  await dispatch ({
    type: SET_NEW_CAPABILITY_LIST_DATA,
    payload: capabilityList
  })
}

export const setBookmarkList = (bookmarkList) => async (dispatch) => {
  await dispatch ({
    type: SET_BOOKMARK_LIST_DATA,
    payload: bookmarkList
  })
}

export const setSubsectorList = (subsectorList) => async (dispatch) => {
  await dispatch ({
    type: SET_SUBSECTOR_LIST_DATA,
    payload: subsectorList
  })
}

export const setIsBookmarkCreated = (flag) => async (dispatch) => {
  await dispatch ({
    type: SET_IS_BOOKMARK_CREATED,
    payload: flag
  })
}
