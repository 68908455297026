import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserGraphData } from "../store/actions/navigationAction";

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const dispatch = useDispatch();
    const { instance } = useMsal();
    const { result } = useMsalAuthentication(InteractionType.Redirect, { scopes: window.CONFIG.SSO.scopes });
    const [accessToken, setAccessToken] = useState(null);
    const currentAccount = instance.getActiveAccount(); 

    useEffect(() => {
        if (!accessToken) {
            loadAccessToken();
            console.log('accessToken : ', accessToken);
        }
    }, [accessToken]);

    const loadAccessToken = async () => {
        const currentAccount = instance.getActiveAccount();
        const silentTokenRequest = { scopes: window.CONFIG.SSO.scopes, account: currentAccount, forceRefresh: false };
        const redirectTokenRequest = { scopes: window.CONFIG.SSO.scopes, loginHint: currentAccount.username };
        const tokenResponse = await instance.acquireTokenSilent(silentTokenRequest).catch(error => {
            if (error instanceof InteractionRequiredAuthError) {
                return instance.acquireTokenRedirect(redirectTokenRequest);
            }
        });
        
        setAccessToken(tokenResponse.accessToken);

        const headers = new Headers();
        headers.append("Authorization", "Bearer " + tokenResponse.accessToken);
        const options = { method: "GET", headers: headers };
        let userDetailApiUrl = window.CONFIG.WEBAPI.base_url + "/api/user/detail/" + currentAccount.username;

        let webApiResponse = await fetch(userDetailApiUrl, options);
       
        let graphUserData =await webApiResponse.json();
        if(graphUserData && Object.keys(graphUserData).length > 0){
            let userData={
                picture:graphUserData.picture,
                email:graphUserData.email,
                fullName:graphUserData.fullName,
                isAdmin:graphUserData.isAdmin,
                accessToken:tokenResponse.accessToken
            }

            dispatch(setUserGraphData(userData))
        }
    }

    if (result) {
        return <><Component {...rest} accessToken={accessToken} /></>;
    }
    else {
        return <span>Redirecting to Deloitte SSO...</span>;
    }
};