import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "reactstrap";
import ErrorBoundary from "../../components/error-boundary/ErrorBoundary";
import "./Capabilities.scss";
import {
  capabilities_details,
} from "../../constants/commonConstants";
import CapabilityDetails from "./CapabilityDetails";
import { useNavigate, useParams } from "react-router-dom";
import { removeHypensWithSpace, sortByKey } from "../../utils";
import { CapabilitiesQuery } from "../../services/graphql-queries";
import { POST_GRAPHQL } from "../../services/httpService";
import Loader from "../../components/loader";
import { GET_CAMPAIGN_BY_INDUSTRY } from "../../services/graphql-queries/techEnablers";
import { useSelector, useDispatch } from "react-redux";
import {  pageView } from "../../utils/omniture";
import { pageNames } from "../../constants/omnitureConstants";
import CapabilityInfoHeader from "../../components/CapabilityInfoHeader/CapabilityInfoHeader";
import AboutCapabilityCard from "../../components/AboutCapabilityCard/AboutCapabilityCard.component";
import CapabilityDetailsTabs from "../../components/CapacibiltyDetailsTabs/CapabilityDetailsTabs.Component";
import { replaceBreakWithNextline } from './../../utils/utilities';
import BriefCapabilityInfo from "../../components/brief-capability-info/BriefCapabilityInfo.Component";
import DeloitteAssetCard from "../../components/DeloitteAssetCard/DeloitteAssetCard.component";
import { setNewCapabilityList } from "../../store/actions/storefrontAction";
import CapabilityDrawer from "../../components/CapabilityDrawer/CapabilityDrawer.component";
import { removeSpaceWithHypens } from "../../utils";
import CoverPageTemplate from "../../components/exportDigiprintTemplates/CoverPageTemplate.Component";
import html2canvas from "html2canvas";

function Capabilities(props) {
  const [capabilitiesHeader, setCapabilitiesHeader] =
    useState(capabilities_details);
  const dispatch = useDispatch();
  const [capabilityDetail, setCapabilityDetail] = useState({});
  const [ecoPartnerSolutions, setEcoPartnerSolutions] = useState([]); 
  const [deloitteAssets, setDeloitteAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [coverTemplateImage, setCoverTemplateImage]=useState();
  const [capabilityCollaterals, setCapabilityCollaterals] = useState([])
  const [capabilityExternalAdditionalLinks, setCapabilityExternalAdditionalLinks] = useState([])
  const [capabilityInternalAdditionalLinks, setCapabilityInternalAdditionalLinks] = useState([])

  const navigate = useNavigate();
  const { valueChain, capability } = useParams();
  const [campaignMasterData, setCampaignMasterData] = useState([]);
  const tabs = ["Capability Info", `Deloitte Assets (${deloitteAssets.length})`]; //, "Sub-Capability", "Third-Party Solutions" to be added later
  const [activeTab, setActiveTab] = useState("Capability Info");
  const capabilitySuggestions = []
  const [businessValuesArr, setBusinessValuesArr] = useState([])
  const [suggestedCapabilities, setSuggestedCapabilities] = useState({})
  let selectedIndex = -1;
  let selectedCapabilityIndex = -1;
  const navigateTo = (subsectorName) => {
    let modifiedValuchainPath = removeSpaceWithHypens(capabilityDetail.valuechain.subsector.subsectorName);
    navigate(`/storefront/${modifiedValuchainPath}`);
  };
  const navigateToStoreFront = (subsectorName) => {
    navigateTo(subsectorName)
  }

  const navigateToTemplatePage = () => {
    navigate('/digiprint-preview');
  }

  const navReducer = useSelector((state) => ({
    email: state.navigationReducer.userEmail,
  }));
  const totalCapabilities = 10;
  const totalSelected = () => {
    let totalCount = 0;
    selectedCapabilityList.forEach(valueChain => {
      valueChain.capabilityList.forEach(() => totalCount++)
    }
    );
    return totalCount;
  }
  const userDataReducers = useSelector((state) => state.navigationReducer);
  const  sfReducer = useSelector((state) => ({
    subsectorSpecificCapabilities: state.storefrontReducer.subsectorSpecificCapabilities,
    valuechainSpecificCapabilities: state.storefrontReducer.valueChainSpecificCapabilities,
    capabilityDescriptions: state.storefrontReducer.subsectorSpecificCapabilitiesDescriptions,
    benefitsOrValue: state.storefrontReducer.subsectorSpecificCapabilitiesBenefits,
    subsectorList: state.storefrontReducer.subsectorList,
    currentSubsector: state.storefrontReducer.currentSubSector,
    item: state.storefrontReducer
  }))
  
  useEffect(()=>{
    setCoverTemplateImage(pdfCanvas('capability-template-01'));
  },[sfReducer?.subsectorList])


  let selectedCapabilityList = sfReducer.item.capabilityList;
  const checkIfSelected = (capabilityDetail) => {
    if (selectedCapabilityList) {
      for (let i = 0; i < selectedCapabilityList.length; i++) {
        if (selectedCapabilityList[i]?.valueChainName === capabilityDetail?.valuechain?.valueChainName && selectedCapabilityList[i]?.capabilityList.map(capability=>capability.capabilityName).includes(capabilityDetail.capabilityName)) {
          selectedIndex = i;
          selectedCapabilityIndex = selectedCapabilityList[i]?.capabilityList.map(capability=>capability.capabilityName).indexOf(capabilityDetail.capabilityName);
          return true;
        } else if (selectedCapabilityList[i]?.valueChainName === capabilityDetail?.valuechain?.valueChainName) {
          selectedIndex = i;
        } else {
          selectedIndex = -1;
        }
      }
      return false;
    }
  }
  const [capabilitySelected, setCapabilitySelected] = useState()

  useEffect(() => {
    if (navReducer.email !== "") {
      pageView(pageNames.CAPABILITIES_PAGE, navReducer.email);
    }
  }, [navReducer.email]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.accessToken != undefined) {
      if (capability) {
        Get_Data();
      }
    }
  }, [props.accessToken,valueChain, capability]);

  const findFileType = (filename) => {
    const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length);
    return extension;
  }

  const Get_Data = async () => {
    setLoading(true);
    try {
      let modifiedCapability = removeHypensWithSpace(capability);
      let modifiedValueChain = removeHypensWithSpace(valueChain);
      let responseData = await POST_GRAPHQL(
        CapabilitiesQuery.GET_CAPABILITY_BY_NAME,
        { capabilityName: modifiedCapability, valueChainName: modifiedValueChain },
        { accessToken: props.accessToken },
        `GET_CAPABILITY_BY_NAME_${valueChain}_${capability}`
      );

      if (responseData && responseData.data && responseData.data.data) {
        let capabilityList = responseData.data.data.capabilityList.items;
        if (capabilityList && capabilityList.length > 0) {
          let capabilityDetail = capabilityList[0];
          setCapabilitySelected(checkIfSelected(capabilityDetail));
          GET_CAMPAIGN_DATA(capabilityDetail);
          setCapabilityDetail(capabilityDetail);
          setEcoPartnerSolutions(capabilityDetail.ecoPartnerSolutions);
          setDeloitteAssets(capabilityDetail.deloitteAssets);

          let arr = []
          if(capabilityDetail.externalOnePager.html !== null) {
            arr.push({
              title: "One Pager (External)",
              url: capabilityDetail.externalOnePager.html,
              fileType: findFileType(capabilityDetail.externalOnePager.plaintext)
            })
          }

          if(capabilityDetail.internalTearSheets.html !== null) {
            arr.push({
              title: "Tear Sheets (Internal)",
              url: capabilityDetail.internalTearSheets.html,
              fileType: findFileType(capabilityDetail.internalTearSheets.plaintext)
            })
          }

          if(capabilityDetail.externalSalesDeck.html !== null) {
            arr.push({
              title: "Sales Deck (External)",
              url: capabilityDetail.externalSalesDeck.html,
              fileType: findFileType(capabilityDetail.externalSalesDeck.plaintext)
            })
          }

          if(capabilityDetail.internalOverviewDeck.html !== null) {
            arr.push({
              title: "Overview Deck (Internal)",
              url: capabilityDetail.internalOverviewDeck.html,
              fileType: findFileType(capabilityDetail.internalOverviewDeck.plaintext)
            })
          }
          
          if(capabilityDetail.externalDemoVideo.html !== null) {
            arr.push({
              title: "Demo Video (External)",
              url: capabilityDetail.externalDemoVideo.html,
              fileType: 'video'
            })
          }


          if(capabilityDetail.internalDemoVideo.html !== null) {
            arr.push({
              title: "Demo Video (Internal)",
              url: capabilityDetail.internalDemoVideo.html,
              fileType: 'video'
            })
          }
          //Set Collaterals for Capability
          setCapabilityCollaterals(arr)

          let arr2 = []
          if(capabilityDetail.additionalLinksExternal.html !== null) {
            arr2.push({
              title: capabilityDetail.additionalLinksExternal.plaintext,
              url: capabilityDetail.additionalLinksExternal.html,
            })
          }
          //Set External additional links for capability
          setCapabilityExternalAdditionalLinks(arr2)

          let arr3 = []
          if(capabilityDetail.additionalLinksInternal.html !== null) {
            arr3.push({
              title: capabilityDetail.additionalLinksInternal.plaintext,
              url: capabilityDetail.additionalLinksInternal.html,
            })
          }
          //set Internal additional links for capability
          setCapabilityInternalAdditionalLinks(arr3)

          const businessValuesArrTemp = []
          capabilityDetail?.businessValue.map((item) => {
            return businessValuesArrTemp.push(item.name)
          })
          setBusinessValuesArr(businessValuesArrTemp)

          const currentCapabilityName = capabilityDetail.capabilityName

          //checking if capability with exact same business values is present in value chain
          let capabilitySuggestions = checkSubset(sfReducer.valuechainSpecificCapabilities, businessValuesArrTemp.sort());
          const keys = Object.keys(sfReducer.valuechainSpecificCapabilities)
          const tempObj = {...sfReducer.subsectorSpecificCapabilities}
          keys.map((item) => delete tempObj[item])

          if(capabilitySuggestions[capabilityDetail.capabilityName])
            delete capabilitySuggestions[capabilityDetail.capabilityName]

          //checking if (capability - n) with exact same business values is present in value chain
          if(Object.keys(capabilitySuggestions).length < 4 && businessValuesArrTemp.length > 1) {
            let arr = [...businessValuesArrTemp]
            for(let i = businessValuesArrTemp.length; i >=0 ; i--) {
              arr.sort().reverse().pop()
              if(Object.keys(checkSubset(sfReducer.valuechainSpecificCapabilities, arr)).length > 0)
                capabilitySuggestions = {...capabilitySuggestions, ...checkSubset(sfReducer.valuechainSpecificCapabilities, arr)};
            }
            setSuggestedCapabilities(capabilitySuggestions)
          }

          if(Object.keys(capabilitySuggestions).length < 3) {
            //checking if capability with exact same business values is present in sub sector
            if(Object.keys(checkSubset(tempObj, businessValuesArrTemp)).length > 0)
              capabilitySuggestions = {...capabilitySuggestions, ...checkSubset(tempObj, businessValuesArrTemp.sort())};
            setSuggestedCapabilities(capabilitySuggestions)
          }

        
          //checking if (capability - n) with exact same business values is present in subsector
          if(Object.keys(capabilitySuggestions).length < 3 && businessValuesArrTemp.length > 1) {
            let arr = [...businessValuesArrTemp]
            for(let i = businessValuesArrTemp.length; i >=0 ; i--) {
              arr.sort().reverse().pop()
              if(Object.keys(checkSubset(tempObj, arr)).length > 0)
                capabilitySuggestions = {...capabilitySuggestions, ...checkSubset(tempObj, arr)};
            }

            setSuggestedCapabilities(capabilitySuggestions)
          }

          const finalCapabilitySuggestions = {}

          if(Object.keys(capabilitySuggestions).length > 6) {
            for(let i = 0; i < 6; i++) {
              let keyToBeAdded = Object.keys(capabilitySuggestions)[i]
              finalCapabilitySuggestions[keyToBeAdded] = capabilitySuggestions[keyToBeAdded]
            }
            const sortedKeys = Object.keys(finalCapabilitySuggestions).sort()
            const obj = {}
            const temp = sortedKeys.map((item) => {
              obj[item] = finalCapabilitySuggestions[item]
              return false
            })
            setSuggestedCapabilities(obj)
          }


        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("err ", err);
    }
  };

  //utility function to check for subset in array
  const checkSubset = (parentObj, subsetArray) => {
    const keys = Object.keys(parentObj)
    const capabilitySuggestions = {}
    keys.map((item) => {
      if(JSON.stringify(parentObj[item].sort()) === JSON.stringify(subsetArray.sort())){
        capabilitySuggestions[item] = parentObj[item]
      }
      return false
    })
    return capabilitySuggestions;
  }



  const removeCapability = (index, valueChainIndex, selectedCapabilities) => {
    if (selectedCapabilities[valueChainIndex].capabilityList.length <= 1) {
      selectedCapabilities.splice(valueChainIndex, 1);
    } else {
      selectedCapabilities[valueChainIndex].capabilityList.splice(index, 1);
    }
    dispatch(setNewCapabilityList(selectedCapabilities));
    setCapabilitySelected(checkIfSelected(capabilityDetail))
  }

  const addCapability = () => {
    const totalSelectedCount = totalSelected();
    checkIfSelected(capabilityDetail);
    // Condition that takes care of the addition logic
    if (totalSelectedCount < 10) {
      if (selectedIndex > -1) {
        selectedCapabilityList[selectedIndex].capabilityList.push(capabilityDetail)
      } else {
        selectedCapabilityList.push(
          {
            valueChainName: capabilityDetail.valuechain.valueChainName,
            capabilityList: [capabilityDetail]
          }
        )
      }
      selectedIndex = -1;
      dispatch(setNewCapabilityList(selectedCapabilityList));
      setCapabilitySelected(checkIfSelected(capabilityDetail))
    }
  }

  const removeCapabilityFromList = () => {
    checkIfSelected(capabilityDetail);
    if (selectedCapabilityList) {
      if (selectedCapabilityList[selectedIndex]?.capabilityList.length === 1) {
        selectedCapabilityList.splice(selectedIndex, 1);
      } else {
        selectedCapabilityList[selectedIndex]?.capabilityList.splice(selectedCapabilityIndex, 1);
      }
      selectedCapabilityIndex = -1;
      selectedIndex = -1;
      dispatch(setNewCapabilityList(selectedCapabilityList));
      setCapabilitySelected(checkIfSelected(capabilityDetail))
    }
  }

  const GET_CAMPAIGN_DATA = async (selectedCapability) => {
    try {
      if (
        selectedCapability &&
        selectedCapability.valuechain &&
        selectedCapability.valuechain.subsector &&
        selectedCapability.valuechain.subsector.sector.industry &&
        selectedCapability.valuechain.subsector.sector.industry.industryName
      ) {
        let industryName =
          selectedCapability.valuechain.subsector.sector.industry.industryName;

        let responseData = await POST_GRAPHQL(
          GET_CAMPAIGN_BY_INDUSTRY,
          { industryName },
          { accessToken: props.accessToken },
          `GET_CAMPAIGN_BY_INDUSTRY_${industryName}`
        );
        if (responseData && responseData.data && responseData.data.data) {
          let deloitteCampaignsList =
            responseData.data.data.deloitteCampaignsList.items;

          let displayOrderedCampaignsList = sortByKey(
            deloitteCampaignsList,
            "displayOrder"
          );
          setCampaignMasterData(displayOrderedCampaignsList);
        }
      }
    } catch (err) {
      console.log("err ", err);
    }
  };

  const buildDigiprint = () => {

  }
  
  const navigateToCapabilityPage = (valueChainName, capabilityName) => {
    console.log('go to ', valueChainName, capabilityName)
    let modifiedValuchainPath = removeSpaceWithHypens(valueChainName);
    let modifiedCapsPath = removeSpaceWithHypens(capabilityName);
    navigate(`/capabilities/${modifiedValuchainPath}/${modifiedCapsPath}`);
  }

  const pdfCanvas = async (id) => {
    console.log('id ', id)
    const input = document.getElementById(id);
    console.log('input ', input)
    input.style.width = 1000 + "px";
    input.style.height = parseInt(500)  + "px";
    let val = await html2canvas(input,{useCORS:true}).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      return imgData;
    });
    return val;
  };

  return (
    <ErrorBoundary>
      {loading && <Loader />}
      {!loading && capabilityDetail && (
        <>
          <div className="capabilities-details-back">
            <Row className="capabilities-header">
              <Col lg="12">
                <CapabilityInfoHeader props={{ capabilityDetail: capabilityDetail, navigateToStoreFront: navigateToStoreFront, selectedCapabilityList: selectedCapabilityList, checkIfSelected: capabilitySelected, addCapability: addCapability, removeCapabilityFromList: removeCapabilityFromList }} accessToken={props.accessToken}></CapabilityInfoHeader>
              </Col>
            </Row>
            <Row ><CapabilityDetailsTabs props = {{tabs:tabs, activeTab:activeTab, setActiveTab:setActiveTab}}></CapabilityDetailsTabs></Row>
            {
              activeTab === "Capability Info" ?
                <Row style={{ marginTop: '40px' ,marginLeft: '20px'}}>
                  <Col lg="8">
                    <CapabilityDetails
                      
                      props={capabilityDetail}
                      accessToken={props.accessToken}
                      campaignMaster={campaignMasterData}
                      email={navReducer.email}
                      collaterals={capabilityCollaterals}
                      additionalLinksExternal={capabilityExternalAdditionalLinks}
                      additionalLinksInternal={capabilityInternalAdditionalLinks}
                      

                    />
                  </Col>
                  <div style={{display:'inline-block', width:'35px'}}></div>
                  <Col lg="3"><AboutCapabilityCard capability={capabilityDetail}></AboutCapabilityCard></Col>
                </Row>
                :
                <DeloitteAssetCard deloitteAssets={capabilityDetail.deloitteAssets} userDataReducers={userDataReducers} salesCollateral={capabilityDetail.salesCollateral}
                />
            }
            {Object.keys(suggestedCapabilities).length > 0 && <Row style={{padding: '0 5.6rem 2rem 3.3rem'}}>
              <h3 style={{fontFamily: 'Open Sans', fontSize: '24px', fontWeight: 'bold', padding: '2rem 0rem 2rem 0.7rem'}}>
                More Capabilities from the {capabilityDetail?.valuechain?.subsector?.subsectorName} Storefront
              </h3>
              <div style={Object.keys(suggestedCapabilities).length > 3 ? 
                        {display: 'grid', 
                        gridTemplateColumns: `repeat(${Object.keys(suggestedCapabilities).length}, 33%)`, 
                        gridTemplateRows: 'auto', 
                        gridAutoFlow: 'column', 
                        columnGap: '30px',
                        gridAutoColumns: `repeat(${Object.keys(suggestedCapabilities).length}, 33%)`, 
                        overflowX: 'auto', 
                        paddingBottom: 10}
                      :
                        {display: 'grid', 
                        gridTemplateColumns: `repeat(${Object.keys(suggestedCapabilities).length}, 33%)`, 
                        gridTemplateRows: 'auto', 
                        gridAutoFlow: 'column', 
                        columnGap: '30px',
                        gridAutoColumns: `repeat(${Object.keys(suggestedCapabilities).length}, 33%)`, 
                        paddingBottom: 10}
                        }>
                {Object.keys(suggestedCapabilities).length > 0 && Object.keys(suggestedCapabilities).map((item) =>
                  <div className="briefCapabilityInfoStyle" onClick={() => navigateToCapabilityPage(sfReducer.capabilityDescriptions[item][1], item)} >
                    <BriefCapabilityInfo displayValueChainName={sfReducer.capabilityDescriptions[item][1]} capabilityPage='true' capabilityType={item} Benefits={sfReducer.benefitsOrValue[item]?.length > 0 ? replaceBreakWithNextline(sfReducer.benefitsOrValue[item]) : 'N/A'} description={sfReducer.capabilityDescriptions[item][0]}/> 
                  </div>)}
              </div>
            </Row>}
          </div> 
          <CoverPageTemplate subsectorData = {sfReducer?.subsectorList?.filter(subsector=>subsector?.subsectorName=== removeHypensWithSpace(sfReducer?.currentSubsector))[0]}/>
          <div><CapabilityDrawer coverTemplateImage={coverTemplateImage} buildDigiprint = {buildDigiprint} selectedCapabilities={selectedCapabilityList} totalCapabilities={totalCapabilities} totalSelected={totalSelected()}
          navigateToTemplatePage={navigateToTemplatePage}  removeCapability={removeCapability} sfReducer={sfReducer} /></div>
        </>
      )}
    </ErrorBoundary>
  );
}

export default Capabilities;
