export const GET_SUB_SECTORS_LIST = `
{
  subsectorList{
    items{
      sector{
        _path
        sectorName
      }
      subsectorName
      subsectorDescription{
          html
        }
      _path
      
    }
  }
}
`


export const GET_SUB_SECTORS_BY_SECTOR_PATH = `query GetSubSectors($path:ID){
    subsectorList(filter:{
      sector:{
        _path:{
          _expressions:{
            value:$path
          }
        }
      }
    }){
      items{
        sector{
          _path
          sectorName
        }
        subsectorName
        subsectorDescription{
            html
          }
        _path
        
      }
    }
  }
`

export const GET_SUB_SECTOR_BY_NAME = `query getSubSectorByName($subSectorName:String!){
  subsectorList(filter:{
    subsectorName:{
      _expressions:{
        value:$subSectorName
      }
    }
  }){
    items {
      sector {
        _path
        sectorName
        industry {
          industryName
          _path
        }
      }
      subsectorName
      subsectorDescription {
        html
      }
      _path
      subsectorImage {
        ... on ImageRef {
          _authorUrl
          _publishUrl
        }
      }
      exportImage{
        ... on ImageRef {
          _authorUrl
          _publishUrl
        }
      }
      referenceLinkUrl
      referenceLinkTitle
    }
  }
  
}
`

export const GET_SUB_SECTOR_BY_NAME_WITH_VALUCHAIN = `
query getSubSectorByName($subSectorName: String!) {
  subsectorList(filter: {subsectorName: {_expressions: {value: $subSectorName}}}) {
    items {
      sector {
        _path
        sectorName
        industry {
          industryName
          _path
        }
      }
      subsectorName
      subsectorDescription {
        html
      }
      _path
    }
  }
  valuechainList(
    filter: {subsector: {subsectorName: {_expressions: {value: $subSectorName}}}, isDeleted: {_expressions: {value: false}}}
  ) {
    items {
      _path
      valueChainDescription {
        html
      }
      valueChainName
      isActive
      isDeleted
      displayOrder
      subsector {
        subsectorName
        sector {
          sectorName
          industry {
            industryName
          }
        }
      }
    }
  }

  capabilityList
  (filter:{
    subSector: {subsectorName: {_expressions: {value: $subSectorName}}}
    isDeleted: {_expressions: {value: false}}
  })
  {
    items {
      _path
      capabilityName
      benefitOrValue {
        html
        plaintext
      }
      capabilityDescription{
        html
        plaintext
      }
      capabilityShortDescription {
        html
      }
      capabilityType {
        capabilityTypeName
        isActive
      }
      isActive
      isDeleted
      displayOrder
      valuechain {
        _path
        valueChainName
      }
      subSector{
        subsectorName
      }
      technologyEnablement {
        name
        description {
          html
        }
        isActive
        isDeleted
        displayOrder
      }
      deloitteAssets {
        assetName

        assetDescription {
          html
        }
        assetLinks {
          html
          plaintext
        }

              
      }


      campaign {
        name
        description {
          html
        }
        isActive
        isDeleted
        displayOrder
      }

      maturityLevel {
        name
        description {
          html
        }
        isActive
        isDeleted
        displayOrder
      }  
      ecoPartnerSolutions {
        ecoSystem {
          name
          description {
            html
          }
          displayOrder
          isActive
          isDeleted
        }
        title
        description {
          html
        }
        referenceLinkTitle
        referenceLink
        isActive
        isDeleted
        displayOrder
      }
      businessValue {
        name
        description {
          html
        }
        isActive
        isDeleted
        displayOrder
      }
    }
  }

  
}

`

export const GET_CAPS_BY_VALUE_CHAIN = `
query getCapsByValueChainName($valueChainName: String!) {
  capabilityList
   (filter:{
     valuechain:{  
       valueChainName:{
         _expressions:{
           value:$valueChainName
         }
       }
       isDeleted: {_expressions: {value: false}}
     }
     isDeleted: {_expressions: {value: false}}
   })
   {
     items {
       _path
       capabilityName
       capabilityShortDescription {
         html
       }
       capabilityType {
         capabilityTypeName
         isActive
       }
       isActive
       isDeleted
       displayOrder
       valuechain {
         _path
         valueChainName
       }
       technologyEnablement {
         name
         description {
           html
         }
         isActive
         isDeleted
         displayOrder
       }
       deloitteAssets {
         assetName
 
         assetDescription {
           html
         }
         assetLinks {
           html
           plaintext
         }
 
               
       }
 
 
       campaign {
         name
         description {
           html
         }
         isActive
         isDeleted
         displayOrder
       }
 
       maturityLevel {
         name
         description {
           html
         }
         isActive
         isDeleted
         displayOrder
       }  
       ecoPartnerSolutions {
         ecoSystem {
           name
           description {
             html
           }
           displayOrder
           isActive
           isDeleted
         }
         title
         description {
           html
         }
         referenceLinkTitle
         referenceLink
         isActive
         isDeleted
         displayOrder
       }
       businessValue {
         name
         description {
           html
         }
         isActive
         isDeleted
         displayOrder
       }
     }
   }
   
 }

`

export default {
  GET_SUB_SECTORS_LIST,
  GET_SUB_SECTORS_BY_SECTOR_PATH,
  GET_SUB_SECTOR_BY_NAME,
  GET_SUB_SECTOR_BY_NAME_WITH_VALUCHAIN,
  GET_CAPS_BY_VALUE_CHAIN
}