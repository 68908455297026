import React from 'react'
import styles from './SiteUnderConstructionAlert.module.scss';

function SiteUnderConstructionAlert() {
  return (
    <div className={styles.mainDiv}>
        <b>Website Under Construction</b>
    </div>
  )
}

export default SiteUnderConstructionAlert