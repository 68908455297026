export const ConvertAssetsToJsonData = (assetsData) => {
    let response = [];
    let pagination = {};

    if (assetsData && assetsData.entities) {
        let dataTmp = assetsData.entities;

        dataTmp.forEach(element => {
            let objValue = {
                cfm_name: element.properties.name
            }

            let elementsTemp = element.properties.elements;

            for (let [key, value] of Object.entries(elementsTemp)) {
                objValue[key] = value.value ? value.value : "";
            }


            response.push(objValue);
        });
    }

    if (assetsData.properties && assetsData.properties["srn:paging"]) {
        pagination = assetsData.properties["srn:paging"]
    }

    return {
        data: response,
        pagination,
    }
}

export const sortByKey = (arrayValues, key) => {
    return arrayValues.sort((a, b) => a[key] < b[key] ? -1 : 1)
}


export const AssetsDataFilterByKey = (dataArray, key, searchKey) => {

    return dataArray.filter((item) => item[key].includes(searchKey))

}

export function groupBy(data, property) {
    return data.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
}


export function getUniqueListBy(arr, key) {
    if (arr.length === 0) {
        return []
    }
    return [...new Map(arr.map(item => [item[key], item])).values()]
}