import { Group, GroupImage, user } from "./images";
export const navigationLinks = {
  Home: "Home",
  Storefronts: "Storefronts",
  Admin: "Admin",
  Contacts: "Contacts",
};

export const storefrontScreenTabs = {
  CBA: "Core Business Areas",
  SV: "Storefront view",
};

export const capabilityDrawer = {
  maxLimit : 10
}

export const storefrontViewScreenTabs = [
  { groupName: "Technology Enablement" },
  { groupName: "Business Value" },
  { groupName: "Deloitte’s Asset Portfolio" },
  { groupName: "Deloitte Campaigns" },
  { groupName: "Ecosystem Acceleration" },
  { groupName: "Industry Evolution" },
];

export const storefrontTabs = [
  {
    title: "Technology Enablement",
    isActive: true,
  },
  {
    title: "Business Value",
    isActive: true,
  },
  {
    title: "Deloitte’s Asset Portfolio",
    isActive: true,
  },
  {
    title: "Deloitte Campaigns",
    isActive: true,
  },
  {
    title: "Ecosystem Acceleration",
    isActive: true,
  },
  {
    title: "Industry Evolution (Future)",
    isActive: false,
  },
  {
    title: "Enterprise Consciousness (Future)",
    isActive: false,
  },
];

export const contactTabs = [
  "DigiPrint Owners",
  "Life Sciences & Health Care",
  "Technology, Media & Telecommunic...",
  "Financial Services",
  "Consumer",
  "Energy, Resources & Industrials",
  "Government & Public Services",
];

export const industries = [
  {
    _path: "/content/dam/projects/digiprint/data/industry/consumer",
    industryName: "Life Sciences & Health Care",
    referenceUrl: null,
    industryImage: GroupImage,
    industryIcon: {
      _authorUrl: GroupImage,
      _publishUrl:
        "http://localhost:4503/content/dam/projects/digiprint/icons/industryicons/consumer.png",
    },
    isActive: true,
  },
  {
    _path: "/content/dam/projects/digiprint/data/industry/consumer",
    industryName: "Technology, Media & Telecommunications",
    referenceUrl: null,
    industryImage: GroupImage,
    industryIcon: {
      _authorUrl: GroupImage,
      _publishUrl:
        "http://localhost:4503/content/dam/projects/digiprint/icons/industryicons/consumer.png",
    },
    isActive: false,
  },
  {
    _path: "/content/dam/projects/digiprint/data/industry/consumer",
    industryName: "Financial Services",
    referenceUrl: null,
    industryImage: GroupImage,
    industryIcon: {
      _authorUrl: GroupImage,
      _publishUrl:
        "http://localhost:4503/content/dam/projects/digiprint/icons/industryicons/consumer.png",
    },
    isActive: false,
  },
];

export const sectors = {
  data: {
    industryList: {
      items: [
        {
          _path:
            "/content/dam/digiprint/data/industry/life-sciences-and-health-care",
          industryName: "Life Sciences & Health Care",
          referenceLinkUrl: "https://www.deloitte.com/about",
          referenceLinkTitle: "Learn More",
          industryImage: {
            _authorUrl:
              "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/images/industryimages/banner.png",
            _publishUrl:
              "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/images/industryimages/banner.png",
          },
          industryIcon: {
            _authorUrl:
              "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/industryicons/life_science_health.png",
            _publishUrl:
              "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/industryicons/life_science_health.png",
          },
          industryDescription: {
            html: "<p>A sector-specific DigiPrint is a portfolio of cloud-enabled business capabilities and use cases activated through advanced analytics, AI/ML, intelligent automation, IoT, blockchain, and 5G to accelerate business transformation and innovation.</p>\n<p>A sector-specific DigiPrint is a portfolio of cloud-enabled business capabilities and use cases activated through advanced analytics, AI/ML, intelligent automation.</p>\n",
          },
          isActive: true,
        },
      ],
    },
    sectorList: {
      items: [
        {
          _path: "/content/dam/digiprint/data/sector/health-care",
          sectorName: "Health Care",
          sectorDescription: {
            html: null,
          },
          industry: {
            industryName: "Life Sciences & Health Care",
            _path:
              "/content/dam/digiprint/data/industry/life-sciences-and-health-care",
          },
        },
      ],
    },
    subsectorList: {
      items: [
        {
          sector: {
            _path: "/content/dam/digiprint/data/sector/health-care",
            sectorName: "Health Care",
            industry: {
              industryName: "Life Sciences & Health Care",
            },
          },
          subsectorName: "Health Plans (ACP)",
          subsectorDescription: {
            html: "<p>A sector-specific DigiPrint is a portfolio of cloud-enabled business capabilities and use cases activated through advanced...</p>\n",
          },
          _path: "/content/dam/digiprint/data/subsector/healthcare",
        },
      ],
    },
  },
};
export const faq = [
  {
    index: 0,
    faqQuestion: {
      html: "What is DigiPrint?",
    },
    faqAnswer: {
      html: `A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.
      A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.`,
    },
    page: {
      pageId: "home-page",
      pageName: "Home Page",
    },
    isActive: true,
    displayOrder: 1,
  },
];

export const subSectors = [
  {
    id: 1,
    subsectorName: "Sub-Sector 1",
    subsectorDescription: {
      html: "A sector-specific DigiPrint is a portfolio of cloud-enabled business capabilities and use cases activated through advanced...",
    },
    SelectSector: "Select Sub-Sector",
  },
  {
    id: 2,
    subsectorName: "Sub-Sector 2",
    subsectorDescription: {
      html: "A sector-specific DigiPrint is a portfolio of cloud-enabled business capabilities and use cases activated through advanced...",
    },
    SelectSector: "Select Sub-Sector",
  },
  {
    id: 3,
    subsectorName: "Sub-Sector 3",
    subsectorDescription: {
      html: "A sector-specific DigiPrint is a portfolio of cloud-enabled business capabilities and use cases activated through advanced...",
    },
    SelectSector: "Select Sub-Sector",
  },
  {
    id: 4,
    subsectorName: "Healthcare Provider",
    subsectorDescription: {
      html: "A sector-specific DigiPrint is a portfolio of cloud-enabled business capabilities and use cases activated through advanced...",
    },
    SelectSector: "Select Sub-Sector",
  },
];

export const sectorBanner = {
  title: "Life Sciences & Health Care",
  description: {
    html: `A sector-specific DigiPrint is a portfolio of cloud-enabled business
      capabilities and use cases activated through advanced analytics,
      AI/ML, intelligent automation, IoT, blockchain, and 5G to accelerate
      business transformation and innovation.`,
  },

  image: "",
  logo: Group,
};

export const contacts = {
  data: {
    contactgroupList: {
      items: [
        {
          contactGroupName: "Consumer",
          contactGroupDescription: {
            html: null,
          },
          contacts: [
            {
              contactEmail: "nmerizzi@deloitte.com",
              contactTitle: "Principal",
              contactTelephoneNumber: "(603) 555-0123",
              associatedFirm: "Deloitte Consulting LLP",
              displayOrder: 1,
              _path: "/content/dam/digiprint/data/contact/pakulkarni",
              isActive: true,
              isDeleted: false,
            },
          ],
          displayOrder: 5,
          isActive: true,
        },
        {
          contactGroupName: "Consumer-1",
          contactGroupDescription: {
            html: null,
          },
          contacts: [
            {
              contactEmail: "nmerizzi@deloitte.com",
              contactTitle: "Principal",
              contactTelephoneNumber: "(603) 555-0123",
              associatedFirm: "Deloitte Consulting LLP",
              displayOrder: 1,
              _path: "/content/dam/digiprint/data/contact/pakulkarni",
              isActive: true,
              isDeleted: false,
            },
          ],
          displayOrder: 5,
          isActive: true,
        },
      ],
    },
  },
};

export const blogs = {
  data: {
    blogList: {
      items: [].concat(
        ...new Array(4).fill([
          {
            blogTitle: "What is DigiPrint?",
            blogSubtitle: null,
            shortDescription: {
              html: "<p>A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.</p>\n",
            },
            description: {
              html: null,
            },
            blogImage: {
              _authorUrl:
                "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/images/blogimages/Maskgroup1.png",
              _publishUrl:
                "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/images/blogimages/Maskgroup1.png",
            },
            isActive: true,
            referenceUrl: "https://www.deloitte.com/about",
            referenceLinkTitle: "Learn more",
          },
        ])
      ),
    },
  },
};

export const industries_sector_list = [
  {
    _path:
      "/content/dam/projects/digiprint/data/industry/life-sciences-and-health-care",
    industryName: "Life Sciences & Health Care",
    referenceLinkUrl: null,
    referenceLinkTitle: null,
    industryImage: null,
    industryIcon: {
      _authorUrl:
        "http://localhost:4502/content/dam/projects/digiprint/icons/industryicons/life_science_health.png",
      _publishUrl:
        "http://localhost:4503/content/dam/projects/digiprint/icons/industryicons/life_science_health.png",
    },
    isActive: true,
    isDeleted: false,
    displayOrder: 0,
    sectors: [].concat(
      ...new Array(6).fill([
        {
          sectorName: "Transportation, Hospitality & Services",

          subSectors: [].concat(
            ...new Array(3).fill([
              {
                subsectorName: "Restaurants & Food Service",
              },
            ])
          ),
        },
      ])
    ),
  },
  {
    _path:
      "/content/dam/projects/digiprint/data/industry/life-sciences-and-health-care",
    industryName: "Technology, Media & Telecommunications",
    referenceLinkUrl: null,
    referenceLinkTitle: null,
    industryImage: null,
    industryIcon: {
      _authorUrl:
        "http://localhost:4502/content/dam/projects/digiprint/icons/industryicons/life_science_health.png",
      _publishUrl:
        "http://localhost:4503/content/dam/projects/digiprint/icons/industryicons/life_science_health.png",
    },
    isActive: false,
    isDeleted: false,
    displayOrder: 0,
  },
  {
    _path:
      "/content/dam/projects/digiprint/data/industry/life-sciences-and-health-care",
    industryName: "Financial Services",
    referenceLinkUrl: null,
    referenceLinkTitle: null,
    industryImage: null,
    industryIcon: {
      _authorUrl:
        "http://localhost:4502/content/dam/projects/digiprint/icons/industryicons/life_science_health.png",
      _publishUrl:
        "http://localhost:4503/content/dam/projects/digiprint/icons/industryicons/life_science_health.png",
    },
    isActive: false,
    isDeleted: false,
    displayOrder: 0,
  },
];

export const homeBanner = {
  bannersList: {
    items: [
      {
        page: {
          _path: "/content/dam/digiprint/data/page/home-page",
          _variation: "master",
          pageId: "home-page",
          pageName: "Home Page",
          isActive: true,
        },
        title: {
          html: "<p>&lt;p&gt;&lt;span&gt;Digi&lt;/span&gt;&lt;b&gt;Print&lt;/b&gt;&lt;/p&gt;</p>\n",
          plaintext: "<p><span>Digi</span><b>Print</b></p>",
        },
        subtitle: {
          html: null,
          plaintext: null,
        },
        icon: null,
        image: {
          _authorUrl:
            "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/images/bannerimages/homepage_section.jpg",
          _publishUrl:
            "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/images/bannerimages/homepage_section.jpg",
        },
        description1: {
          html: "<p>&lt;p&gt;A sector-specific DigiPrint is a portfolio of cloud-enabled business capabilities and use cases activated through advanced analytics, AI/ML, Intelligent automation, IoT, blockchain, and 5G to accelerate business transformation and innovation.&lt;/p&gt;</p>\n",
          plaintext:
            "<p>A sector-specific DigiPrint is a portfolio of cloud-enabled business capabilities and use cases activated through advanced analytics, AI/ML, Intelligent automation, IoT, blockchain, and 5G to accelerate business transformation and innovation.</p>",
        },
        description2: {
          html: null,
          plaintext: null,
        },
        referenceLinkTitle: "Select Industry",
        referenceLinkUrl: null,
      },
    ],
  },
};

export const aboutStoreFrontTemp = {
  aboutstorefrontgroupList: {
    items: [
      {
        groupName: {
          html: "<p><b>Business Value</b></p>\n",
        },
        displayOrder: 2,
        isActive: true,
        isDeleted: false,
        iscenter: false,
        aboutStorefrontGroupDetails: {
          title: {
            html: "<p>Technology</p>\n",
          },
          subtitle: {
            html: null,
          },
          description1: {
            html: "<p><b>Subheading allows user to recieve condensed information on selected section as well as its graphical representation without extra searching (scrolling).</b></p>\n",
          },
          description2: {
            html: "<p>A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.</p>\n",
          },
          icon: null,
          image: {
            _authorUrl:
              "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/aboutstoregrontgroupdetailicons/StorefrontNew.png",
            _publishUrl:
              "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/aboutstoregrontgroupdetailicons/StorefrontNew.png",
          },
        },
      },
      {
        groupName: {
          html: "<p><b>Capability Storefront</b></p>\n",
        },
        displayOrder: 2,
        isActive: true,
        isDeleted: false,
        iscenter: true,
        aboutStorefrontGroupDetails: {
          title: {
            html: "<p>Technology</p>\n",
          },
          subtitle: {
            html: null,
          },
          description1: {
            html: "<p><b>Subheading allows user to recieve condensed information on selected section as well as its graphical representation without extra searching (scrolling).</b></p>\n",
          },
          description2: {
            html: "<p>A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.</p>\n",
          },
          icon: null,
          image: {
            _authorUrl:
              "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/aboutstoregrontgroupdetailicons/StorefrontNew.png",
            _publishUrl:
              "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/aboutstoregrontgroupdetailicons/StorefrontNew.png",
          },
        },
      },
    ],
  },
};

export const footer_data = {
  linksTitle: "Links",
  title: "Contact",
  emailaddress: "usdigiprint@deloitte.com",
  termsofuse: "Terms Of Use",
  privacy: "Privacy",
  cookienotice: "Cookie Notice",
  description: {
    html: "© 2023 For information, contact Deloitte Touche Tohmatsu Limited.",
  },
  description1: {
    html: "Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited (“DTTL”), its global network of member firms, and their related entities (collectively, the “Deloitte organization”). DTTL (also referred to as “Deloitte Global”) and each of its member firms and related entities are legally separate and independent entities, which cannot obligate or bind each other in respect of third parties. DTTL and each DTTL member firm and related entity is liable only for its own acts and omissions, and not those of each other. DTTL does not provide services to clients. Please see www.deloitte.com/about to learn more.",
  },

  image: "",
};

export const storefronts = {
  data: {
    subsectorList: {
      items: [
        {
          sector: {
            _path: "/content/dam/digiprint/data/sector/health-care",
            sectorName: "Health Care",
            industry: {
              industryName: "Life Sciences & Health Care",
              _path:
                "/content/dam/digiprint/data/industry/life-sciences-and-health-care",
            },
          },
          subsectorName: "Health Plans (ACP)",
          subsectorDescription: {
            html: "<p>A sector-specific DigiPrint is a portfolio of cloud-enabled business capabilities and use cases activated through advanced...</p>\n",
          },
          _path: "/content/dam/digiprint/data/subsector/healthcare",
          subsectorIcon: {
            _authorUrl:
              "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/subsectoricons/life_science_health.png",
            _publishUrl:
              "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/subsectoricons/life_science_health.png",
          },
          subsectorImage: {
            _authorUrl:
              "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/images/subsectorimages/subsector_banner_image.png",
            _publishUrl:
              "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/images/subsectorimages/subsector_banner_image.png",
          },
        },
      ],
    },
    valuechainList: {
      items: [
        {
          _path:
            "/content/dam/digiprint/data/valuechain/care-management-and-delivery",
          valueChainDescription: {
            html: "<p>Cloud-enabled interoperability drives holistic patient profile and longitudinal analyses to develop disease progression Al models for proactive &quot;n of 1&quot; care delivery</p>\n",
          },
          valueChainName: "Care Management And Delivery",
          valueChainIcon: {
            _path:
              "/content/dam/digiprint/icons/valuechainicons/valueChain.png",
            _authorUrl:
              "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/valuechainicons/valueChain.png",
            _publishUrl:
              "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/valuechainicons/valueChain.png",
          },
          valueChainImage: null,
          isActive: true,
          isDeleted: false,
          displayOrder: 2,
          subsector: {
            subsectorName: "Health Plans (ACP)",
            sector: {
              sectorName: "Health Care",
              industry: {
                industryName: "Life Sciences & Health Care",
              },
            },
          },
        },
      ],
    },
    capabilityList: {
      items: [
        {
          capabilityName: "A/R Segmentation & Prioritization for Collection",
          capabilityShortDescription: {
            html: null,
          },
          capabilityIcon: null,
          capabilityType: {
            capabilityTypeName: "Future",
            isActive: true,
          },
          isActive: true,
          isDeleted: false,
          displayOrder: 1,
          valuechain: {
            _path:
              "/content/dam/digiprint/data/valuechain/finance-revenue-and-payment-models",
            valueChainName: "Finance Revenue And Payment Models",
          },
          technologyEnablement: [
            {
              name: "AI & Intelligent Automation",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology3.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology3.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: "4",
            },
            {
              name: "Cyber",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: "2",
            },
          ],
          deloitteAssets: [
            {
              name: "One plus market ready assets",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset1.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset1.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: "1",
            },
            {
              name: "POC Or Initial Investment",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset2.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset2.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: "2",
            },
          ],
          deloitteCampaigns: [
            {
              name: "Care to Cure",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-campaigns-icons/Campaigns1.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-campaigns-icons/Campaigns1.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: "1",
            },
          ],
          industryEvolution: [],
          ecosystemAcceleration: [
            {
              name: "Salesforce",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem3.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem3.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: 3.0,
            },
            {
              name: "Google",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem5.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem5.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: 5.0,
            },
          ],
          businessValue: [
            {
              name: "Business Growth",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue1.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue1.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: 2.0,
            },
            {
              name: "Ops Efficiency",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue2.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue2.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: 3.0,
            },
          ],
        },
      ],
    },
  },
};

export const storefrontView = {
  data: {
    subsectorList: {
      items: [
        {
          sector: {
            _path: "/content/dam/digiprint/data/sector/health-care",
            sectorName: "Health Care",
            industry: {
              industryName: "Life Sciences & Health Care",
              _path:
                "/content/dam/digiprint/data/industry/life-sciences-and-health-care",
            },
          },
          subsectorName: "Health Plans (ACP)",
          subsectorDescription: {
            html: "<p>A sector-specific DigiPrint is a portfolio of cloud-enabled business capabilities and use cases activated through advanced...</p>\n",
          },
          _path: "/content/dam/digiprint/data/subsector/healthcare",
          subsectorIcon: {
            _authorUrl:
              "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/subsectoricons/life_science_health.png",
            _publishUrl:
              "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/subsectoricons/life_science_health.png",
          },
          subsectorImage: {
            _authorUrl:
              "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/images/subsectorimages/subsector_banner_image.png",
            _publishUrl:
              "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/images/subsectorimages/subsector_banner_image.png",
          },
        },
      ],
    },
    valuechainList: {
      items: [
        {
          _path:
            "/content/dam/digiprint/data/valuechain/finance-revenue-and-payment-models",
          valueChainDescription: {
            html: "<p>Cloud-enabled interoperability drives holistic patient profile and longitudinal analyses to develop disease progression Al models for proactive &quot;n of 1&quot; care delivery</p>\n",
          },
          valueChainName: "Finance Revenue And Payment Models",
          valueChainIcon: {
            _path:
              "/content/dam/digiprint/icons/valuechainicons/valueChain.png",
            _authorUrl:
              "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/valuechainicons/valueChain.png",
            _publishUrl:
              "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/valuechainicons/valueChain.png",
          },
          valueChainImage: null,
          isActive: true,
          isDeleted: false,
          displayOrder: 0,
          subsector: {
            subsectorName: "Health Plans (ACP)",
            sector: {
              sectorName: "Health Care",
              industry: {
                industryName: "Life Sciences & Health Care",
              },
            },
          },
          capabilities: [
            {
              capabilityName:
                "A/R Segmentation & Prioritization for Collection",
              capabilityShortDescription: {
                html: null,
              },
              capabilityIcon: null,
              capabilityType: {
                capabilityTypeName: "Future",
                isActive: true,
              },
              isActive: true,
              isDeleted: false,
              displayOrder: 1,
              valuechain: {
                _path:
                  "/content/dam/digiprint/data/valuechain/finance-revenue-and-payment-models",
                valueChainName: "Finance Revenue And Payment Models",
              },
              technologyEnablement: [
                {
                  name: "AI & Intelligent Automation",
                  description: {
                    html: null,
                  },
                  icon: {
                    _authorUrl:
                      "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology3.png",
                    _publishUrl:
                      "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology3.png",
                  },
                  isActive: true,
                  isDeleted: false,
                  displayOrder: "4",
                },
                {
                  name: "Cyber",
                  description: {
                    html: null,
                  },
                  icon: {
                    _authorUrl:
                      "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology.png",
                    _publishUrl:
                      "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology.png",
                  },
                  isActive: true,
                  isDeleted: false,
                  displayOrder: "2",
                },
              ],
              deloitteAssets: [
                {
                  name: "One plus market ready assets",
                  description: {
                    html: null,
                  },
                  icon: {
                    _authorUrl:
                      "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset1.png",
                    _publishUrl:
                      "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset1.png",
                  },
                  isActive: true,
                  isDeleted: false,
                  displayOrder: "1",
                },
                {
                  name: "POC Or Initial Investment",
                  description: {
                    html: null,
                  },
                  icon: {
                    _authorUrl:
                      "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset2.png",
                    _publishUrl:
                      "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset2.png",
                  },
                  isActive: true,
                  isDeleted: false,
                  displayOrder: "2",
                },
              ],
              deloitteCampaigns: [
                {
                  name: "Care to Cure",
                  description: {
                    html: null,
                  },
                  icon: {
                    _authorUrl:
                      "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-campaigns-icons/Campaigns1.png",
                    _publishUrl:
                      "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-campaigns-icons/Campaigns1.png",
                  },
                  isActive: true,
                  isDeleted: false,
                  displayOrder: "1",
                },
              ],
              industryEvolution: [],
              ecosystemAcceleration: [
                {
                  name: "Salesforce",
                  description: {
                    html: null,
                  },
                  icon: {
                    _authorUrl:
                      "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem3.png",
                    _publishUrl:
                      "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem3.png",
                  },
                  isActive: true,
                  isDeleted: false,
                  displayOrder: 3,
                },
                {
                  name: "Google",
                  description: {
                    html: null,
                  },
                  icon: {
                    _authorUrl:
                      "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem5.png",
                    _publishUrl:
                      "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem5.png",
                  },
                  isActive: true,
                  isDeleted: false,
                  displayOrder: 5,
                },
              ],
              businessValue: [
                {
                  name: "Business Growth",
                  description: {
                    html: null,
                  },
                  icon: {
                    _authorUrl:
                      "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue1.png",
                    _publishUrl:
                      "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue1.png",
                  },
                  isActive: true,
                  isDeleted: false,
                  displayOrder: 2,
                },
                {
                  name: "Ops Efficiency",
                  description: {
                    html: null,
                  },
                  icon: {
                    _authorUrl:
                      "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue2.png",
                    _publishUrl:
                      "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue2.png",
                  },
                  isActive: true,
                  isDeleted: false,
                  displayOrder: 3,
                },
              ],
            },
          ],
        },
      ],
    },
    capabilityList: {
      items: [
        {
          capabilityName: "A/R Segmentation & Prioritization for Collection",
          capabilityShortDescription: {
            html: null,
          },
          capabilityIcon: null,
          capabilityType: {
            capabilityTypeName: "Future",
            isActive: true,
          },
          isActive: true,
          isDeleted: false,
          displayOrder: 1,
          valuechain: {
            _path:
              "/content/dam/digiprint/data/valuechain/finance-revenue-and-payment-models",
            valueChainName: "Finance Revenue And Payment Models",
          },
          technologyEnablement: [
            {
              name: "AI & Intelligent Automation",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology3.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology3.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: "4",
            },
            {
              name: "Cyber",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: "2",
            },
          ],
          deloitteAssets: [
            {
              name: "One plus market ready assets",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset1.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset1.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: "1",
            },
            {
              name: "POC Or Initial Investment",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset2.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset2.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: "2",
            },
          ],
          deloitteCampaigns: [
            {
              name: "Care to Cure",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-campaigns-icons/Campaigns1.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-campaigns-icons/Campaigns1.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: "1",
            },
          ],
          industryEvolution: [],
          ecosystemAcceleration: [
            {
              name: "Salesforce",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem3.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem3.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: 3,
            },
            {
              name: "Google",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem5.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem5.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: 5,
            },
          ],
          businessValue: [
            {
              name: "Business Growth",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue1.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue1.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: 2,
            },
            {
              name: "Ops Efficiency",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue2.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue2.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: 3,
            },
          ],
        },
      ],
    },
  },
};

export const enablers_data = {
  technologyEnablementList: {
    items: [
      {
        name: "5G & Edge Computing",
        isActive: true,
        icon: {
          _publishUrl:
            "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology2.png",
          _authorUrl:
            "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology2.png",
        },
      },
    ],
  },
  deloitteassetList: {
    items: [
      {
        name: "One plus market ready assets",
        isActive: true,
        icon: {
          _publishUrl:
            "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset1.png",
          _authorUrl:
            "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset1.png",
        },
      },
    ],
  },
  deloitteCampaignsList: {
    items: [
      {
        name: "Care to Cure",
        isActive: true,
        icon: {
          _publishUrl:
            "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-campaigns-icons/Campaigns1.png",
          _authorUrl:
            "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-campaigns-icons/Campaigns1.png",
        },
      },
      {
        name: "Other",
        isActive: true,
        icon: {
          _publishUrl:
            "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-campaigns-icons/Campaigns2.png",
          _authorUrl:
            "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-campaigns-icons/Campaigns2.png",
        },
      },
    ],
  },
  industryEvolutionList: {
    items: [],
  },
  ecosystemAccelerationList: {
    items: [
      {
        name: "Amazon/AWS",
        isActive: true,
        icon: {
          _publishUrl:
            "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem2.png",
          _authorUrl:
            "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem2.png",
        },
      },
    ],
  },
  businessValueList: {
    items: [
      {
        name: "Business Growth",
        isActive: true,
        icon: {
          _publishUrl:
            "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue1.png",
          _authorUrl:
            "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue1.png",
        },
      },
    ],
  },
};

export const homepage_subnavigation = [
  {
    title: "Welcome to Digiprint",
    id: "welcomedigiprint",
  },
  {
    title: "Elements of a DigiPrint",
    id: "elementsdigiprint",
  },
  {
    title: "Industry Selection",
    id: "industryselection",
  },
  {
    title: "Useful Links",
    id: "usefullinks",
  },
  {
    title: "FAQ",
    id: "faq",
  },
  {
    title: "Contacts",
    id: "contacts",
  },
];

export const modal_props = {
  _path:
    "/content/dam/projects/digiprint/data/valuechain/ops-excellence-and-provider-experience",
  valueChainDescription: {
    html: "Some description",
  },
  valueChainName: "Ops Excellence And Provider Experience",
  valueChainIcon: {
    _path:
      "/content/dam/projects/digiprint/icons/valuechainicons/ops-excellence-and-provider-experience.png",
    _authorUrl:
      "http://localhost:4502/content/dam/projects/digiprint/icons/valuechainicons/ops-excellence-and-provider-experience.png",
    _publishUrl:
      "http://localhost:4503/content/dam/projects/digiprint/icons/valuechainicons/ops-excellence-and-provider-experience.png",
  },
  valueChainImage: null,
  isActive: true,
  isDeleted: null,
  displayOrder: 0,
  subsector: {
    subsectorName: "HealthCare",
    sector: {
      sectorName: "Health Care",
      industry: {
        industryName: "Life Sciences & Health Care",
      },
    },
  },
  capabilities: [
    {
      capabilityName: "AI Powered Medical Scribe",
      capabilityShortDescription: {
        html: null,
      },
      capabilityIcon: null,
      isActive: true,
      isDeleted: null,
      displayOrder: 0,
      benefitOrValue: {
        html: "<p>Reduced clinician burnout by digitizing medical scribing activities</p>\n<p>Ability to capture more details and nuances in transcript from patient visit in EMR charts</p>\n",
      },
      valuechain: {
        _path:
          "/content/dam/projects/digiprint/data/valuechain/ops-excellence-and-provider-experience",
        valueChainName: "Ops Excellence And Provider Experience",
      },
    },
    {
      capabilityName: "AI Powered Medical Scribe",
      capabilityShortDescription: {
        html: null,
      },
      capabilityIcon: null,
      isActive: true,
      isDeleted: null,
      displayOrder: 0,
      benefitOrValue: {
        html: "<p>Reduced clinician burnout by digitizing medical scribing activities</p>\n<p>Ability to capture more details and nuances in transcript from patient visit in EMR charts</p>\n",
      },
      valuechain: {
        _path:
          "/content/dam/projects/digiprint/data/valuechain/ops-excellence-and-provider-experience",
        valueChainName: "Ops Excellence And Provider Experience",
      },
    },
  ],
};

export const Technology = require("../assets/images/Technology/Technology.png");
export const Asset1 = require("../assets/images/Technology/Asset1.png");
export const BusinessValue = require("../assets/images/Technology/BusinessValue.png");
export const Campaigns1 = require("../assets/images/Technology/Campaigns1.png");
export const Ecosystem1 = require("../assets/images/Technology/Ecosystem1.png");

export const storefrontTechnology = {
  title: "Technologies",
  title1: "Dimensions",
  title2: "Assets",
  title3: "Campaigns",
  title4: "Ecosystems",
  technologyOptions: [
    {
      name: "Cloud",
      isActive: true,
      imageUrl: Technology,
      icon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "Cyber",
      isActive: true,
      imageUrl: Technology,
      techEnablerTypeIcon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "5G & Edge Computing",
      isActive: true,
      imageUrl: Technology,
      techEnablerTypeIcon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "AI & Intelligent Automation",
      isActive: true,
      imageUrl: Technology,
      techEnablerTypeIcon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "Unlimited reality",
      isActive: true,
      imageUrl: Technology,
      techEnablerTypeIcon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "Quantum Computing",
      isActive: true,
      imageUrl: Technology,
      techEnablerTypeIcon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "Physical Robotics",
      isActive: true,
      imageUrl: Technology,
      techEnablerTypeIcon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "Blockchain & Digital Assets",
      isActive: true,
      imageUrl: Technology,
      techEnablerTypeIcon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "Computer Vision / OCR",
      isActive: true,
      imageUrl: Technology,
      techEnablerTypeIcon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "IOT",
      isActive: true,
      imageUrl: Technology,
      techEnablerTypeIcon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
  ],
  deloitteAssetsOptions: [
    {
      name: "1+ Market-Ready Asset(s)",
      imageUrl: Asset1,
      isActive: true,
      icon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "POC / Initial Investment",
      imageUrl: Asset1,
      isActive: true,
      icon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "Whitespace",
      imageUrl: Asset1,
      isActive: true,
      deloitteAssetIcon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
  ],
  businessValueOptions: [
    {
      name: "Client Experience",
      imageUrl: BusinessValue,
      isActive: true,
      icon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "Business Growth",
      imageUrl: BusinessValue,
      isActive: true,
      icon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "Ops Efficiency",
      imageUrl: BusinessValue,
      isActive: true,
      icon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "Risk and Regulatory",
      imageUrl: BusinessValue,
      isActive: true,
      icon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "Workforce and Culture",
      imageUrl: BusinessValue,
      isActive: true,
      icon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
  ],
  deloitteCampaignsOptions: [
    {
      name: "Care to Cure",
      imageUrl: Campaigns1,
      isActive: true,
      icon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "Other",
      imageUrl: Campaigns1,
      isActive: true,
      icon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
  ],
  ecosystemAccelerationOptions: [
    {
      name: "Oracle",
      imageUrl: Ecosystem1,
      isActive: true,
      icon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "Amazon/AWS",
      imageUrl: Ecosystem1,
      isActive: true,
      icon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "Salesforce",
      imageUrl: Ecosystem1,
      isActive: true,
      icon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "Workday",
      imageUrl: Ecosystem1,
      isActive: true,
      icon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
    {
      name: "Google",
      imageUrl: Ecosystem1,
      isActive: true,
      icon: {
        _publishUrl: "null",
        _authorUrl: "null",
      },
    },
  ],
  industryEvolutionOptions: [],
};

export const storefrontViewMenu = [
  {
    valueChainName: "Care Management And Delivery",
    capabilities: [
      {
        capabilityName: "AI Diagnostics",
        technologyEnablement: [
          {
            name: "Cloud",
            imageUrl: Technology,
            icon: {
              _authorUrl: "null",
              _publishUrl: "null",
            },
          },

          {
            name: "Cyber",
            imageUrl: Technology,
            icon: {
              _authorUrl: "null",
              _publishUrl: "null",
            },
          },
        ],
        deloitteAssets: [
          {
            name: "POC / Initial Investment",
            imageUrl: Asset1,
            icon: {
              _authorUrl: "null",
              _publishUrl: "null",
            },
          },
          {
            name: "Whitespace",
            imageUrl: Asset1,
            icon: {
              _authorUrl: "null",
              _publishUrl: "null",
            },
          },
        ],
        businessValue: [
          {
            name: "Client Experience",
            imageUrl: BusinessValue,
            icon: {
              _publishUrl: "null",
              _authorUrl: "null",
            },
          },
          {
            name: "Business Growth",
            imageUrl: BusinessValue,
            icon: {
              _publishUrl: "null",
              _authorUrl: "null",
            },
          },
          {
            name: "Ops Efficiency",
            imageUrl: BusinessValue,
            icon: {
              _publishUrl: "null",
              _authorUrl: "null",
            },
          },

        ],
        deloitteCampaigns: [
          {
            name: "Care to Cure",
            imageUrl: Campaigns1,
            icon: {
              _publishUrl: "null",
              _authorUrl: "null",
            },
          },
        ],
        ecosystemAcceleration: [
          {
            name: "Oracle",
            imageUrl: Ecosystem1,
            icon: {
              _publishUrl: "null",
              _authorUrl: "null",
            },
          },
          {
            name: "Amazon/AWS",
            imageUrl: Ecosystem1,
            icon: {
              _publishUrl: "null",
              _authorUrl: "null",
            },
          },


        ],
        industryEvolution: [],
      },

      {
        capabilityName:
          "Analytics And Cognitive Practices Providing Real-time Analysis Of Care",
        technologyEnablement: [
          {
            name: "Unlimited reality",
            imageUrl: Technology,
            icon: {
              _authorUrl: "null",
              _publishUrl: "null",
            },
          },
          {
            name: "Quantum Computing",
            imageUrl: Technology,
            icon: {
              _authorUrl: "null",
              _publishUrl: "null",
            },
          },
        ],
        deloitteAssets: [
          {
            name: "1+ Market-Ready Asset(s)",
            imageUrl: Asset1,
            deloitteAssetIcon: {
              _authorUrl: "null",
              _publishUrl: "null",
            },
          },
          {
            name: "POC / Initial Investment",
            imageUrl: Asset1,
            deloitteAssetIcon: {
              _authorUrl: "null",
              _publishUrl: "null",
            },
          },
        ],
        businessValue: [
          {
            name: "Business Growth",
            imageUrl: BusinessValue,
            icon: {
              _publishUrl: "null",
              _authorUrl: "null",
            },
          },
          {
            name: "Ops Efficiency",
            imageUrl: BusinessValue,
            icon: {
              _publishUrl: "null",
              _authorUrl: "null",
            },
          },
          {
            name: "Workforce and Culture",
            imageUrl: BusinessValue,
            icon: {
              _publishUrl: "null",
              _authorUrl: "null",
            },
          },
        ],
        deloitteCampaigns: [
          {
            name: "Other",
            imageUrl: Campaigns1,
            icon: {
              _publishUrl: "null",
              _authorUrl: "null",
            },
          },
        ],
        ecosystemAcceleration: [
          {
            name: "Salesforce",
            imageUrl: Ecosystem1,
            icon: {
              _publishUrl: "null",
              _authorUrl: "null",
            },
          },
          {
            name: "Workday",
            imageUrl: Ecosystem1,
            icon: {
              _publishUrl: "null",
              _authorUrl: "null",
            },
          },
          {
            name: "Google",
            imageUrl: Ecosystem1,
            icon: {
              _publishUrl: "null",
              _authorUrl: "null",
            },
          },
        ],
        industryEvolution: [],
      },

    ],
  },
  {
    valueChainName: "Care Management And Delivery",
    capabilities: [],
  },
];

export const capabilities_tabs = {
  DA: "Deloitte Assets",
  ECS: "Ecopartners Solutions",
};

export const mock_capabilities = {
  data: {
    capabilityList: {
      items: [
        {
          capabilityName: "A/R Segmentation & Prioritization for Collection",
          capabilityDescription: {
            html: "<p>The RevenueIntellect Intelligent A/R Prioritization &amp; Segmentation solution predicts A/R account characteristics, including collectability, expected value, cost-to-collect, complexity-to-collect, and automation appropriateness, to intelligently prioritize accounts, maximize the collection value, efficiently deploy manual resources for outreach.</p>\n",
          },
          capabilityShortDescription: {
            html: null,
          },
          benefitOrValue: {
            html: "<p>Revenue-maximizing allocation of manual collection resources</p>\n",
          },
          capabilityType: {
            capabilityTypeName: "Future",
            isActive: true,
          },
          capabilityIcon: null,
          capabilityImage: null,
          displayOrder: 1,
          isActive: true,
          isDeleted: false,
          valuechain: {
            valueChainName: "Finance Revenue And Payment Models",
            valueChainIcon: {
              _publishUrl:
                "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/valuechainicons/valueChain.png",
              _authorUrl:
                "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/valuechainicons/valueChain.png",
            },
            subsector: {
              subsectorName: "Health Plans (ACP)",
              subsectorIcon: {
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/subsectoricons/life_science_health.png",
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/subsectoricons/life_science_health.png",
              },
              sector: {
                sectorName: "Health Care",
                sectorIcon: {
                  _publishUrl:
                    "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/sectoricons/health-care.png",
                  _authorUrl:
                    "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/sectoricons/health-care.png",
                },
                industry: {
                  industryName: "Life Sciences & Health Care",
                  industryIcon: {
                    _publishUrl:
                      "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/industryicons/life_science_health.png",
                    _authorUrl:
                      "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/industryicons/life_science_health.png",
                  },
                },
              },
            },
          },
          technologyEnablement: [
            {
              name: "AI & Intelligent Automation",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology3.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology3.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: "4",
            },
          ],
          deloitteAssets: [
            {
              name: "One plus market ready assets",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset1.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset1.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: "1",
            },
          ],
          deloitteCampaigns: [
            {
              name: "Care to Cure",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-campaigns-icons/Campaigns1.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-campaigns-icons/Campaigns1.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: "1",
            },
          ],
          industryEvolution: [],
          ecosystemAcceleration: [
            {
              name: "Salesforce",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem3.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem3.png",
              },
              referenceUrl: null,
              isActive: true,
              isDeleted: false,
              displayOrder: 3.0,
            },
          ],
          businessValue: [
            {
              name: "Business Growth",
              description: {
                html: null,
              },
              icon: {
                _authorUrl:
                  "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue1.png",
                _publishUrl:
                  "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue1.png",
              },
              isActive: true,
              isDeleted: false,
              displayOrder: 2.0,
            },
          ],
        },
      ],
    },
  },
};

export const capabilities_details = {
  logo: Group,
  title: "Pop Health & Epidemic Management",
  industryName: "Life Sciences & Health Care",
  sectorName: "Health Care",
  subSectorName: "Health Care Providers (Regional)",
  coreBusinessArea: "Patient Experience",
  description: {
    html: `<p>A set of predictive models to identify patients at-risk of various public health epidemics /pandemics, such as COVID, opioid abuse, alcohol and other substance abuse, etc. The models will primarily leverage patient EMR data and SDOH data to identify and/or predict high risk patients for proactive outreach.</p>`,
  },
  benefits: {
    html: `<p>Ability to identify and predict high risk patients for proactive outreach. <br />
    Ability to steer at-risk patients to local resources (i.e. COVID testing site)
    </p>`,
  },

  capabilityProperties: {
    technologies: [].concat(
      ...new Array(3).fill([
        {
          title: "AI & Intelligent Automation",
          logo: Group,
        },
      ])
    ),
    businessValue: [].concat(
      ...new Array(3).fill([
        {
          title: "AI & Intelligent Automation",
          logo: Group,
        },
      ])
    ),
    ecoSystems: [].concat(
      ...new Array(2).fill([
        {
          title: "AI & Intelligent Automation",
          logo: Group,
        },
      ])
    ),
  },
  deloitteAssets: [].concat(
    ...new Array(3).fill([
      {
        title: "ConvergeHEALTH Miner",
        logo: Group,
        description: {
          html: `<p>Market-proven, cloud-based platform designed to help transform how patient-level data (e.g., real-world data (RWD), patient registry, clinical trials, omics/biomarker data etc.) is managed,
        analyzed, and shared across the product life cycle.
        </p>`,
        },
        usefulLinks: {
          learnMore: "www.deloitte.com",
          benefits: "https://www.deloitte.com",
          use: "https://www.deloitte.com",
        },
        demo: {
          url: "https://www.deloitte.com",
        },
        campaigns: [
          {
            title: "Care To Cure",
            logo: Group,
          },
        ],
        contacts: [].concat(
          ...new Array(2).fill([
            {
              name: "Jeff Morgan",
              designation: "General Manager, ConvergeHealth",
              email: "naggarwal@deloitte.com",
              logo: user,
            },
          ])
        ),
      },
    ])
  ),
  ecoPartnerSolutions: [].concat(
    ...new Array(3).fill([
      {
        "ecoSystem": {
          "name": "Oracle",
          "description": {
            "html": null
          },
          "icon": {
            "_authorUrl": Group,
            "_publishUrl": Group
          },
          "isActive": true,
          "isDeleted": false,
          "displayOrder": 1
        },
        "title": "Oracle HX",
        "description": {
          "html": "<p>Market-proven, cloud-based platform designed to help transform how patient-level data (e.g., real-world data (RWD), patient registry, clinical trials, omics/biomarker data etc.) is managed,</p>\n<p>analyzed, and shared across the product life cycle.</p>\n"
        },
        "referenceLinkTitle": "Visit Oracle HX",
        "referenceLink": null,
        "isActive": true,
        "isDeleted": false,
        logo: Group
      }
    ])
  ),
};

export const storefrontnew_section = [
  {
    title: "Capability Storefront",
    subName:
      "Subheading allows user to recieve condensed information on selected section as well as its graphical representation without extra searching (scrolling).",
    description: {
      html: `<p>A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.
    A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.</p>`,
    },
  },
  {
    title: "Technology Enablement",
    subName:
      "Subheading allows user to recieve condensed information on selected section as well as its graphical representation without extra searching (scrolling).",
    description: {
      html: `<p>A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.
    A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.</p>`,
    },
  },
  {
    title: "Business Value",
    subName:
      "Subheading allows user to recieve condensed information on selected section as well as its graphical representation without extra searching (scrolling).",
    description: {
      html: `<p>A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.
    A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.</p>`,
    },
  },
  {
    title: "Deloitte’s Asset Portfolio",
    subName:
      "Subheading allows user to recieve condensed information on selected section as well as its graphical representation without extra searching (scrolling).",
    description: {
      html: `<p>A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.
    A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.</p>`,
    },
  },
  {
    title: "Deloitte Campaigns",
    subName:
      "Subheading allows user to recieve condensed information on selected section as well as its graphical representation without extra searching (scrolling).",
    description: {
      html: `<p>A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.
    A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.</p>`,
    },
  },
  {
    title: "Ecosystem Acceleration",
    subName:
      "Subheading allows user to recieve condensed information on selected section as well as its graphical representation without extra searching (scrolling).",
    description: {
      html: `<p>A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.
    A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.</p>`,
    },
  },
  {
    title: "Industry Evolution",
    subName:
      "Subheading allows user to recieve condensed information on selected section as well as its graphical representation without extra searching (scrolling).",
    description: {
      html: `<p>A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.
    A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.</p>`,
    },
  },
  {
    title: "Enterprise Consciousness",
    subName:
      "Subheading allows user to recieve condensed information on selected section as well as its graphical representation without extra searching (scrolling).",
    description: {
      html: `<p>A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.
    A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers. A comprehensive tool for data search, knowledge management and governance. Explore the data from hundreds of products and providers.</p>`,
    },
  },
];

export const errorLogs = [].concat(
  ...new Array(10).fill([
    {
      sender: "FrontEnd Error",
      logType: "FrontendError",
      logArea: "FrontendError",
      logMessage: `{"componentStack":"\n at AboutStoreFront (http://localhost:3000/main.d75902bd4b5aa1fec109.hot-update.js:40:80)\n at ErrorBoundary (http://localhost:3000/static/js/bundle.js:481:5)\n at div\n at Home (http://localhost:3000/static/js/bundle.js:6392:88)\n at PublicRoute (http://localhost:3000/static/js/bundle.js:4107:16)\n at div\n at AdminLayout (http://localhost:3000/static/js/bundle.js:623:23)\n at Routes (http://localhost:3000/static/js/bundle.js:79991:5)\n at Suspense\n at AppRoutes\n at Router (http://localhost:3000/static/js/bundle.js:79924:15)\n at BrowserRouter (http://localhost:3000/static/js/bundle.js:78733:5)\n at ApolloProvider (http://localhost:3000/static/js/bundle.js:99146:19)\n at Provider (http://localhost:3000/static/js/bundle.js:76050:5)\n at OidcAuthProvider (http://localhost:3000/static/js/bundle.js:3700:5)\n at App"}`,
      logDetails: `TypeError: Cannot read properties of undefined (reading '_authorUrl')`,
      loggedBy: "admin",
      loggedOn: "2022-09-06 12:30",
    },
    {
      sender: "HTTP Error",
      logType: "HTTPError",
      logArea: "HTTPError",
      logMessage: `{"componentStack":"\n at AboutStoreFront (http://localhost:3000/main.d75902bd4b5aa1fec109.hot-update.js:40:80)\n at ErrorBoundary (http://localhost:3000/static/js/bundle.js:481:5)\n at div\n at Home (http://localhost:3000/static/js/bundle.js:6392:88)\n at PublicRoute (http://localhost:3000/static/js/bundle.js:4107:16)\n at div\n at AdminLayout (http://localhost:3000/static/js/bundle.js:623:23)\n at Routes (http://localhost:3000/static/js/bundle.js:79991:5)\n at Suspense\n at AppRoutes\n at Router (http://localhost:3000/static/js/bundle.js:79924:15)\n at BrowserRouter (http://localhost:3000/static/js/bundle.js:78733:5)\n at ApolloProvider (http://localhost:3000/static/js/bundle.js:99146:19)\n at Provider (http://localhost:3000/static/js/bundle.js:76050:5)\n at OidcAuthProvider (http://localhost:3000/static/js/bundle.js:3700:5)\n at App"}`,
      logDetails: `TypeError: Cannot read properties of undefined (reading '_authorUrl')`,
      loggedBy: "admin",
      loggedOn: "2022-09-06 12:30",
    },
  ])
);

export const storefrontMenu_Props = {
  title: "Patient Experience",
  CardData: ["5G & Edge Computing", "AI & Intelligent Automation"],
  text: [
    {
      capabilityName: "Intelligent Contact Center for Schedule",
      capabilityShortDescription: {
        html: null,
      },
      capabilityIcon: null,
      capabilityType: {
        capabilityTypeName: "Future",
        isActive: true,
      },
      isActive: true,
      isDeleted: false,
      displayOrder: 1,
      valuechain: {
        _path: "/content/dam/digiprint/data/valuechain/patient-experience",
        valueChainName: "Patient Experience",
      },
      technologyEnablement: [
        {
          name: "5G & Edge Computing",
          description: {
            html: null,
          },
          icon: {
            _authorUrl:
              "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology2.png",
            _publishUrl:
              "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology2.png",
          },
          isActive: true,
          isDeleted: false,
          displayOrder: null,
        },
      ],
      deloitteAssets: [
        {
          name: "One plus market ready assets",
          description: {
            html: null,
          },
          icon: {
            _authorUrl:
              "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset1.png",
            _publishUrl:
              "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-asset-portfolio-icons/Asset1.png",
          },
          isActive: true,
          isDeleted: false,
          displayOrder: "1",
        },
      ],
      deloitteCampaigns: [
        {
          name: "Care to Cure",
          description: {
            html: null,
          },
          icon: {
            _authorUrl:
              "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-campaigns-icons/Campaigns1.png",
            _publishUrl:
              "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/deloitte-campaigns-icons/Campaigns1.png",
          },
          isActive: true,
          isDeleted: false,
          displayOrder: "1",
        },
      ],
      industryEvolution: [],
      ecosystemAcceleration: [
        {
          name: "Oracle",
          description: {
            html: null,
          },
          icon: {
            _authorUrl:
              "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem1.png",
            _publishUrl:
              "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/ecosystem-acceleration-icons/Ecosystem1.png",
          },
          isActive: true,
          isDeleted: false,
          displayOrder: 1,
        },
      ],
      businessValue: [
        {
          name: "Business Growth",
          description: {
            html: null,
          },
          icon: {
            _authorUrl:
              "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue1.png",
            _publishUrl:
              "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/business-value-icons/BusinessValue1.png",
          },
          isActive: true,
          isDeleted: false,
          displayOrder: 2,
        },
      ],
    },
  ],
  logo: "abc",
  CardData: [],
  activeTab: "Technology Enablement",
  maturityListData: [
    {
      name: "5G & Edge Computing",
      isActive: true,
      icon: {
        _publishUrl:
          "https://publish-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology2.png",
        _authorUrl:
          "https://author-p88452-e751021.adobeaemcloud.com/content/dam/digiprint/icons/technology-enablement-icons/Technology2.png",
      },
    },
  ],
};


export const file_mime_types = {
  "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "xls": "application/vnd.ms-excel"
}

export const admin_navbar_menu = {
  MASTER_DATA: "Master Data",
  STOREFRONT_DATA: "Storefront Data",
  REDIS_CACHE: "Redis Cache",
};