import React from "react";
import classes from "./Button.module.scss";
import addIcon from './../../../src/assets/images/common/white-add-icon.svg';
import subtractIcon from './../../../src/assets/images/common/substractIcon.png';

function Button(props) {
    return (<>
        <button className={props.digiprintAdded ? classes.capabilityAddButtonDigiPrintAdded : props.label === 'Learn more' ? classes.learn : classes.capabilityAddButton} onClick={(e) => {
            if(props.hasIcon)
            props.digiprintAdded ?  props.removeCapabilityFromList() : props.addCapability() 
            else 
            props?.actionTobeTake(e)
        }} style = {props.inlineStyles ? props.inlineStyles : {}}>
            <div style={{display: 'flex'}}>
                <div>{props.hasIcon && ! props.digiprintAdded && <img className={classes.addButtonIcon} src={addIcon} alt="add to digiprint"/>}</div>
                <div>{props.hasIcon && props.digiprintAdded && <img className={classes.addButtonIcon} src={subtractIcon} alt="remove from digiprint"/>}</div>
                <div style={{padding: '2px 0px'}}>{ props.hasIcon ? props.digiprintAdded ? `Remove from ${props.label}` : `Add to ${props.label}` : props.label}</div>
            </div>
        </button>
    </>)

}

export default Button;