import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./index.scss";
import { PublicClientApplication, EventType } from '@azure/msal-browser';

fetch(process.env.PUBLIC_URL + '/config/configuration.json')
    .then(r => r.json())
    .then((configData) => {
        window.CONFIG = configData;

        // Adobe analytics
        const omnitureScript = document.createElement('script');
        omnitureScript.src=configData.OMNITURE.url;
        omnitureScript.type="text/javascript";
        omnitureScript.charset="UTF-8";
        document.head.appendChild(omnitureScript);

        // Cookie consent
        const cookieScript = document.createElement('script');
        cookieScript.src=`https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`;
        cookieScript.type="text/javascript";
        cookieScript.charset="UTF-8";
        cookieScript.setAttribute('data-domain-script',configData.COOKIESCONSENT.data_domain_script);
        document.head.appendChild(cookieScript);        

        let scope_base_url = window.CONFIG.APP.base_url;
        if (scope_base_url === 'https://digiprintsandbox.deloitte.com:3000')
            scope_base_url = 'https://ddigiprint.deloitte.com';

        let scopeApiAccessCall = scope_base_url + '/apiaccesscall';
        window.CONFIG.SSO.scopes = [scopeApiAccessCall];

        const publicClientApp = new PublicClientApplication({
            auth: {
                clientId: window.CONFIG.SSO.client_id,
                authority: window.CONFIG.SSO.authority,
                redirectUri: window.CONFIG.APP.base_url + '/login-callback',
                postLogoutRedirectUri: window.CONFIG.APP.base_url + '/logout-callback'
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level, message, containsPII) => {
                        //console.log(message);
                    },
                    logLevel: "Info"
                },
                tokenRenewalOffsetSeconds: 60 * 60
            }
        });

        publicClientApp.addEventCallback(event => {
            if (event.eventType === EventType.LOGIN_SUCCESS) {
                publicClientApp.setActiveAccount(event.payload.account);
            }
        });

        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <>
                <App msalInstance={publicClientApp} />
            </>
        );
    });
