import React from "react";
import classes from "./UseFullLinks.module.scss";
import "../../Home.scss";
import DescriptiveCards from "../DescriptiveCards/DescriptiveCards";
import Loader from "../../../../components/loader";
import ErrorBoundary from "../../../../components/error-boundary/ErrorBoundary";

const UseFullLinks = (props) => {
  const { usefulLinksList, loading, title, description } = props
  const redirectToLink = (link) => {
    window.open( link, '_blank' );
  }
  return (
    <ErrorBoundary>
      {loading && <Loader />}
      {!loading &&
        <div className={classes.usefulLinksContainer} >
          <div className={classes.usefulLinksContainerTitle}>
            {title? title : ""}
          </div>
          <div className={classes.usefulLinksContainerDescription}>
            {description ? description : ""}
          </div>
          <div className={classes.descriptiveCards}>
            {usefulLinksList && usefulLinksList.map((links) =>
              <DescriptiveCards style={ {cursor: 'pointer' , width: `${100/usefulLinksList.length}%`}} cardTag={links.title} cardTitle={links.description.plaintext} arrowIcon = {true} redirectToLink = {redirectToLink} link = {links.link} isActive= {links.isactive}></DescriptiveCards>
            )}
          </div>
        </div>}
    </ErrorBoundary>
  );
};

export default UseFullLinks;
