
export const industryCircle = require("../assets/images/homePage/industry-circle.png");
export const downArrow = require("../assets/images/homePage/down-arrow.png");
export const upArrow = require("../assets/images/homePage/up-arrow.png");
export const up = require("../assets/images/common/up.png");
export const down = require("../assets/images/common/down.png");
export const ellipse = require("../assets/images/industries/ellipse.png");
export const Learnmore = require("../assets/images/homePage/Learnmore.png");
export const Learnmore1 = require("../assets/images/homePage/Learnmore1.png");
export const LinksArrow = require("../assets/images/homePage/arrow_outward.png")
export const Group = require("../assets/images/sector/Group.png");
export const enable = require("../assets/images/sector/enable.png");
export const logout = require("../assets/images/common/logout.png");
export const Technology = require("../assets/images/storefront-view/Technology.png");
export const download_icon = require("../assets/images/storefront-view/download_icon.png");
export const contactimage = require("../assets/images/homePage/contactimage.png");
export const Nodata = require("../assets/images/sector/Nodata.png");
export const Storefront = require("../assets/images/homePage/Storefront.png");
export const Storefront1 = require("../assets/images/homePage/Storefront1.png");
export const Storefront2 = require("../assets/images/homePage/Storefront2.png");
export const Storefront3 = require("../assets/images/homePage/Storefront3.png");
export const Storefront4 = require("../assets/images/homePage/Storefront4.png");
export const Ellipse1 = require("../assets/images/homePage/Ellipse1.png");
export const Ellipse2 = require("../assets/images/homePage/Ellipse2.png");
export const redirect = require("../assets/images/common/redirect.png");
export const user = require("../assets/images/common/user.png");
export const whiteArrow = require("../assets/images/common/white-arrow.png");
export const emptyCapabilities = require("../assets/images/common/vc-not-found.png");
export const Close = require("../assets/images/homePage/Close.png");
export const defaultBanner = require("../assets/images/common/digi_print_default_banner.png")
export const defaultCircle = require("../assets/images/common/digi_print_default_circle.png")
export const defaultbannerBlack = require("../assets/images/common/digi_print_default_banner_black.png")
export const defaultInductryBlack = require("../assets/images/common/digi_print_default_banner_black_industry.png")
export const bannerImagesList = [
  require("../assets/images/homePage/Banner1.png"),
  require("../assets/images/homePage/Banner2.png"),
  require("../assets/images/homePage/Banner3.png"),
  require("../assets/images/homePage/Banner4.png"),
  require("../assets/images/homePage/Banner5.png")
]

export {
  industryCircle as InustryCircleImage,
  downArrow as DownArrowImage,
  ellipse as EllipseImage,
  Learnmore as LearnmoreImage,
  Learnmore1 as Learnmore1Image,
  LinksArrow as LinksArrowImage,
  Group as GroupImage,
  enable as enableImage,
  logout as logoutImage,
  Technology as TechnologyImage,
  download_icon as download_iconImage,
  contactimage as contactimageImage,
  Nodata as NodataImage,
  Storefront as StorefrontImage,
  Storefront1 as Storefront1Image,
  Storefront2 as Storefront2Image,
  Storefront3 as Storefront3Image,
  Storefront4 as Storefront4Image,
  Ellipse1 as Ellipse1Image,
  Ellipse2 as Ellipse2Image,
  redirect as RedirectIcon,
  Close as CloseImage,
  up as UpImage,
  down as DownImage,
  defaultBanner as DefaultBanner,
  defaultCircle as DefaultCircle,
  defaultbannerBlack as DefaultBannerBlack,
  defaultInductryBlack as DefaultInductryBlack,
  bannerImagesList as BannerImagesList
};
