import React, { useEffect, useState } from "react";
import classes from "./CapabilityDetailsTemplate.module.scss"
import Tag from "../../tag";
import { Row, Col } from 'reactstrap';
import { RedirectIcon } from "../../../constants/images";
import { getUniqueListBy } from "../../../utils/assets_data_converter";
import GreenTick from './../../../assets/images/sector/greenTick.png'
import { replaceBreakWithNextline } from './../../../utils/utilities';


function CapabilityDetailsTemplate(props) {

  const [ecoSystem, setEcoSystem] = useState([]);
  const { businessValue, technologyEnablement, valuechain, capabilityName,
    ecoPartnerSolutions, benefitOrValue, capabilityDescription, subCapabilities } = props.capability;
  const getEcoSystem = () => {
    let ecoSolutionsTemp = ecoPartnerSolutions;
    if (ecoSolutionsTemp) {
      let ecoSolutionData = [];
      ecoSolutionsTemp.forEach((element) => {
        ecoSolutionData.push(element.ecoSystem);
      });
      let uniqueList = getUniqueListBy(ecoSolutionData, "name");
      setEcoSystem(uniqueList);
    }
  };

  useEffect(() => {
    getEcoSystem();
  }, []);

  return (
    <Row id={`capability-template-9${props?.index}`} style={{padding: '1rem',  width:'80%', position:"absolute", top:'-99999px', left:'-99999px'}} > 
     {props.capability && <Col lg="4" md="4" className={classes.leftSectionContainer}>
        <div className={classes.valueChain}>{valuechain} </div>
        <div className={classes.capabilityName}>{capabilityName} </div>
        <div className={classes.showAtBottom}>
          <div className={classes.header}>Business Value</div>
          {
            businessValue?.map(item =>
              <Tag closable={false} className={classes.tag} >{item}</Tag>
            )
          }
          {technologyEnablement?.length > 0 && <div className={classes.header}>Technologies</div>}
          {
            technologyEnablement?.map(item =>
              <Tag closable={false} className={classes.tag}>{item}</Tag>
            )
          }
        </div> 
      </Col>}
      {props.capability &&  <Col lg="6" md="6" className={classes.middleSectionContainer}>
        <div>
          <div className={classes.capabilityTitle}><strong>Overview</strong></div>
          {capabilityDescription && (
            <div style={{ paddingTop: '16px', fontWeight:'400', color: '#63666a', fontFamily: 'Open Sans', fontSize:'14px'}}>{capabilityDescription}</div>
          )}
        </div>
        {
          benefitOrValue !== 'N/A' ? <div className={classes.detailsArea}>
            <p className={classes.bnvTitle}>Benefits and Value</p>
            {benefitOrValue?.map((item) => (
              <div className={classes.benefitOrValue}>
                <div><img src={GreenTick} alt="green tick" width='27px' height='27px' /></div>
                <div className={classes.detailsText}>{item}</div>
              </div>
            ))}
          </div>
          :
          <div>N/A</div>
        }
        {
          ecoSystem && ecoSystem.length > 0 && <div>
            <p className={classes.ecosysStyle} >Compatible Eco-Systems</p>
            {
              ecoSystem?.map((item, index) => (
                <div key={index} className="capability-properties-content">
                  <div className="d-inline">
                    {item.icon && item.icon._imageBase64 && (
                      <img
                        src={item.icon._imageBase64}
                        alt=""
                        width="24px"
                        height="24px"
                      />
                    )}
                  </div>
                  <div className="d-inline enabler-title">{item}</div>
                  <div className="d-inline float-end mt-2 redirect">
                    <a
                      href={item?.referenceUrl || "https://www.deloitte.com/about"}
                      className="ecosystem-icon"
                    >
                      <img src={RedirectIcon} alt="" width="12px" height="12px" />
                    </a>
                  </div>
                  {index !== ecoSystem.length - 1 && (
                    <hr className="capability-details-underline" />
                  )}
                </div>
              ))}
          </div>
        }
      </Col>}
      {props.capability && <Col lg="2" md="2" className={classes.rightSectionContainer}>
        {
          subCapabilities.length > 0 && <div>
            {subCapabilities.length>0 && <p className={classes.subCapability} >Sub-Capabilities</p>}
            {
              subCapabilities?.map((item, index) => (
                <li className={classes.subCapName} key={index}>
                  { item }
                </li>
              ))}
          </div>
        }
      </Col>}
    </Row>
  );
}

export default CapabilityDetailsTemplate;
