import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import AppRoutes from "./components/routing/index";
import { MsalProvider } from "@azure/msal-react";
import SiteUnderConstructionAlert from './components/SiteUnderConstruction/SiteUnderConstructionAlert';

function App({ msalInstance }) {
    return (
        <MsalProvider instance={msalInstance}>
            <Provider store={store}>
                <>
                    <SiteUnderConstructionAlert/>
                    <Router>
                        <AppRoutes />
                    </Router>
                </>
            </Provider>
        </MsalProvider>
    );
}

export default App;
