export const GET_HOMEPAGE_ITEMS = `
query GetHomepageItems{
  homePageItemsList{
    items{
      image{
       ... on MultimediaRef{
          _authorUrl
          _publishUrl
        }
      }
      header
      description{
        plaintext
      }
    }
  }
}`

export default {
    GET_HOMEPAGE_ITEMS
}
