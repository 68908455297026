import React from 'react'
import styles from './Navbar.module.scss'

const ContactsPageNavbar = (props) => {
 
  return (
    <>
        <div className={styles.mainDiv}>
          {
            props.tabs.sort().map((industry) => (
              <div className={props.activeTab === industry ? styles.industryDivActive  : styles.industryDiv} onClick={() => props.selectedTab(industry)}>{industry}</div>
            ))
          }
        </div>
        <hr style={{margin: '-0.1rem 0rem', color: '#D0D0CE'}}/>
    </>
  )
}

export default ContactsPageNavbar