import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Input,
  Alert,
  NavItem,
  NavLink,
  Nav,
  Spinner,
} from "reactstrap";
import "./Adminsettings.scss";
import { POST_CALL, GET_CALL } from "../../../services/httpService";
import {
  admin_navbar_menu,
  file_mime_types,
} from "../../../constants/commonConstants";
import { useDispatch, useSelector } from "react-redux";
import { setAdminMenu } from "../../../store/actions/adminAction";
import { useNavigate } from "react-router-dom";
import { actionTracking, pageView } from "../../../utils/omniture";
import { pageNames } from "../../../constants/omnitureConstants";

function RedisCache(props) {
  const [redisLoading, setRedisLoading] = useState(false);
  const [redisSuccessMsg, setRedisSuccessMsg] = useState("");
  const [redisErrors, setRedisErrors] = useState("");
  const [httpError, setHttpError] = useState([]);

  const dispatch = useDispatch();
  const adminReducer = useSelector((state) => ({
    item: state.adminReducer,
  }));
  const navReducer = useSelector((state) => ({
    item: state.navigationReducer
  }))
  const navigate = useNavigate();

  useEffect(() => {
    if (!navReducer.item.isAdmin) {
      navigate("/errorpage/?e=401");
    }
  }, [navReducer.item]);

  useEffect(() => {
    setTimeout(() => {
      setRedisSuccessMsg("");
    }, 6000);
  }, [redisSuccessMsg]);

  useEffect(() => {
    setTimeout(() => {
      setRedisErrors("");
    }, 6000);
  }, [redisErrors]);

  useEffect(() => {
    if (navReducer.item.userEmail !== "") {
      pageView(pageNames.REDIS_CACHE, navReducer.item.userEmail);
    }
  }, [navReducer.item.userEmail]);

  const updateSelectedType = (type) => {
    if (navReducer.item.userEmail !== "") {
      actionTracking(pageNames.REDIS_CACHE,navReducer.item.userEmail,type)
    }
    dispatch(setAdminMenu(type));
  };

  const redisCache = async (e) => {
    try {
      if (props.accessToken) {
        if (navReducer.item.userEmail !== "") {
          actionTracking(pageNames.REDIS_CACHE,navReducer.item.userEmail,`Flush Cache`,"Click")
        }
        setRedisLoading(true);
        let redisUrl = "/api/redis/flushcache";
        const payload = {};
        let data = await POST_CALL(redisUrl, payload, {
          accessToken: props.accessToken,
        });
        setRedisLoading(false);
        if (data.status === 200) {
          let res = data?.data;
          if (res.hasError === true) {
            setHttpError(res.errors);
          } else {
            setHttpError([]);
            setRedisSuccessMsg("Flushed Cache successfully");
          }
        }
      }
    } catch (err) {
      setRedisLoading(false);
      setRedisErrors("Something Went wrong");
    }
  };

  useEffect(() => {
    dispatch(setAdminMenu(admin_navbar_menu.REDIS_CACHE));
  }, []);

  return (
    <>
      <div className="admin-layout">
        <Row>
          <Col lg="2" className="admin_navbar_left_color">
            <div className="admin_navbar_left">
              <Nav pills vertical>
                <NavItem
                  onClick={() => {
                    updateSelectedType(admin_navbar_menu.MASTER_DATA);
                    navigate("/adminsettings");
                  }}
                >
                  <NavLink
                    className="ps-5"
                    active={
                      adminReducer.item.menu ===
                      admin_navbar_menu.MASTER_DATA ?? true
                    }
                    href="#"
                  >
                    Master Data
                  </NavLink>
                </NavItem>
                <NavItem
                  onClick={() => {
                    updateSelectedType(admin_navbar_menu.STOREFRONT_DATA);
                    navigate("/adminsettings");
                  }}
                >
                  <NavLink
                    className="ps-5"
                    active={
                      adminReducer.item.menu ===
                      admin_navbar_menu.STOREFRONT_DATA ?? true
                    }
                    href="#"
                  >
                    Storefront Data
                  </NavLink>
                </NavItem>
                <NavItem
                  onClick={() => {
                    updateSelectedType(admin_navbar_menu.REDIS_CACHE);
                  }}
                >
                  <NavLink
                    className="ps-5"
                    active={
                      adminReducer.item.menu ===
                      admin_navbar_menu.REDIS_CACHE ?? true
                    }
                    href="#"
                  >
                    Redis Cache
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
          <Col lg="10">
            {adminReducer.item.menu === admin_navbar_menu.REDIS_CACHE && (
              <>
                <div className="admin_heading_data">
                  <Row>
                    <div>
                      <span>Redis Cache</span>
                    </div>
                  </Row>
                </div>
                <div className="radiscache_navbar_right">
                  <div
                    className="rediscache-download-btn"
                    id="download-btn"
                    onClick={(e) => {
                      !redisLoading && redisCache(e);
                    }}
                  >
                    {redisLoading ? (
                      <Spinner
                        color="light"
                        className="d-block m-auto text-center"
                        size="sm"
                      />
                    ) : (
                      <div className="storefront-download-option">
                        <div className="d-inline adminsetting-download-option-text">
                          Flush Cache
                        </div>
                      </div>
                    )}
                  </div>
                  {redisSuccessMsg !== "" && (
                    <Alert color="success" className="redissuccess-alert">
                      <p>{redisSuccessMsg}</p>
                    </Alert>
                  )}
                  {redisErrors !== "" && (
                    <Alert color="danger" className="rediserror-alert">
                      <p>{redisErrors}</p>
                    </Alert>
                  )}
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default RedisCache;
