import React from "react";
import classes from "./MyDigiPrints.module.scss";
import { Row, Col } from 'reactstrap';

function MyDigiPrints( props ) {

    const { bookmarkList, navigateToStoreFrontPage } = props || {};
    return <div className={classes.container}>
        <div className={classes.mydigiprint}>
            My DigiPrints
        </div>
        <div className={classes.cardBody}>
            <div className={classes.cardTitle}>Bookmarked Storefronts</div>
            <Row className={classes.allCardContainer}>
                {
                    bookmarkList.map((item,index)=>{
                        return <Col onClick={()=>navigateToStoreFrontPage(item?.subsectorName)} key={index} className={`col col-md-4 col-sm-4 ${classes.cardContainer}`}>
                            <Row >
                                <Col className="col col-md-3 col-sm-3" style={{padding:'0px'}} >
                                    <img src={item?.subsectorImage?._publishUrl} style={{ width:'112px', height:'112px'}}/>
                                </Col>
                                <Col className={`col col-md-9 col-sm-9 ${classes.cardRightSection}`}>
                                    <span className={classes.industryName}>{item?.sector?.sectorName}</span><br/>
                                    <span className={classes.sectorName}>{item?.subsectorName}</span>
                                </Col>
                            </Row>
                        </Col>
                    }) 
                }
            </Row>
        </div>
    </div>
}

export default MyDigiPrints;
