import React from 'react'
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import CapabilityDetailsTemplate from './../../components/exportDigiprintTemplates/CapabilityDetailsTemplate/CapabilityDetailsTemplate.component'
import { replaceBreakWithNextline } from './../../utils/utilities'
import "./index.scss"
import SelectionTemplate from '../../components/exportDigiprintTemplates/SelectionTemplate/SelectionTemplate.component';
import { exportDefaultSpecifier } from '@babel/types';

const DigiprintExport = (props) => {
    const sfReducer = props.sfReducer;
    let selectedCapabilityList = sfReducer.item.capabilityList;     //selected capabilities
    let selectedCapabilityNamesArray = selectedCapabilityList.map((item) => {
        return item.capabilityList
    }).flat()
    let stateData = selectedCapabilityNamesArray.map((item) => {
      const capabilityName=item.capabilityName;
        return {
            capabilityName: capabilityName,
            valuechain: sfReducer.capabilityDescriptions[capabilityName][1],
            technologyEnablement: sfReducer.capabilityDescriptions[capabilityName][2],
            businessValue: sfReducer.subsectorSpecificCapabilities[capabilityName],
            ecoPartnerSolutions: sfReducer.capabilityDescriptions[capabilityName][3],
            benefitOrValue: sfReducer.benefitsOrValue[capabilityName]?.length > 0 ? replaceBreakWithNextline(sfReducer.benefitsOrValue[capabilityName]) : 'N/A',
            capabilityDescription: sfReducer.capabilityDescriptions[capabilityName][0],
            subCapabilities: []
        }
    })

    /*
    const subsectorData =
      sfReducer.subsectorList.map((subsectorItem) => {
        if(removeSpaceWithHypens(subsectorItem.subsectorName) === sfReducer.currentSubsector) {
          return subsectorItem
        }
        else {
          return false
        }
      })
    */

    const allSelectedBusinessValues=['', 'Business Growth', 'Customer Experience','Ops Efficiency',  'Risk & Regulatory', 'Workforce & Culture'];

    const pdfCanvas = async (id) => {
        const input = document.getElementById(id);
        input.style.width = 1200 + "px";
        input.style.height = parseInt(800)  + "px";
        let val = await html2canvas(input,{useCORS:true}).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          return imgData
        });
        return val;
        
      };


    const downloadPDF = async ()=>{
      try {
        await document.getElementById("loading-div")?.classList.remove("loading-div");
        let pdf_name = "digiprint.pdf"
        const pdf = new jsPDF("l", "mm", "a2");      
        
        const selectionPages = allSelectedBusinessValues.map((item, index) => `capability-template-5${index+2}`);
        const pages = [...selectionPages, ...stateData.map((item, index) => `capability-template-9${index}`)];
        
        props?.coverTemplateImage?.then((canvas)=>{
          pdf.addImage(canvas, "PNG", 10, 10);
          pdf.addPage();
        })

        for (let i = 0; i < pages?.length ; i++) {
          await pdfCanvas(pages[i]).then((canvas) => {
            pdf.addImage(canvas, "PNG", 10, 10);
            if(i === pages.length-1){
              pdf.save(pdf_name);
              document.getElementById("loading-div")?.classList.add("loading-div"); 
            }
            pdf.addPage();
          });
        }
      }
      catch{
        //setIsDisplayLoading(false);
        document.getElementById("loading-div")?.classList.add("loading-div"); 
      }
    }
  
    return (
        <React.Fragment>
          <span className="build-digiprint-container">
            <span id="loading-div" className="loading-div">
                <span className="icon-div"><div className="spinner-border text-primary loading-icon" role="status"></div></span>
                <span className="loading-text">Preparing your DigiPrint</span>
            </span> 
          
          <button id='buildDigiprint' onClick={ ()=>{ downloadPDF(); } } className= "buildDigiprint" >Build DigiPrint</button>
            {
                stateData.length > 0 && stateData.map((item, index) => (
                    <CapabilityDetailsTemplate capability={item} index={index} key={index} />
                ))
            }
            {
              allSelectedBusinessValues.map((businessValue,index)=>
                <SelectionTemplate subsectorName={sfReducer.currentSubsector} selectedCapabilities={selectedCapabilityList} key={index} index={index+2} selectedBusinessValue={businessValue}/>
            )}        
          </span>    
        </React.Fragment>
    )
}

export default DigiprintExport;