import React from "react";
import classes from "./HomeDigiprintComesToLife.module.scss";
import "../../Home.scss";
import DescriptiveCards from "../DescriptiveCards/DescriptiveCards";
import Loader from "../../../../components/loader";
import ErrorBoundary from "../../../../components/error-boundary/ErrorBoundary";

const HomeDigiprintComesToLife = (props) => {
  const { digiprintComesToLifeList, loading, title, description } = props;
  const height = digiprintComesToLifeList.length > 3 ? '769px' : '634.33px'
  return (
    <ErrorBoundary>
      {loading && <Loader />}
      {!loading &&
        <div className={classes.digiprintComesToLifeContainer} style={{height: height}}>
          <div className={classes.digiprintComesToLifeContainerTitle}>
            {title? title : ""}
          </div>
          <div className={classes.digiprintComesToLifeContainerDescription}>
            {description ? description : ""}
          </div>
          <div className={classes.descriptiveCards}>
            {digiprintComesToLifeList && digiprintComesToLifeList.map((digiprintComesToLife) =>
              <DescriptiveCards style={ {padding: '40px 40px 0px 40px',  width: `${100/digiprintComesToLifeList.length}%` }} isActive = {digiprintComesToLife.isActive}
              cardTag={digiprintComesToLife.step} cardDescription={digiprintComesToLife.description.plaintext} cardTitle={digiprintComesToLife.heading} arrowIcon = {false} setCustomHeight = {digiprintComesToLifeList.length > 3}></DescriptiveCards>
            )}
          </div>
        </div>}
    </ErrorBoundary>
  );
};

export default HomeDigiprintComesToLife;
