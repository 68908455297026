import React from 'react'
import closeIcon from "../../assets/images/common/close.png"

import styles from './PreviewTag.module.scss'

function PreviewTag(props) {

  const removeFilter = (removedTag) => {
    props.removeFilter(removedTag)
  }

  return (
    <div data-testid="preview-tag">
      {props.names && props.names.map((item, index) => (
        <div key={index} className={styles.tag}>
          <div className={styles.content}>
            {item}
          </div>
          <div>
            <img src={closeIcon} data-testid={"preview-tag-filter-remove-btn"+index} id={"preview-tag-filter-remove-btn"+index} className={styles.close} onClick={() => removeFilter(item)}/>
          </div>
        </div>
      ))
      }
    </div>
  )
}

export default PreviewTag