import React from 'react'
import { Spinner } from 'reactstrap';
import './style.css'

function Loader() {
    return (
        <div className="loading">
            <Spinner color="success" >
                Loading...
            </Spinner>
        </div>
    )
}

export default Loader