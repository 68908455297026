import React from 'react'
import userImg from './../../../src/assets/images/common/user.png';
import contactEmailIcon from './../../../src/assets/images/common/contact-email-icon.png';
import copyIcon from './../../../src/assets/images/common/copy-icon.png';
import styles from './ContactCard.module.scss'

const ContactCard = (props) => {
  const {contactPersonImg, name, designation, email, industryFlag, subsectorArr} = props;

  const commonDivStyle = {padding: '12px', backgroundColor: 'white', width: '276px', marginBottom: '24px'}

  const subsectorArrStyle = {height: '510px'}
  const withoutSubsectorArrStyle = {height: '418px'}

  return (
    <>
      { props.name?.length > 0 &&
        <div style={subsectorArr?.length > 0 ? {...commonDivStyle, ...subsectorArrStyle} : {...commonDivStyle, ...withoutSubsectorArrStyle}}>
            <img style={{width:'252px', height:'252px', objectFit: 'cover'}} src={contactPersonImg.length > 0 ? 'data:image/jpeg;base64,' + contactPersonImg : userImg} alt="contact-person"/>
            <div style={{width: '252px', height: '140px', padding: '8px'}}>
              <div style={{width: '236px', height: '60px', padding: '4px 0px'}}>
                <p style={{marginBottom: '8px', fontSize: '16px', fontFamily: 'Open Sans'}}>{name}</p>
                <p style={{fontSize: '14px', color: '#63666A'}}>{designation}</p>
              </div>
              <hr style={{color: '#b5b7bd'}}/>
              { (industryFlag && subsectorArr?.length > 0) && <div>
                  <div style={{width: '236px', height: '60px', padding: '4px 0px'}}>
                    {
                      subsectorArr?.length > 0 && subsectorArr.map((subsectorName) => (
                        <p style={{marginBottom: '8px', fontSize: '14px', fontFamily: 'Open Sans'}}>{subsectorName}</p>
                      ))
                    }
                  </div>
                  <hr style={{color: '#b5b7bd'}}/>
              </div>
              }
              <div className={styles.emailDiv}>
                  <div><img src={contactEmailIcon} width='18px' height='14px' alt='contact email'/> &nbsp;</div>
                  <div style={{display: 'flex'}}>
                    <a style={{cursor: 'pointer', width: '196px', height: '22px'}} href={`mailto:${email}`}>{email}</a>
                    <img onClick={() =>  navigator.clipboard.writeText(email)} className={styles.copyIcon} src={copyIcon} width='18px' height='18px' alt='copy email'/>
                  </div>
              </div>
            </div>
        </div>
      }
    </>
  )
}

export default ContactCard