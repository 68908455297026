export const GET_USEFULL_LINKS = `
query GetUsefulLinks {
  homeUsefulLinksList(filter:{
		 isactive:{
      _expressions:{
        value: true
      }
    }
  }) {
    items {
      description {
        plaintext
        html
      }
      link
      _path
      displayOrder
      title
      isactive
      fieldText
      _variation
			_model{
        title
        _path
      }
    }
  }
}

`

export default {
  GET_USEFULL_LINKS
}

