import React, { useEffect, useState } from "react";
import classes from "./FeedbackModal.module.scss"
import { Modal, Input, Upload, Checkbox  } from 'antd';
import Check from "../../assets/images/feedback/submittedCheck.png";
import StartOutlined from "../../assets/images/feedback/starOutlined.png"
import StarFilled from "../../assets/images/feedback/starFilled.png"
import CloseModal from "../../assets/images/feedback/closeModal.png"

const { TextArea } = Input;

function FeedbackModal( props ) {
  const { feedbackModal, setFeedbackModal } = props;
  const improveFeedback=["Suggestion", "Feedback", "Bug"];
  const [canBeImprove, setCanBeImprove]=useState("Suggestion");
  const [allowUploadFile, setAllowUploadFile]=useState(false);
  const [suggestion, setSuggestion] = useState("");
  const [file, setFile] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const emojiText=[1,2,3,4,5];
  const [rating, setRating] =useState(-1);
  const onRemove= (file) => {
      setFile();
    }
  const beforeUpload=(file) => {
    const fileSize = file.size / 1024; //size <=800kb
    if(fileSize>800)
      return true;

    setFile(file)
    return false;
  };
  const disableSubmit = rating === -1 || canBeImprove.length===0 || suggestion.length === 0;

  useEffect(()=>{
    clearData();
  },[feedbackModal])

  const clearData=()=>{
    setRating(-1);
    setCanBeImprove("Suggestion");
    setSuggestion("");
    setIsSubmitted(false);
    setFile();
    setAllowUploadFile(false);
  };

  return (
    <Modal open={feedbackModal} style={{padding:'24px'}} okText="Submit Feedback" width={800} footer={null} onCancel={()=>setFeedbackModal(false)} closable={!isSubmitted} closeIcon={<img src={CloseModal} alt="close"/>}>
        {!isSubmitted ?<div className={classes.rightContainer}>
          <div className={classes.title}>Provide your feedback for DigiPrint</div>
          <div className={classes.feedbackRating}>How satisfied are you with the tool?<span style={{color:'red'}}> *</span></div>
            {
              emojiText.map((emoji,index)=>
                <img key={emoji} src={index+1>rating? StartOutlined :StarFilled} alt="emoji" onClick={()=>rating-1===index?setRating(-1):setRating(index+1)} className={classes.emoji}/>)
            }
          <div className={classes.feedbackImprovedText}>Tell us more about your experience.<span style={{color:'red'}}> *</span></div>
          {
            improveFeedback.map(feedback=>
                <div onClick={()=>setCanBeImprove(feedback)} className={feedback===canBeImprove? classes.selectedImprove : classes.improve}key={feedback}>{feedback}</div>
            )
          }
          <TextArea  placeholder={'Tell us what you like..'} className={classes.suggestion} 
          value={suggestion} onChange={(e)=>setSuggestion(e.target.value)} />
          <div><Checkbox className={classes.check} checked={allowUploadFile} onChange={()=>setAllowUploadFile(!allowUploadFile)}/><span className={classes.checkboxText}>Upload Files (optional)</span></div>
          <div className={classes.uploadFiles}>Upload Files</div>
          <div className={classes.format}>JPG, PDF or PNG. Max size of 800K</div>
          <Upload accept=".png,.jpeg,.pdf" maxCount={1} onRemove={onRemove} beforeUpload={beforeUpload} disabled={!allowUploadFile}>
            <button  className={allowUploadFile? classes.uploadButton: classes.disabledUploadButton} disabled={!allowUploadFile}>Choose File</button>
          </Upload>
          <div className={classes.footerContainer}>
            <button onClick={()=>{clearData(); setFeedbackModal(false) }} className={classes.cancelBtn}>Cancel</button>
            <button className={ disableSubmit? classes.disabledSubmitButton : classes.submitBtn} 
            disabled={disableSubmit} onClick={()=>setIsSubmitted(true)}>Submit Feedback</button>
          </div>
        </div>
        :<div className={classes.rightContainerSubmitted}>
            <img src={Check} className={classes.submittedCheck} alt="Thanks"/>
            <h3 className={classes.thanks}>Thank You!</h3>
            <div className={classes.text}>By making your voice heard, you help us improve Digiprint</div>
            <button className={classes.okBtn} onClick={()=>{clearData();setFeedbackModal(false);}}>Ok</button>
        </div>
        }
    </Modal>
  );
}

export default FeedbackModal;
