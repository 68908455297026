import React from "react";
import "./Footer.scss";
import footer from "../../../assets/images/homePage/footer.png";
import { footer_data } from "../../../constants/commonConstants";
import { useSelector } from "react-redux";
import { actionTracking } from "../../../utils/omniture";
import { pageNames } from "../../../constants/omnitureConstants";

function Footer() {
  const navReducer = useSelector((state) => ({
    email: state.navigationReducer.userEmail,
  }));

  return (
    <>
      <div className="digiprint-footer">
        <div className="row">
          <div className="col-lg-7 footer-div1">
            <div className="row footer-logo-section">
              <div className="col-lg-2"></div>
              <div className="col-lg-11">
                <img src={footer} alt="" className="footer-logo" />
                <p className="footer-paragraph">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: footer_data.description.html,
                    }}
                  ></span>
                  <br></br>
                  <br></br>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: footer_data.description1.html,
                    }}
                  ></span>
                </p>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
          <div className="col-lg-5 footer-div3">
            <div className="row">
              <div className="col-lg-4 footer_links_horizontal">
                <p className="footer-content-section1">{footer_data.linksTitle}</p>
                <div className="footer-content-section2">
                  <a
                    onClick={() => {
                      navReducer.email !== "" &&
                        actionTracking(
                          pageNames.HOME_PAGE,
                          navReducer.email,
                          `Footer: ${footer_data.termsofuse}`
                        );
                    }}
                    target="_blank"
                    href="https://www2.deloitte.com/in/en/footerlinks1/legal.html?icid=bottom_legal"
                    className="terms-of-use"
                  >
                    {footer_data.termsofuse}
                  </a>
                </div>
                <div className="footer-content-section2">
                  <a
                    onClick={() => {
                      navReducer.email !== "" &&
                        actionTracking(
                          pageNames.HOME_PAGE,
                          navReducer.email,
                          `Footer: ${footer_data.privacy}`
                        );
                    }}
                    target="_blank"
                    href="https://www2.deloitte.com/in/en/footerlinks1/privacy.html?icid=bottom_privacy"
                    className="terms-of-use"
                  >
                    {footer_data.privacy}
                  </a>
                </div>
                <div className="footer-content-section2">
                  <button
                  style={{textDecoration: 'underline'}}
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings terms-of-use cookie-setting-btn"
                    onClick={() => {
                      navReducer.email !== "" &&
                        actionTracking(
                          pageNames.HOME_PAGE,
                          navReducer.email,
                          `Footer: View Cookies`,
                          "Click"
                        );
                    }}
                  >
                    Cookie Settings
                  </button>
                </div>
                <div className="footer-content-section2">
                  <a
                    onClick={() => {
                      navReducer.email !== "" &&
                        actionTracking(
                          pageNames.HOME_PAGE,
                          navReducer.email,
                          `Footer: ${footer_data.cookienotice}`
                        );
                    }}
                    target="_blank"
                    href="https://cookienotice.deloitte.com"
                    className="terms-of-use"
                  >
                    {footer_data.cookienotice}
                  </a>
                </div>
              </div>
              <div className="col-lg-3">
                <p className="footer-content-section1">{footer_data.title}</p>
                <p className="footer-content-section2">
                  <a href={"mailto:" + footer_data.emailaddress} className="email_address">
                    {footer_data.emailaddress}
                  </a>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;
