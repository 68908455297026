import React, { useState, useEffect } from 'react'
import { Row, Col } from 'reactstrap'
import Loader from '../../components/loader'
import { contactimage } from '../../constants/images'
import { POST_CALL } from '../../services/httpService'
import "./Capabilities.scss";

function DeloitteAssetsContacts({ accessToken, contacts }) {
    const [contactDetailLoading, setContactDetailLoading] = useState(false)
    const [contactDetailsData, setContactDetailsData] = useState([])

    useEffect(() => {
        GET_Contact_Detail();
    }, [])

    const GET_Contact_Detail = async () => {
        setContactDetailLoading(true)
        try {
            let selectedUserEmails = contacts;
            if (selectedUserEmails && selectedUserEmails.length > 0) {
                let url = '/api/user/details'
                let responseData = await POST_CALL(url, selectedUserEmails, { accessToken: accessToken });
                setContactDetailsData(responseData.data)
            }

            setContactDetailLoading(false)

        } catch (err) {
            setContactDetailLoading(false)
            console.log('err ', err);
        }
    }
    return (
        <>

            {
                contactDetailLoading && <Loader />
            }

            {
                !contactDetailLoading && contactDetailsData && contactDetailsData.length > 0 && <>
                    <Row>
                        {contactDetailsData.map((contactPerson, index) => {
                            return !contactPerson.email? "" : (
                            <Col lg="6" key={`con ${index}`} className="mb-4">
                                <Row>
                                    <div className="d-inline contact-image">
                                        <img
                                            className="contactsection-image"
                                            src={contactPerson.picture ? 'data:image/jpeg;base64,' + contactPerson.picture : contactimage}
                                            alt=""
                                        />
                                    </div>
                                    <div className="d-inline contact-details">
                                        <div className="contact-name">
                                            {contactPerson.fullName}
                                        </div>
                                        <div className="contact-designation">
                                            {contactPerson.jobTitle}
                                        </div>
                                        <div className="contact-email">
                                            {contactPerson.email}
                                        </div>
                                    </div>
                                </Row>
                            </Col>
                        )})}
                    </Row>
                </>
            }
        </>
    )
}

export default DeloitteAssetsContacts