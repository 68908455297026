import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Input,
  Alert,
  NavItem,
  NavLink,
  Nav,
  Spinner,
} from "reactstrap";
import "./Adminsettings.scss";
import { POST_CALL, GET_CALL } from "../../../services/httpService";
import {
  admin_navbar_menu,
  file_mime_types,
} from "../../../constants/commonConstants";
import { useDispatch, useSelector } from "react-redux";
import { setAdminMenu } from "../../../store/actions/adminAction";
import { useNavigate } from "react-router-dom";
import { actionTracking, pageView } from "../../../utils/omniture";
import { pageNames } from "../../../constants/omnitureConstants";

function Adminsettings(props) {
  const inputFileRef = useRef(null);
  const [selectedUploadFile, setSelectedUploadFile] = useState("");
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [errors, setErrors] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [downloadSuccessMsg, setDownloadSuccessMsg] = useState("");
  const [httpError, setHttpError] = useState([]);

  const navId = document.getElementById('navbar');
  navId.classList.remove('navbarTransparent')
  navId.classList.add('navbar');

  const dispatch = useDispatch();
  const adminReducer = useSelector((state) => ({
    item: state.adminReducer,
  }));
  const navReducer = useSelector((state) => ({
    item: state.navigationReducer,
  }));
  const navigate = useNavigate();

  useEffect(() => {
    if (adminReducer.item.menu === admin_navbar_menu.REDIS_CACHE)
      dispatch(setAdminMenu(admin_navbar_menu.MASTER_DATA));
  }, []);

  useEffect(() => {
    if (!navReducer.item.isAdmin) {
      navigate("/errorpage/?e=401");
    }
  }, [navReducer.item]);

  useEffect(() => {
    setTimeout(() => {
      setSuccessMsg("");
    }, 6000);
  }, [successMsg]);

  useEffect(() => {
    setTimeout(() => {
      setErrors("");
    }, 6000);
  }, [errors]);

  useEffect(() => {
    setTimeout(() => {
      setDownloadSuccessMsg("");
    }, 6000);
  }, [downloadSuccessMsg]);

  useEffect(() => {
    if (navReducer.item.userEmail !== "") {
      pageView(pageNames.ADMIN_SETTINGS, navReducer.item.userEmail);
    }
  }, [navReducer.item.userEmail]);

  const updateSelectedType = (type) => {
    if (navReducer.item.userEmail !== "") {
      actionTracking(pageNames.ADMIN_SETTINGS,navReducer.item.userEmail,type)
    }
    if (type !== adminReducer.item.menu) {
      setSelectedUploadFile("");
      if (inputFileRef && inputFileRef.current) {
        inputFileRef.current.value = null;
      }
      setDownloadSuccessMsg("");
      setErrors("");
      setSuccessMsg("");
      setHttpError([]);
      dispatch(setAdminMenu(type));
    }
  };

  const downloadFile = async () => {
    if (navReducer.item.userEmail !== "") {
      actionTracking(pageNames.ADMIN_SETTINGS,navReducer.item.userEmail,`Download: ${adminReducer.item.menu}`,"Click")
    }
    setDownloading(true);
    let fileUlr =
      adminReducer.item.menu === admin_navbar_menu.MASTER_DATA
        ? "/api/excel/download/master/template"
        : "/api/excel/download/storefront/template";
    try {
      let response = await GET_CALL(
        fileUlr,
        {
          accessToken: props.accessToken,
        },
        {
          responseType: "blob",
        }
      );
      await parseAndDownload(response);
      if (response.status === 200) {
        setErrors("");
        adminReducer.item.menu === admin_navbar_menu.MASTER_DATA
          ? setDownloadSuccessMsg("Master file downloaded successfully")
          : setDownloadSuccessMsg("Store Front file downloaded successfully");
      }
      setDownloading(false);
    } catch (err) {
      setDownloading(false);
      console.log("err ", err);
    }
  };

  const parseAndDownload = async (response) => {
    let fileName = "DigiPrint_Template.xlsx";
    const contentDisposition = response.headers["content-disposition"];
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    setDownloadSuccessMsg("");
  };

  const validateFile = (file) => {
    // Empty file validation
    if (file === "") {
      setErrors("File Required: Please select a file");
      return false;
    }

    // File extension validation
    const fileType = file.type;
    const allowedFiles = window.CONFIG.FILEIMPORT.supported_formats.split(",");
    let fileTypeFound = "";
    for (let key in file_mime_types) {
      if (file_mime_types[key] === fileType) {
        fileTypeFound = key;
      }
    }

    if (fileTypeFound) {
      fileTypeFound = allowedFiles.find(
        (str) => str.toLowerCase() == fileTypeFound
      );
    }

    if (fileTypeFound === "" || fileTypeFound === undefined) {
      setErrors("File Rejected: File format is not supported");
      return false;
    }

    // File size validation
    let fileSize = file.size / (1024 * 1024);
    if (fileSize > window.CONFIG.FILEIMPORT.allowed_size_mb) {
      setErrors(
        `File Rejected: File size cannot be greater than ${window.CONFIG.FILEIMPORT.max_size}MB`
      );
      return false;
    }

    console.log(" VALID file");
    setErrors("");
    return true;
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    if (navReducer.item.userEmail !== "") {
      actionTracking(pageNames.ADMIN_SETTINGS,navReducer.item.userEmail,`Upload File: ${adminReducer.item.menu}`,"Click")
    }

    try {
      if (validateFile(selectedUploadFile) === true) {
        setUploading(true);

        const formData = new FormData();

        formData.append("file", selectedUploadFile);
        if (props.accessToken) {
          let fileUrl =
            adminReducer.item.menu === admin_navbar_menu.MASTER_DATA
              ? "/api/excel/upload/masterdata"
              : "/api/excel/upload/storefront";
          let data = await POST_CALL(fileUrl, formData, {
            accessToken: props.accessToken,
          });
          if (data.status === 200) {
            let res = data?.data;
            if (res.hasError === true) {
              setHttpError(res.errors);
              inputFileRef.current.value = null;
              setSelectedUploadFile("");
            } else {
              setErrors("");
              setHttpError([]);
              adminReducer.item.menu === admin_navbar_menu.MASTER_DATA
                ? setSuccessMsg("Master file uploaded successfully")
                : setSuccessMsg("Store Front file uploaded successfully");
            }
          }
        }
      } else {
        setHttpError([]);
      }
      setUploading(false);
    } catch (err) {
      setUploading(false);
      setSuccessMsg("");
      setErrors("Something Went wrong");
      console.log("err ", err);
    }
  };

  return (
    <>
      <div className="admin-layout">
        <Row>
          <Col lg="2" className="admin_navbar_left_color">
            <div className="admin_navbar_left">
              <Nav pills vertical>
                <NavItem
                  onClick={() => {
                    updateSelectedType(admin_navbar_menu.MASTER_DATA);
                  }}
                >
                  <NavLink
                    className="ps-5"
                    active={
                      adminReducer.item.menu ===
                      admin_navbar_menu.MASTER_DATA ?? true
                    }
                    href="#"
                  >
                    Master Data
                  </NavLink>
                </NavItem>
                <NavItem
                  onClick={() => {
                    updateSelectedType(admin_navbar_menu.STOREFRONT_DATA);
                  }}
                >
                  <NavLink
                    className="ps-5"
                    active={
                      adminReducer.item.menu ===
                      admin_navbar_menu.STOREFRONT_DATA ?? true
                    }
                    href="#"
                  >
                    Storefront Data
                  </NavLink>
                </NavItem>
                <NavItem
                  onClick={() => {
                    updateSelectedType(admin_navbar_menu.REDIS_CACHE);
                    navigate("/rediscache");
                  }}
                >
                  <NavLink
                    className="ps-5"
                    active={
                      adminReducer.item.menu ===
                      admin_navbar_menu.REDIS_CACHE ?? true
                    }
                    href="#"
                  >
                    Redis Cache
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
          <Col lg="10">
            {adminReducer.item.menu !== admin_navbar_menu.REDIS_CACHE && (
              <>
                <div className="admin_heading_data">
                  <Row>
                    <div>
                      <span>
                        {adminReducer.item.menu} Template Download & Upload
                        Process
                      </span>
                    </div>
                  </Row>
                </div>
                <div className="admin_navbar_right">
                  <Row>
                    <Col lg="5">
                      <span className="text-label">Download:</span>
                      <div>
                        {downloadSuccessMsg !== "" && (
                          <Alert color="success" className="error-alert">
                            {downloadSuccessMsg}
                          </Alert>
                        )}
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div
                        className="adminreport-download-btn"
                        id="download-btn"
                        onClick={() => downloadFile()}
                      >
                        {downloading ? (
                          <Spinner
                            color="light"
                            className="d-block m-auto text-center"
                            size="sm"
                          />
                        ) : (
                          <div className="storefront-download-option">
                            <div className="d-inline">
                              <i
                                className="fa fa-download"
                                style={{ fontSize: "15px", color: "white" }}
                              ></i>
                            </div>
                            <div className="d-inline adminsetting-download-option-text">
                              Download
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-5">
                    <p className="text-label">Upload:</p>
                  </div>

                  <Row>
                    <Col lg="5">
                      <Input
                        innerRef={inputFileRef}
                        style={{ width: "auto", marginLeft: "20px" }}
                        type="file"
                        onChange={(e) => {
                          setErrors("");
                          setSelectedUploadFile(e.target.files[0]);
                        }}
                      />
                    </Col>

                    <Col lg="4" className="uploadButton">
                      <div
                        className="adminreport-download-btn"
                        id="download-btn"
                        onClick={(e) => uploadFile(e)}
                      >
                        {uploading ? (
                          <Spinner
                            color="light"
                            size="sm"
                            className="d-block m-auto text-center"
                          />
                        ) : (
                          <div className="storefront-download-option">
                            <div className="d-inline">
                              <i
                                className="fa fa-upload"
                                style={{ fontSize: "15px", color: "white" }}
                              ></i>
                            </div>
                            <div className="d-inline adminsetting-download-option-text">
                              Upload
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      {httpError.length > 0 && (
                        <div className="pt-5 ps-3 error-fonts">
                          <h3 className="mb-3">
                            File could not be processed due to following
                            error(s)
                          </h3>
                          {httpError.map((err, index) => (
                            <p key={index}>{err.message}</p>
                          ))}
                        </div>
                      )}
                      {successMsg !== "" && (
                        <Alert color="success" className="error-alert">
                          {successMsg}
                        </Alert>
                      )}

                      {errors !== "" && (
                        <Alert color="danger" className="error-alert">
                          {errors}
                        </Alert>
                      )}
                    </div>
                  </Row>
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Adminsettings;
