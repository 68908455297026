import React, { useState } from "react";
import { errorLogs } from "../../constants/commonConstants";
import "./ErrorLogs.scss";
import {
  Badge,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function ErrorLogs() {
  const [logs, setLogs] = useState(errorLogs);
  const [page, setPage] = useState(1);
  const [currentLog, setCurrentLog] = useState({});
  const [toggle, setToggle] = useState(false);

  return (
    <div className="container-fluid logs-layout text-center">
      <Table hover bordered responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Logged On</th>
            <th>Logged By</th>
            <th>Log Type</th>
          </tr>
        </thead>
        <tbody>
          {logs.length > 0 &&
            logs.map((item, index) => (
              <tr
                onClick={() => {
                  setToggle(!toggle);
                  setCurrentLog(item);
                }}
                className="log-tr"
                key={index}
              >
                <th scope="row">{index + 1}</th>
                <td>{item.loggedOn}</td>
                <td>{item.loggedBy}</td>
                <td>
                  <Badge
                    color={
                      item.logType === "FrontendError" ? "secondary" : "danger"
                    }
                  >
                    {item.logType}
                  </Badge>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      {logs.length > 0 && currentLog && toggle && (
        <Modal
          isOpen={toggle}
          toggle={() => setToggle(!toggle)}
          className="log-modal"
          size="xl"
        >
          <ModalHeader toggle={() => setToggle(!toggle)}>
            {currentLog.logType} - {currentLog.loggedOn}
          </ModalHeader>
          <ModalBody className="modal-body-logs">
            <Row className="mt-2">
              <Col lg="4">
                <FormGroup>
                  <Label for="sender">Sender</Label>
                  <Input
                    readOnly
                    type="text"
                    name="sender"
                    id="sender"
                    placeholder="Log Sender"
                    value={currentLog?.sender}
                  />
                </FormGroup>
              </Col>

              <Col lg="4">
                <FormGroup>
                  <Label for="sender">Log Area</Label>
                  <Input
                    readOnly
                    type="text"
                    name="logArea"
                    id="logArea"
                    placeholder="Log Area"
                    value={currentLog?.logArea}
                  />
                </FormGroup>
              </Col>

              <Col lg="4">
                <FormGroup>
                  <Label for="sender">Logged By</Label>
                  <Input
                    readOnly
                    type="text"
                    name="loggedBy"
                    id="loggedBy"
                    placeholder="Logged By"
                    value={currentLog?.loggedBy}
                  />
                </FormGroup>
              </Col>
              <Col lg="12" className="mt-2">
                <FormGroup>
                  <Label for="logMessage">Log Message</Label>
                  <Input
                    readOnly
                    type="textarea"
                    name="logMessage"
                    id="logMessage"
                    placeholder="Log Message"
                    value={currentLog?.logMessage}
                    className="textarea-log-css"
                  />
                </FormGroup>
              </Col>
              <Col lg="12" className="mt-2">
                <FormGroup>
                  <Label for="logMessage">Log Details</Label>
                  <Input
                    readOnly
                    type="textarea"
                    name="logDetails"
                    id="logDetails"
                    placeholder="Log Details"
                    value={currentLog?.logDetails}
                    className="textarea-log-css"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
}

export default ErrorLogs;
