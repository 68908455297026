import React from "react";
import { Col, Row, Card } from "reactstrap";
import classes from "./CapabilityInfoHeader.module.scss";
import CapabilityBreadcrumb from "../capabilityBreadcrumb/CapabilityBreadcrumb";
import Button from "../button/Button";
import Tag from "../tag";
import { useState, useEffect } from "react";

function CapabilityInfoHeader({ props, accessToken }) {
    const { capabilityDetail, navigateToStoreFront, checkIfSelected, addCapability, removeCapabilityFromList } = props;
    // Todo : Hardcoded the email untill the value is available from api
    const emailList = ["bdoty@deloitte.com"];
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    }, [checkIfSelected]);

    const isSticky = (e) => {
        const header = document.getElementById('addButton');
        window.pageYOffset + 46 > header.offsetTop ? header.classList.add(classes.stickyButton) : header.classList.remove(classes.stickyButton);
    };
    return (
        <>
            <Card className={classes.capabilityHeaderContainer}>
                <div style={{paddingLeft : '30px'}}>
                    <Row>
                        <Col lg="9">
                            {capabilityDetail?.valuechain && <CapabilityBreadcrumb props={{valuechain : capabilityDetail?.valuechain,navigateToStoreFront: navigateToStoreFront}}></CapabilityBreadcrumb>}
                        </Col>
                        <Col style={{justifyContent: "flex-end"}} lg="3" id="addButton"><Button label="DigiPrint" digiprintAdded={checkIfSelected} addCapability= {addCapability} removeCapabilityFromList={removeCapabilityFromList} hasIcon= {true}></Button></Col>
                    </Row>
                    <Tag closable={false} className={classes.valuechainTag} >{capabilityDetail?.valuechain?.valueChainName}</Tag>
                    <Row className={classes.CapabilityNameHeader}><div className={classes.capabilityName}>{capabilityDetail?.capabilityName}</div></Row>
                    <Row className={classes.digiprintCount}>
                        {/* <div className={classes.digiprintViewCount}><BsBookmark /> <span className={classes.viewCountHeading}>included in 1,351 digiprints</span></div>
                        <div className={classes.digiprintViewCount}><AiOutlineEye /> <span className={classes.viewCountHeading}>4,192  recent  views</span></div> */}
                        {/* <div className={classes.lastUpdated}>Last Updated: january 13 2023</div> */}
                    </Row>
                    {/* <Row className={classes.contact}><DeloitteAssetsContacts accessToken={accessToken} contacts={emailList} /></Row> to be included later*/}
                </div>
            </Card>
        </>)

}

export default CapabilityInfoHeader;