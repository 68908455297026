import React from "react";
import classes from "./DescriptiveCards.module.scss";
import Tag from "../../../../components/tag";
import ErrorBoundary from "../../../../components/error-boundary/ErrorBoundary";
import { LinksArrow } from "../../../../constants/images";
import { faL } from "@fortawesome/free-solid-svg-icons";

function DescriptiveCards(props) {
    const { cardDescription, cardTag, cardTitle, arrowIcon, link, redirectToLink, isActive, setCustomHeight = false } = props;
    const titleHeight = setCustomHeight ? '90px' : '64px';
    const descriptionHeight = setCustomHeight ? '160px' : '96px'
    return (
        <ErrorBoundary>
            {isActive && <div className={classes.descriptiveCardContainer} style={props.style} onClick={() => redirectToLink(link)}>
                <div style={{ width: "100%" }}>
                    <div style={{ float: "left" }}>{cardTag && <Tag closable={false} className={classes.cardTag} >{cardTag}</Tag>}</div>
                    <div style={{ float: "right" }}>{arrowIcon && <img
                        src={LinksArrow}
                        alt=""
                    />}</div>
                </div>
                <div style={{ marginTop: 80 }}>
                    {cardTitle && <p className={classes.cardTitle} style={{height: titleHeight}}>{cardTitle}</p>}
                </div>
                <div>
                    {cardDescription && <p className={classes.cardDescription} style={{height: descriptionHeight}}> {cardDescription}</p>}
                </div>

                <></>
            </div>}
        </ErrorBoundary>

    )

}

export default DescriptiveCards;