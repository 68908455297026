export const GET_ENABLERS_LIST = `
{
  technologyEnablementList(filter: {isActive: {_expressions: {value: true}}}) {
    items {
      name
      isActive
      displayOrder
    }
    
  }
  maturityLevelList(
    filter: {isActive: {_expressions: {value: true}}, isDeleted: {_expressions: {value: false}}}
  ) {
    items {
      name
      isActive
      displayOrder
    }
  }
 
  
  ecosystemAccelerationList(
    filter: {isActive: {_expressions: {value: true}}, isDeleted: {_expressions: {value: false}}}
  ) {
    items {
      name
      isActive
      displayOrder
    }
  }
  businessValueList(
    filter: {isActive: {_expressions: {value: true}}, isDeleted: {_expressions: {value: false}}}
  ) {
    items {
      name
      isActive
      displayOrder
    }
  }
}

`


export const GET_CAMPAIGN_BY_INDUSTRY = `
query GetCampaign($industryName: String!) {
  deloitteCampaignsList(
    filter: {industry: {_match: {industryName: {_expressions: [{value: $industryName}]}}}, isActive: {_expressions: {value: true}}, isDeleted: {_expressions: {value: false}}}
  ) {
    items {
      name
      isActive
      industry {
        industryName
      }
      displayOrder
    }
  }
}

`


export default {
  GET_ENABLERS_LIST,
  GET_CAMPAIGN_BY_INDUSTRY
}