import React from 'react'
import { Checkbox } from 'antd';

function CheckboxGroup(props) {

    const onChange = (e) => {
        if (e.target.checked) {
            props.onChange(e.target.value, "add", props.nameOfProperty)
        }
        else {
            props.onChange(e.target.value, "delete", props.nameOfProperty)
        }
    }

    return (
            <>
                <Checkbox.Group value={props.options}>
                    <Checkbox style={{fontFamily: 'Open Sans'}} data-testid="checkbox-grp" value={props.name} onChange={onChange}>
                        {props.name}
                    </Checkbox>
                </Checkbox.Group>
            </>
    )
}

export default CheckboxGroup