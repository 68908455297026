import {
  SET_ACTIVE_NAVIGATION,
  SET_LOGGED_USER_DATA,
  SET_IS_ADMIN,
  SET_USER_EMAIL,
} from "../types";
const initialState = {
  menu: "",
  loggedUserData: null,
  isAdmin: true,
  userEmail: "",
};

const navigationReducer = (state = initialState, action) => {
  const { payload, type } = action;
  switch (type) {
    case SET_ACTIVE_NAVIGATION:
      return {
        ...state,
        menu: payload,
      };
    case SET_LOGGED_USER_DATA:
      return {
        ...state,
        loggedUserData: payload,
      };
    case SET_IS_ADMIN:
      return {
        ...state,
        isAdmin: payload,
      };
    case SET_USER_EMAIL:
      return {
        ...state,
        userEmail: payload,
      };
    default:
      return state;
  }
};

export default navigationReducer;
