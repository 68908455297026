import React from "react";
import { Card, CardText, CardTitle } from "reactstrap";
import { Learnmore1Image } from "../../constants/images";
import "./Card.scss";

function CardComponent({
  props,
  hasLink = null,
  linkText = null,
  hasLogo = null,
  hasFooter = null,
  isActive=true
}) {
  return (
    <Card body className={`card-layout ${!isActive && 'inactive-card'}`}>
      {hasLogo && (
        <img src={props.logo} alt="" className="card-logo-css" />
      )}
      {(props.index && 
      <CardTitle className="card-title">{props.index}. {props.title}</CardTitle>
      )}
       {(!props.index && 
      <CardTitle className="card-title">{props.title}</CardTitle>
       )}
      <CardText
        className="card-text"
        dangerouslySetInnerHTML={{ __html: props.text }}
      ></CardText>
      {hasLink && (
        <p className="link-text">
          {linkText}{" "}
          <img className="d-inline link-arrow" src={Learnmore1Image} alt="" />
        </p>
      )}
      {hasFooter && <p className="card-comp-footer">{props.footer}</p>}
    </Card>
  );
}

export default CardComponent;
