import React from 'react'
import Footer from './footer/Footer'
import Navbar from './navigation/Navbar/Navbar';
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import './__theme.scss'
import { Container } from 'reactstrap';

const AdminLayout = (props) => {
    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            <Navbar />
            <Container className="container-custom" fluid>
                {props.children}
            </Container >
            <Footer />
        </MsalAuthenticationTemplate>
    )
}

export default AdminLayout