import React from "react";
import classes from "./ReadyToGetStarted.module.scss";

const ReadyToGetStarted = (props) => {
    const { readyTogetStartedInfo, introGif, loading, title, description } = props;
    return (<div className={classes.readyTogetStartedContainer}>
        <div className={classes.titleSectionContainer}>
            <div>
                <p className={classes.readyTogetStartedTitle}>{title}</p>
            </div>
            <div>
                <p className={classes.readyTogetStartedDescription}>{description}</p>
            </div>
            <video  autoPlay={true} muted playsInline width={604} height={392.6} src={introGif} type="video/mp4" loop/>
        </div>
        <hr></hr>
        <div className={classes.ReadyToGetStartedStepsContainer}>
            {readyTogetStartedInfo && readyTogetStartedInfo.map((Info, index) =>
                Info.isactive  && <div className={classes.stepsContainer}>
                    <p className={classes.pointNumber}>{index + 1}</p>
                    <div style={{ display: "flex", flexDirection: "column", marginLeft: 40 }}>
                        <p className={classes.stepTitle}>{Info.heading}</p>
                        <p className={classes.stepDescription}>{Info.description.plaintext}</p>
                    </div>
                </div>

            )}
        </div>
    </div>)
}

export default ReadyToGetStarted