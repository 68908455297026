import React from 'react';
import { Row, Col } from 'reactstrap';
import logo from "../../assets/images/common/blackLogo.png";
const CoverPageTemplate = (props) => {
  return (
    <div id={`capability-template-01`} style={{color: 'black', backgroundColor: 'white', position:'absolute', top:'-99999px', left:'-99999px'   }}>
       <Row style={{padding:'0px', margin: '0px'}} >
        <Col md="5" lg="5">
            <div style={{ fontSize: '32px', color:'black !important', fontWeight: '600', fontFamily: 'Open Sans', padding: '60px 60px 10px 20px' }}>{props.subsectorData?.subsectorName}</div>
            <div style={{paddingTop:'590px', paddingLeft:'20px'}}>
              <img src={logo} alt="logo" style={{width:'85px', height:'16px', color:'black !important'}} />
            </div>
        </Col>
        <Col md="7" lg="7" style={{ height: '100%', width: '50%'}}>
        {<img src={props?.subsectorData?.exportImage?._publishUrl} alt={'coverImage'}   style={{backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
     width:'570px', height:'832px'}}/>}
        </Col>
      </Row>
    </div>
  )
}

export default CoverPageTemplate;