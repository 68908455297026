import React, { useState } from "react";
import classes from "./DeloitteAssetCard.module.scss"
import Tag from "../tag";
import { Row, Col } from  'reactstrap';
import { isGuidValid } from "../../utils/utilities";
import trailingIcon from "../../assets/images/common/Trailing Icon.png";
import { Button } from 'antd';
import DeloitteAssetsContacts from "../../views/capabilities/DeloitteAssetsContacts";
import SalesCollaterals from "../SalesCollaterals/SalesCollaterals.Component";

function DeloitteAssetCard( props ) {
    const { deloitteAssets, userDataReducers } = props || [];
    const [expanded, setExpanded] = useState(new Array(deloitteAssets?.length).fill(false));
    const MAX_POSSIBLE_HEIGHT = 5000;

    const capabilityMaturity={ 
        'market ready' : {
            background:'#DDEFE8',
            color: '#0D8390'
        },
        'poc or initial investment':{
            background:'#E3E48D',
            color:'#2C5234'
        },
        'whitespace':{
            background:'#A0DCFF',
            color:'#007CB0'
        }
    }

    const findFileType = (filename) => {
        const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length);
        return extension;
      }

    const borderBackground={ 
        'market ready' : {
            borderTop:'8px solid #DDEFE8',
        },
        'poc or initial investment':{
            borderTop:'8px solid #E3E48D',
        },
        'whitespace':{
            borderTop:'8px solid #A0DCFF',
        }
    }

    const MAI_BASE_LINK = window.CONFIG && window.CONFIG.DAP.domain_url ? window.CONFIG.DAP.domain_url + window.CONFIG.DAP.asset_url : null
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    
    return (
    <div className={classes.container}>
    {
        deloitteAssets.map((deloitteAsset,index)=>{
            let collaterals = []
            if(deloitteAsset.externalOnePager.plaintext !== null) {
                collaterals.push({
                title: "One Pager (External)",
                url: deloitteAsset.externalOnePager.html,
                fileType: findFileType(deloitteAsset.externalOnePager.plaintext)
              })
            }

            if(deloitteAsset.externalSalesDeck.html !== null) {
                collaterals.push({
                title: "Sales Deck (External)",
                url: deloitteAsset.externalSalesDeck.html,
                fileType: findFileType(deloitteAsset.externalSalesDeck.plaintext)
              })
            }
  
            if(deloitteAsset.externalDemoVideo.html !== null) {
                collaterals.push({
                title: "Demo Video (External)",
                url: deloitteAsset.externalDemoVideo.html,
                fileType: 'video'
              })
            }

              
            if(deloitteAsset.internalTearSheets.html !== null) {
                collaterals.push({
                title: "Tear Sheets (Internal)",
                url: deloitteAsset.internalTearSheets.html,
                fileType: findFileType(deloitteAsset.internalTearSheets.plaintext)
              })
            }

            if(deloitteAsset.internalOverviewDeck.plaintext !== null) {
                collaterals.push({
                title: "Overview Deck (Internal)",
                url: deloitteAsset.internalOverviewDeck.html,
                fileType: findFileType(deloitteAsset.internalOverviewDeck.plaintext)
              })
            }
  
            if(deloitteAsset?.internalDemoVideo?.html !=null) {
                collaterals.push({
                title: "Demo Video (Internal)",
                url: deloitteAsset.internalDemoVideo.html,
                fileType: 'video'
              })
            }
  
            let additionalLinksExternal = []
            if(deloitteAsset?.additionalLinksExternal?.html) {
                additionalLinksExternal=deloitteAsset.additionalLinksExternal.html.split('\n');
                additionalLinksExternal=additionalLinksExternal.slice(0,additionalLinksExternal.length-1);

                additionalLinksExternal=additionalLinksExternal.map(link=>{
                    link=link.replace('<a',`<a target='_blank'`);
                    return link;
                });
            }

  
            let additionalLinksInternal = []
            if(deloitteAsset?.assetLinks?.plaintext) {
                additionalLinksInternal=deloitteAsset.assetLinks.plaintext.split('\n');
                additionalLinksInternal=additionalLinksInternal.slice(0,additionalLinksInternal.length-1);
                additionalLinksInternal=additionalLinksInternal.map(link=>{
                    return link;
                })
            }

            const modifiedDate=new Date(deloitteAsset?.modifiedDateTime);
            return <Row className={ classes.cardContainer } style={ borderBackground[deloitteAsset?.deloitteAssetPortfolio?.name.toLowerCase() || 'Market Ready'] }>
                <Col className={ `col col-md-3 col-lg-3 ${classes.leftSectionContainer}` }>
                {deloitteAsset?.deloitteAssetPortfolio?.name && <Tag style={ capabilityMaturity[deloitteAsset.deloitteAssetPortfolio.name.toLowerCase()] }>{ deloitteAsset.deloitteAssetPortfolio.name === "POC Or Initial Investment"  ? "POC or Initial Investment" : deloitteAsset.deloitteAssetPortfolio.name} </Tag>}
                    <div className={ classes.name }>{ deloitteAsset.assetName } </div>
                    {deloitteAsset?.modifiedDateTime!== null && <div className={classes.date}>Last Updated: {
                        `${months[modifiedDate.getMonth()]} ${modifiedDate.getDay()}, ${modifiedDate.getFullYear()}`}</div>}
                    {
                        deloitteAsset.assetSystemId && isGuidValid(deloitteAsset.assetSystemId) && 
                        <div className={ classes.assetInventory }>Deloitte's Master Asset Inventory </div>
                    }
                    {
                        deloitteAsset.assetSystemId && isGuidValid(deloitteAsset.assetSystemId) &&
                        <a href={`${MAI_BASE_LINK}${deloitteAsset.assetSystemId}`} target="_blank" rel="noreferrer" >
                            <button className={classes.inventoryButton}>
                                Launch <div className={classes.arrow}>
                                    <img src={ trailingIcon } alt=""/>
                                </div>
                            </button>
                        </a>
                    }
                   
                </Col>
                <Col className={ `col col-md-9 col-lg-9 ${classes.rightSectionContainer}`} style={{ padding:'0px !important'}}>
                    
                    <div className={expanded[index] ? 'h-auto' : 'h-12'} id={`deloitte-asset-desc-${index}`}
                        style={{ overflow: 'hidden', maxHeight: expanded[index] ? MAX_POSSIBLE_HEIGHT : 140, fontSize:'16px' }}
                    >
                        <div dangerouslySetInnerHTML={{ __html:  deloitteAsset?.assetDescription?.plaintext }} />
                    </div >
                    <div>
                        {
                        deloitteAsset?.assetDescription?.plaintext?.length>700 && <Button id={`read-more-${index}`} className={classes.readMore} onClick={() =>   setExpanded(expanded.map((el,index2)=>{return index === index2 ? !el : el}))}>{expanded[index] ? 'Read Less':  'Read More'}</Button>   
                        }
                    </div>
                    <Row>
                        <Col className={ `col col-md-6 col-lg-6`} >
                            {collaterals?.length>0 && <div className={classes.collateral}>Collateral</div>}
                                <SalesCollaterals collaterals={collaterals} isDeloitteAssetsPage={true} />
                        </Col>
                        <Col className={ `col col-md-6 col-lg-6`} >
                            {(additionalLinksExternal?.length>0 || additionalLinksInternal?.length>0) && <div className={classes.contacts}>Additional Links</div>}
                            {
                                additionalLinksExternal?.map(item=>
                                    <div key={item}>
                                        <div style={{display:'inline-block', cursor:'pointer'}} dangerouslySetInnerHTML={{ __html:  item}}></div>
                                    </div>
                                )
                            }
                            {
                                additionalLinksInternal?.map(item=>
                                    <div key={item}>
                                        <div style={{display:'inline-block', cursor:'pointer'}} dangerouslySetInnerHTML={{ __html:  item}}></div> 
                                    </div>
                                )
                            }
                        </Col>
                    </Row>

                    {deloitteAsset?.assetTeamEmailList?.length>0 && <div className={classes.contacts}>Contacts</div>}
                    <DeloitteAssetsContacts  accessToken={userDataReducers.loggedUserData.accessToken} contacts={deloitteAsset.assetTeamEmailList}/>
                </Col>
            </Row>
        })
    }

    </div>
  );
}

export default DeloitteAssetCard;
