export const pageView = (pageName, email) => {
  let userID = email.split("@deloitte.com");
  userID = userID.length > 0 ? userID[0] : email;
  const obj = {
    pageInfo: {
      pageName,
      userID,
      applicationName: "DigiPrint",
    },
  };
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.charset = "UTF-8";
  script.innerHTML += `analyticsDataLayer=${JSON.stringify(obj)};`;
  script.innerHTML += `_satellite.track("pageView");`;
  document.head.appendChild(script);
  document.head.removeChild(script);
};

export const actionTracking = (pageName, email, actionName, actionType="Navigation") => {
  let userID = email.split("@deloitte.com");
  userID = userID.length > 0 ? userID[0] : email;
  const obj = {
    pageInfo: {
      pageName,
      userID,
      applicationName: "DigiPrint",
      actionName,
      actionType,
    },
  };
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.charset = "UTF-8";
  script.innerHTML += `analyticsDataLayer=${JSON.stringify(obj)};`;
  script.innerHTML += `_satellite.track("action");`;
  document.head.appendChild(script);
  document.head.removeChild(script);
};
