export const SEARCH_BY_KEY = `
query getSearchResult($searchKey: String!) {
    capabilityList(
      filter: {_logOp: OR, capabilityName: {_expressions: {value: $searchKey, _operator: CONTAINS, _ignoreCase: true}}, capabilityDescription: {html: {_expressions: {value: $searchKey, _operator: CONTAINS, _ignoreCase: true}}}, technologyEnablement: {_match: {name: {_expressions: {value: $searchKey, _operator: CONTAINS, _ignoreCase: true}}}}, valuechain: {valueChainName: {_expressions: {value: $searchKey, _operator: CONTAINS, _ignoreCase: true}}}, benefitOrValue: {html: {_expressions: {value: $searchKey, _operator: CONTAINS, _ignoreCase: true}}}, deloitteAssets: {_match: {_logOp: OR, assetName: {_expressions: {value: $searchKey, _operator: CONTAINS, _ignoreCase: true}}, assetDescription: {html: {_expressions: {value: $searchKey, _operator: CONTAINS, _ignoreCase: true}}}}}, campaign: {_match: {name: {_expressions: {value: $searchKey, _operator: CONTAINS, _ignoreCase: true}}}}, ecoPartnerSolutions: {_match: {_logOp: OR, title: {_expressions: {value: $searchKey, _operator: CONTAINS, _ignoreCase: true}}}}, businessValue: {_match: {name: {_expressions: {value: $searchKey, _operator: CONTAINS, _ignoreCase: true}}}}, maturityLevel: {name: {_expressions: {value: $searchKey, _operator: CONTAINS, _ignoreCase: true}}}}
    ) {
      items {
        capabilityName
        capabilityDescription {
          html
          plaintext
        }
        benefitOrValue {
          html
        }
        valuechain {
          valueChainName
        }
        technologyEnablement {
          name
        }
        deloitteAssets {
          assetName
          assetDescription {
            html
          }
        }
        benefitOrValue {
          html
        }
        campaign {
          name
        }
        ecoPartnerSolutions {
          title
          ecoSystem {
            name
          }
        }
        businessValue {
          name
        }
        maturityLevel {
          name
        }
      }
    }
  }
  
`

export default {
    SEARCH_BY_KEY
}