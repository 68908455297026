import React, { useState, useEffect } from "react";
import "../../Home.scss";
import { Col, Row } from "reactstrap";
import Loader from "../../../../components/loader";
import ErrorBoundary from "../../../../components/error-boundary/ErrorBoundary";
import ErrorContent from "../../../../components/error-content";
import Button from "../../../../components/button/Button";
import { BannerImagesList } from "../../../../constants/images";
import arrowDownward from './../../../../assets/images/storefront-view/arrow_downward.png';
import { Carousel } from 'antd';

const BannerSection = (props) => {
  const { bannerData, loading, error, scrollToReadyTogetStarted, scrollToDigiprintInfo, navigateToFaqs } = props
  const navId = document.getElementById('navbar');
  navId?.classList?.remove('navbar');
  navId?.classList?.add('navbarTransparent')
  const contentStyle = {
    width: "100%",
    textAlign: "center",
  };

  useEffect(() => {
    window.addEventListener("scroll", isScrolled);
    return () => window.removeEventListener("scroll", isScrolled);
  }, [navId?.classList]);

  const isScrolled = () => {
    navId?.classList?.remove('navbarTransparent');
    navId?.classList?.add('navbar');
    if (window.scrollY === 0) {
      navId?.classList?.remove('navbar');
      navId?.classList?.add('navbarTransparent');
    }
  }

  return (
    <ErrorBoundary>
      {loading && <Loader />}
      {error && (
        <ErrorContent message="There was an error in loading this content." />
      )}
      {!loading && !error && bannerData && Object.keys(bannerData).length > 0 && (
        <div className="home-screen background-image-layout" >
          <Row>
            <Carousel autoplay autoplaySpeed={5000} dots={false} fade={true} style={{ objectFit: 'cover', position: 'relative' }} >
              {BannerImagesList.map(img => (
                <div style={contentStyle}>
                  <img src={img} alt="" style={{ width: '100%', height: 832 }} />
                </div>
              ))}

            </Carousel>
          </Row>
          <div style={{ position: 'absolute', zIndex: 10, top: 0, left: 0, width: '100%' }}>
            <Row style={{ marginTop: 424, marginLeft: 80 }}>
              {bannerData.title && bannerData.title.plaintext && (
                <p
                  className="homepage-title"
                  dangerouslySetInnerHTML={{
                    __html: bannerData.title.plaintext,
                  }}
                ></p>
              )}
            </Row>
            <Row style={{ marginTop: 64, marginLeft: 80, marginBottom: 80 }}>
              <Col lg="10" style={{ display: 'inline-block' }}>
                <span ><Button label="See how it works" digiprintAdded={false} hasIcon={false} actionTobeTake={scrollToReadyTogetStarted} inlineStyles={{ backgroundColor: '0D8390', padding: '10px 25px' }}></Button></span>
                <span style={{ marginLeft: 24 }}><Button label="Learn more" inlineStyles={{ border: '1px solid white', padding: '10px 42px' }} digiprintAdded={false} hasIcon={false} actionTobeTake={navigateToFaqs}></Button></span>
              </Col>
              <Col lg="2">
                <a style={{ color: 'white', fontSize: 80 }} onClick={(e) => { scrollToDigiprintInfo(e) }}><img src={arrowDownward} alt="down-arrow" width="46.67px" height="46.67px" /></a>
              </Col>
            </Row>
            <Row>
            </Row>
          </div>
        </div>
      )}
    </ErrorBoundary>
  );
};

export default BannerSection;
