import axios from "axios";
import { LOG_TYPE } from "../constants/logConstants";
import { logError } from "../services/loggingService";
import { getConfig } from "../utils/config";

export const LoadAuthHeader = async () => {
  let config = await getConfig();
  const base64Cred = btoa(
    `${config.AEM.basic_auth_user}:${config.AEM.basic_auth_user}`
  );

  axios.defaults.headers.common["Authorization"] = `Basic ${base64Cred}`;
  axios.interceptors.response.use(
    (res) => {
      //console.log(res);
      return res;
    },
    (err) => {
      //console.log(err.response);
      const originalReq = err.config;
      let urlString = originalReq.url;

      if (urlString && urlString.includes("/digiprint/data/log")) {
        return Promise.reject(err);
      }

      // Create Payload for calling Log API
      const payload = {
        properties: {
          "cq:model": "/conf/DigiPrint/settings/dam/cfm/models/log",
          title: `${LOG_TYPE.HTTP_ERROR} - ${Date.now()}`,
          description: LOG_TYPE.HTTP_ERROR,
          elements: {
            logType: {
              value: LOG_TYPE.HTTP_ERROR,
            },
            loggedOn: {
              value: Date.now(),
            },
            logArea: {
              value: err?.response?.request?.responseURL,
            },
            sender: {
              value: "DigiPrint HTTP XHR",
            },
            logMessage: {
              value: `
          ${JSON.stringify(err?.response?.status)} \n
          ${JSON.stringify(err?.response?.statusText)}
          `,
            },
            loggedBy: {
              value: process.env.REACT_APP_AEM_USERNAME,
            },
            logDetails: {
              value: `
          HTTP Status Code: ${err?.response?.status} \n
          Headers: ${JSON.stringify(err?.response?.headers)} \n
          Body: ${JSON.stringify(err?.response?.body)} \n
          Error Details: ${err?.response?.data} \n
          `,
            },
          },
        },
      };
      logError(payload);
      return Promise.reject(err);
    }
  );

  return {
    config,
  };
};

export const GET_API = async (url) => {
  return await axios.get(process.env.REACT_APP_ASSETS_ENDPOINT + url);
};

export const POST_API = async (url, payload) => {
  return await axios.post(process.env.REACT_APP_ASSETS_ENDPOINT + url, payload);
};

export const POST_LOGGING_API = async (url, payload) => {
  return await axios.post(url, payload);
};

export const PUT_API = async (url, payload) => {
  return await axios.put(process.env.REACT_APP_ASSETS_ENDPOINT + url, payload);
};

export const DELETE_API = async (url) => {
  return await axios.delete(process.env.REACT_APP_ASSETS_ENDPOINT + url);
};

export const POST_GRAPHQL = async (
  query,
  variables,
  mslAuthConfig,
  cacheKey = "",
  isBookmarkApi=false,
) => {
  if (!mslAuthConfig) {
    return {};
  }
  let config = await getConfig()
  const api_end_point=isBookmarkApi ? config.AEM.graphql_bookmark_api_endpoint : config.AEM.graphql_api_endpoint;
  const graph_ql_endpoint = config.WEBAPI.base_url +  api_end_point;
  axios.defaults.headers.common['Authorization'] = `Bearer ${mslAuthConfig.accessToken}`
  let result = await axios.post(graph_ql_endpoint, {
    query,
    variables: variables,
    cacheKey,
  });

  if (result && result.data && Object.keys(result.data).length === 0) {
    //window.location.href = '/errorpage/?e=500'
    throw ({
      error: 'Site is under maintenance',
      status: 500
    });

  } else {
    //console.log('POST_GRAPHQL result ::', result)
    return result;
  }


};

export const POST_CALL = async (url, payload, mslAuthConfig, responseType = {}) => {
  if (!mslAuthConfig) {
    return {};
  }
  let config = await getConfig();
  const api_end_point = config.WEBAPI.base_url + url;
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${mslAuthConfig.accessToken}`;
  return await axios.post(api_end_point, payload, responseType);
};

export const PUT_CALL = async (url, payload, mslAuthConfig, responseType = {}) => {
  if (!mslAuthConfig) {
    return {};
  }
  let config = await getConfig();
  const api_end_point = config.WEBAPI.base_url + url;
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${mslAuthConfig.accessToken}`;
  return await axios.put(api_end_point, payload,{headers: {'Content-Type': 'application/json'}});
};


export const GET_CALL = async (url, mslAuthConfig, responseType = {}) => {
  if (!mslAuthConfig) {
    return {};
  }
  let config = await getConfig();
  const api_end_point = config.WEBAPI.base_url + url;
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${mslAuthConfig.accessToken}`;
  return await axios.get(api_end_point, responseType);
};
