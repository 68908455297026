import React from "react";
import classes from "./CapabilityBreadcrumb.module.scss";
function CapabilityBreadcrumb({ props }) {
    const {valuechain,navigateToStoreFront} = props;
    return (<>
        <div className={classes.capabilityBreadcrumb} data-testid= "Capability-Breadcrumb">
            <div className={classes.capabilityBreadcrumbItem}> {valuechain?.subsector?.sector?.industry?.industryName}</div>
            <div className={classes.capabilityBreadcrumbItem}> {valuechain?.subsector?.sector?.sectorName} </div>
            <div className={classes.capabilityBreadcrumbItemActive} onClick={() => navigateToStoreFront(valuechain?.subsector?.subsectorName) }> {valuechain?.subsector?.subsectorName}</div>
        </div>
    </>)
}

export default CapabilityBreadcrumb;