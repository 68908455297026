import React, { useEffect, useState, useRef } from "react";
import * as HoverCard from '@radix-ui/react-hover-card';
import './BriefCapabilityInfo.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

function BriefCapabilityInfo(props) {
    const Benefits = props.Benefits;
    const capabilityPageStyle = {backgroundColor: 'white', borderRadius: '6px', boxShadow: '0px 4px 5px 0.5px rgba(206, 206, 206, 0.9)', height: '552px'}
    const headerRef = useRef(null)
    const mainRef = useRef(null)
    const [contentHeight, setContentHeight] = useState(0)
    
    useEffect(() => {
        setContentHeight(mainRef.current.clientHeight - headerRef.current.clientHeight - 50)
    }, [])

    return (
        <div ref={mainRef} style={props.capabilityPage === 'true' ? capabilityPageStyle : {}} data-testid= "Brief-Capability-Info">  
            <div ref={headerRef}>
                {props?.displayValueChainName?.length > 0 && 
                        <p style={{padding: '2rem 0rem 0rem 1rem', fontSize: '12px',fontFamily: 'Open Sans Semibold', marginBottom: '0rem'}}>
                            {props?.displayValueChainName}
                        </p>
                }         
                <div className="capability-label-container" style= {{padding: '0rem 1rem 1rem 1rem'}}>                
                    <div className="capability-label">{props.capabilityType}</div>            
                </div>   
            </div>   
            <hr style={{ marginTop: '0.5rem',borderColor: '#9fa0a6' }} />      
            <div style={props.capabilityPage === 'true' ? {height: `${contentHeight}px`} : {}} className={props.capabilityPage === 'true' ? 'capability-suggestion-content' : 'capability-tooltip-content'}>               
                <div className="capability-befinits-section">                    
                    <div className="capability-benifits-label">Benefits & Value</div>                    
                    {Benefits !== 'N/A' ?
                         Benefits?.map(benefit => (
                            <>
                                <div className="capability-benifits-points" key={benefit}>                                
                                    <div className="tick"><FontAwesomeIcon icon={faCheck} /></div>                                
                                    <div className="benefit-points-text">{benefit}</div>                           
                                </div>                        
                            </>
                        ))
                        :
                        <div>N/A</div>
                }
                </div>          
                <div className="capability-description-section"> 
                    <div className="capability-description-label">Description</div>                   
                    <div className="capability-description">{props.description}</div>                
                </div>            
            </div>        
        </div>)
}
export default BriefCapabilityInfo;