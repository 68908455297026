import React, { useEffect } from "react";
import classes from "./ValueChainCard.module.scss";
import { Collapse, Space } from "antd";
import { PlusOutlined, MinusOutlined, HolderOutlined } from "@ant-design/icons"
import BriefCapabilityInfo from "../brief-capability-info/BriefCapabilityInfo.Component"

import * as HoverCard from '@radix-ui/react-hover-card';
import ExpandCollapseIcon from "../ExpandCollapseIcon/ExpandCollapseIcon.component";
import { replaceBreakWithNextline } from "./../../utils/utilities"
import { capabilityDrawer } from '../../constants/commonConstants'

function ValueChainCard({ props }) {
  const { Panel } = Collapse;
  const { valueChainName, capabilities, navigateToCapabilityPage, selectedCapabilityList, setNewCapability } = props;
  let selectedIndex = -1;
  let selectedCapabilityIndex = -1;
  const bvMaturityList = props.newBvMaturityList ? props.newBvMaturityList : {}
  const techMaturityList = props.newTechMaturityList ? props.newTechMaturityList : {}
  const dapMaturityList = props.newDapMaturityList ? props.newDapMaturityList : {}
  const ecoMaturityList = props.newEcoMaturityList ? props.newEcoMaturityList : {}
  let displayCapability = false;

  const totalSelected = () => {
    let totalCount = 0;
    selectedCapabilityList.forEach(valueChain => {
      valueChain.capabilityList.forEach(() => totalCount++)
    }
    );
    return totalCount;
  }
  const checkEnablersSelected = (inputValueChain) => {

    let result = false;
    if (bvMaturityList.length == 0 && techMaturityList.length == 0 && dapMaturityList.length == 0 && ecoMaturityList.length == 0) {
      result = true
    }
    else {
      result = checkEnablersBVSelected(inputValueChain) && checkEnablersTechSelected(inputValueChain) && checkEnablersMaturitySelected(inputValueChain) && checkEnablersTPSelected(inputValueChain)
    }
    return result;
  }

  const updateCapabilityList = (capability) => {
    // Condition that check if the capability exists in the list and removes it. 
    if (checkIfSelected(capability)) {
      if (selectedCapabilityList[selectedIndex].capabilityList.length === 1) {
        selectedCapabilityList.splice(selectedIndex, 1);
      } else {
        selectedCapabilityList[selectedIndex].capabilityList.splice(selectedCapabilityIndex, 1);
      }
      selectedCapabilityIndex = -1;
      selectedIndex = -1;
    } else {
      const totalSelectedCount = totalSelected();
      // Condition that takes care of the addition logic
      if (totalSelectedCount < capabilityDrawer.maxLimit) {
        if (selectedIndex > -1) {
          selectedCapabilityList[selectedIndex].capabilityList.push(capability)
        } else {
          selectedCapabilityList.push(
            {
              valueChainName: capability.valuechain.valueChainName,
              capabilityList: [capability]
            }
          )
        }
        selectedIndex = -1;
      }
    }
    setNewCapability(selectedCapabilityList);
  }

  const checkIfSelected = (capability) => {
    if (selectedCapabilityList) {
      for (let i = 0; i < selectedCapabilityList.length; i++) {
        if (selectedCapabilityList[i]?.valueChainName === capability?.valuechain?.valueChainName && selectedCapabilityList[i]?.capabilityList.map(capability => capability.capabilityName).includes(capability.capabilityName)) {
          selectedIndex = i;
          selectedCapabilityIndex = selectedCapabilityList[i]?.capabilityList.map(capability => capability.capabilityName).indexOf(capability.capabilityName);
          return true;
        } else if (selectedCapabilityList[i]?.valueChainName === capability?.valuechain?.valueChainName) {
          selectedIndex = i;
          break;
        } else {
          selectedIndex = -1;
        }
      }
      return false;
    }
  }

  const checkEnablersBVSelected = (inputValueChain) => {
    let result = false;
    //Business Value
    result = bvMaturityList.length == 0
    if (inputValueChain.businessValue.length > 0 && bvMaturityList.length > 0) {
      result = checkForSubset(Array.from(inputValueChain.businessValue.map((item) => { return item.name })).sort(), bvMaturityList.sort())
      displayCapability = result;
    }
    return result;
  }
  const checkEnablersTechSelected = (inputValueChain) => {
    let result = false;
    //Technology Enablement
    result = techMaturityList.length == 0
    if (inputValueChain.technologyEnablement.length > 0 && techMaturityList.length > 0) {
      result = checkForSubset(Array.from(inputValueChain.technologyEnablement.map((item) => { return item.name })).sort(), (techMaturityList.sort()))
      displayCapability = result;
    }
    return result;
  }

  const checkEnablersMaturitySelected = (inputValueChain) => {
    let result = false;
    //Asset Portfolio OR Maturity Level
    result = dapMaturityList.length == 0
    if (dapMaturityList.length > 0 && inputValueChain.maturityLevel !== null) {
      result = checkForSubset([inputValueChain.maturityLevel.name], dapMaturityList.sort())
    }
    displayCapability = result;
    return result;
  }
  const checkEnablersTPSelected = (inputValueChain) => {
    let result = false;
    //Third Party Solutions OR eco Partner Solutions OR Ecosystem Acceleration
    result = ecoMaturityList.length == 0
    if (inputValueChain.ecoPartnerSolutions.length > 0 && ecoMaturityList.length > 0) {
      result = checkForSubset(Array.from(inputValueChain.ecoPartnerSolutions.map((item) => { return item.name })).sort(), ecoMaturityList.sort())
      displayCapability = result;
    }
    if (inputValueChain.ecoPartnerSolutions.length === 0) {
      result = true
    }
    return result;
  }

  function arrayEquals(a, b) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }

  const checkForSubset = (capabilityPropertiesArr, selectedFiltersArr) => {
    return selectedFiltersArr.every(val => capabilityPropertiesArr.includes(val));
  }

  return (
    <Collapse collapsible="icon" expandIconPosition="end" className={classes.collapse} defaultActiveKey={1}
      expandIcon={({ isActive }) => <ExpandCollapseIcon isActive={isActive} isDark={true}/>} >
      <Panel header={
        <span>
          {/* <div className={classes.dragIcon}><Space><HolderOutlined /></Space></div>  */}
          <span className={classes.valueChainName}>{valueChainName}</span>
        </span>}
        key="1" style={{ fontFamily: 'Open Sans' }} >
        {capabilities.map((capability, index) =>
          <HoverCard.Root closeDelay={0} openDelay={0} key={capability.capabilityName}>
            <HoverCard.Trigger asChild>
              <div className={checkEnablersSelected(capability) ? capabilities.length - 1 === index ? classes.capabilityContainerNoBorder : classes.capabilityContainer : classes.capabilityHide}>
                <div className={checkEnablersSelected(capability) ? classes.capability : classes.capabilityHide} style={checkIfSelected(capability) ? { backgroundColor: '#9DD4CF', display: "flex" } : { display: "flex" }}>
                  <div className={classes.capabilityName} onClick={() => navigateToCapabilityPage(valueChainName, capability.capabilityName, capabilities)}>{capability.capabilityName}</div>
                  <div className={classes.plusIcon} onClick={() => { updateCapabilityList(capability) }}><Space>{checkIfSelected(capability) ? <MinusOutlined /> : <PlusOutlined />}</Space></div>
                </div>
              </div>
            </HoverCard.Trigger>
            <HoverCard.Portal>
              <HoverCard.Content style={{ marginTop: '5rem' }} className={classes.HoverCardContent} sideOffset={0} side='left' avoidCollisions={true} alignOffset={30} align='end' hideWhenDetached={true}>
                <BriefCapabilityInfo capabilityPage='false' capabilityType={capability.capabilityName} Benefits={capability?.benefitOrValue?.plaintext?.length > 0 ? replaceBreakWithNextline(capability?.benefitOrValue?.plaintext) : 'N/A'} description={capability.capabilityDescription.plaintext}></BriefCapabilityInfo>
              </HoverCard.Content>
            </HoverCard.Portal>
          </HoverCard.Root>
        )}
      </Panel>
    </Collapse>
  );
}

export default ValueChainCard;
