import React from 'react'

const AuthLayout = (props) => {
    return (
        <div>
            {/*<div>Login ....</div>*/}
            <div>{props.children}</div>
        </div>

    )
}

export default AuthLayout