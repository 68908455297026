import React, { Component } from "react";
import { useSearchParams } from "react-router-dom";
import "./ErrorPage.scss";

function ErrorPage() {

    const [searchQueryParams] = useSearchParams()
    const errorCode = searchQueryParams.get('e') ? searchQueryParams.get('e') : 404

    return (
        <>
            <div className="notfound">
                <div className="notfound-parentdiv">
                    <div className="notfound-childdiv">
                        <h1>{errorCode}</h1>
                    </div>
                    <p>Page not found</p>
                    <a href="/">Home Page</a>
                </div>
            </div>
        </>
    )
}
export default ErrorPage