import React, { useState, useEffect } from "react";
import { Card } from "reactstrap";
import SupportingMaterials from "./SupportingMaterials";
import { RedirectIcon } from "../../constants/images";
import { getUniqueListBy } from "../../utils/assets_data_converter";
import styles from './CapabilityDetails.module.scss';
import GreenTick from './../../assets/images/sector/greenTick.png'
import { replaceBreakWithNextline } from './../../utils/utilities';
import SalesCollaterals from "../../components/SalesCollaterals/SalesCollaterals.Component";
function CapabilityDetails({ props, campaignMaster, collaterals, additionalLinksExternal, additionalLinksInternal,  email = "" }) {
 
  const {capabilityDetail} = props;
  const [ecoSystem, setEcoSystem] = useState([]);

  let benefitsValueArray = props.benefitOrValue?.plaintext?.length > 0 && replaceBreakWithNextline(props.benefitOrValue?.plaintext)

  useEffect(() => {
    getEcoSystem();
  }, []);

  useEffect(()=>{

  },[collaterals])

  const getEcoSystem = () => {
    let ecoSolutionsTemp = capabilityDetail?.ecoPartnerSolutions;
    if (ecoSolutionsTemp) {
      let ecoSolutionData = [];
      ecoSolutionsTemp.forEach((element) => {
        ecoSolutionData.push(element.ecoSystem);
      });
      let uniqueList = getUniqueListBy(ecoSolutionData, "name");
      setEcoSystem(uniqueList);
    }
  };
  return (
    <Card className="capabilities-card">
      <div>
        <div className={styles.capabilityTitle}><strong>Overview</strong></div>
        {props.capabilityDescription && props.capabilityDescription.plaintext && (
          <div
            style={{marginLeft: '0.7rem', marginTop: '1.5rem', fontSize: '16px', fontFamily: 'Open Sans'}}
            dangerouslySetInnerHTML={{
              __html: props.capabilityDescription.plaintext,
            }}
          ></div>
        )}
      </div>
      {
      (props.benefitOrValue && props.benefitOrValue.plaintext) && <div className={styles.detailsArea}>
        <p className={styles.bnvTitle}>Benefits and Value</p>
        {benefitsValueArray.map((item)=>(
            <div style={{marginLeft: '-0.3rem', paddingBottom: '0.5rem', display: "flex", flexDirection: "row"}}>
              <img src={GreenTick} alt="green tick" width='27px' height='27px'/>
              <div className={styles.detailsText}>{item}</div>
            </div>
        ))}
      </div>
      }
      {
      ecoSystem && ecoSystem?.length > 0 && <div>
        <p className={styles.ecosysStyle} style={{fontSize: '22px'}}>Compatible Eco-Systems</p>
        {
        ecoSystem?.map((item, index) => (
          <div key={index} className="capability-properties-content">
            <div className="d-inline">
              {item.icon && item.icon._imageBase64 && (
                <img
                  src={item.icon._imageBase64}
                  alt=""
                  width="24px"
                  height="24px"
                />
              )}
            </div>
            <div className="d-inline enabler-title">{item.name}</div>
            <div className="d-inline float-end mt-2 redirect">
              <a
                href={item?.referenceUrl || "https://www.deloitte.com/about"}
                className="ecosystem-icon"
              >
                <img src={RedirectIcon} alt="" width="12px" height="12px" />
              </a>
            </div>
            {index !== ecoSystem.length - 1 && (
              <hr className="capability-details-underline" />
            )}
          </div>
        ))}
      </div>
      }
      {
      collaterals.length>0 && <div>
        <p className={styles.ecosysStyle} style={{fontSize: '22px'}}>Sales Collateral</p>
        {/* {
          capabilityDetail?.salesCollateral?.map((item, index) => (
            <div key={index} className="capability-properties-content">
              <SalesCollateralItem item={item} key={index} accessToken={props.accessToken} />
              {index !== capabilityDetail?.salesCollateral.length - 1 && (
                <hr className="capability-details-underline" />
              )}
            </div>
          ))} */}
          <SalesCollaterals collaterals={collaterals}/> 

      </div>
      }
      {
        <div>
          {/* {capabilityDetail?.supportingMaterials &&
          capabilityDetail?.supportingMaterials?.map((item, index) => (
            <div key={index} className="capability-properties-content">
              <p style={{fontSize: '22px', margin: '3rem 0rem 1rem 0.8rem', fontWeight: 'bolder'}}>Supporting Materials</p>
              <SupportingMaterials item={item} key={index} accessToken={props.accessToken} />
              {index !== capabilityDetail?.supportingMaterials.length - 1 && (
                <hr className="capability-details-underline" />
              )}
            </div>
          ))} */}
          {(additionalLinksExternal?.length > 0 || additionalLinksInternal?.length > 0) &&        
            <div className="capability-properties-content">
              <div className={styles.supportingMaterials}>Supporting Materials</div>
              <SupportingMaterials additionalLinksExternal={additionalLinksExternal} additionalLinksInternal={additionalLinksInternal} />

              {/* <AdditionalLinks externalLinksArray={additionalLinksExternal} internalLinksArray={additionalLinksInternal} deloitteAssetFlag='false' accessToken={props.accessToken} /> */}
            </div>
          }
        </div>
      }
    </Card>
  );
}

export default CapabilityDetails;
