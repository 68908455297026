export const GET_INUDSTRIES_LIST = `
{
    industryList(filter:{     
      isDeleted:{
        _expressions:{
          value:false
        }
      }
    }){
      items {
        _path      
        industryName
        referenceLinkTitle
        referenceLinkUrl
        isActive
        isDeleted
        displayOrder
      }
    }
  }
  `



export const GET_INUDSTRIES_BY_NAME = `
query GetIndusty($industryName:String!){
  industryList(filter:{
    industryName:{
      _expressions:{
        value:$industryName
      }
    }
    isActive:{
      _expressions:{
        value:true
      }
    }
    isDeleted:{
      _expressions:{
        value:false
      }
    }
  }){
    items {
      _path      
      industryName
      referenceLinkTitle
      referenceLinkUrl
      isActive
      displayOrder
      isDeleted
    }
  }
}
  `


export const GET_INDUSTRY_LIST_WITH_SECTOR_SUBSECTOR = `

{
  industryList(filter:{
    isDeleted:{
      _expressions:{
        value:false
      }
    }
  }){
    items {
      _path      
      industryName
      referenceLinkUrl
      referenceLinkTitle
      isActive
      isDeleted
      displayOrder
    }
  }
  
  sectorList(filter:{
    isDeleted:{
      _expressions:{
        value:false
      }
    }
  }){
      items{
        _path
        sectorName
        sectorDescription{
          html
        }
        industry{
          industryName
          _path
        }
        isActive
      }
  }
  
  subsectorList(filter:{
    isDeleted:{
      _expressions:{
        value:false
      }
    }
  }){
    items{
      sector{
        _path
        sectorName
      }
      subsectorName
      subsectorDescription{
          html
        }
      _path
      isActive      
    }
  }
}
  `


export const GET_INDUSTY_BY_NAME_WITH_SECTOR_SUBSECTOR = `
query getIndustryByName($industryName:String!){
  industryList(filter:{
    industryName:{
      _expressions:{
        value:$industryName
      }
    }
  }){
    items {
      _path      
      industryName
      referenceLinkUrl
      referenceLinkTitle
      industryDescription{
				html
			}
      isActive
    }
  }
  
  sectorList(filter:{
    industry:{
      industryName:{
         _expressions:{
        	value:$industryName
      	}
      }
    }
    isDeleted:{
      _expressions:{
        value:false
      }
    }
  }){
      items{
        _path
        sectorName
        sectorDescription{
          html
        }
        industry{
          industryName
          _path
        }
        isDeleted
        isActive
      }
  }
  
  subsectorList(filter:{
    isDeleted:{
      _expressions:{
        value:false
      }
    }
  }){
    items{
      sector{
        _path
        sectorName
        industry{
          industryName
        }
      }
      subsectorName
      subsectorDescription{
          html
        }
      _path
      isDeleted
      isActive
      
    }
  }
}
`

export default {
  GET_INUDSTRIES_LIST,
  GET_INUDSTRIES_BY_NAME,
  GET_INDUSTRY_LIST_WITH_SECTOR_SUBSECTOR,
  GET_INDUSTY_BY_NAME_WITH_SECTOR_SUBSECTOR

}