import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import search, { SEARCH_BY_KEY } from '../../services/graphql-queries/search';
import { POST_GRAPHQL } from '../../services/httpService';
import { removeSpaceWithHypens, sortByKey } from '../../utils';
import './style.scss'
const RECORDS_PER_PAGE = 5;
const Search = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchedFlag, setSearchedFlag] = useState(false);
    const [resultShowingKey, setResultShowingKey] = useState("");
    const [searchKey, setSearchKey] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {

    }, [])

    const GET_SEARCH_RESULTS = async () => {
        if (!searchKey || searchKey.length == 0) {
            return
        }

        if (resultShowingKey.trim() === searchKey.trim()) {
            return
        }
        setSearchResults([])
        setLoading(true)
        setResultShowingKey(searchKey)
        try {
            let responseData = await POST_GRAPHQL(
                SEARCH_BY_KEY,
                { searchKey: searchKey.trim() },
                { accessToken: props.accessToken },
                null
            );
            setSearchedFlag(true)
            setLoading(false)

            if (responseData && responseData.data && responseData.data.data) {
                let capabilityList = responseData.data.data.capabilityList.items;

                if (capabilityList && capabilityList.length > 0) {
                    let displayOrderedList = sortByKey(
                        capabilityList,
                        "displayOrder"
                    );
                    setSearchResults(displayOrderedList)
                }
            }
        } catch (e) {
            setLoading(false)
            console.log('search error', e)
        }

    }

    const setPage = (pageNum) => {
        setCurrentPage(pageNum)
    }

    const navigateToCaps = (pathName) => {
        let modifiedPath = removeSpaceWithHypens(pathName);

        navigate(`/capabilities/${modifiedPath}`);
    }

    const pageNumbers = []

    for (let i = 1; i <= Math.ceil(searchResults.length / RECORDS_PER_PAGE); i++) {
        pageNumbers.push(i);
    }

    const indexOfLastPost = currentPage * RECORDS_PER_PAGE;
    const indexOfFirstPost = indexOfLastPost - RECORDS_PER_PAGE;
    const currentRecords = searchResults.slice(indexOfFirstPost, indexOfLastPost);


    return <div>

        <div class="search-container">
            <div className="search-input-container">
                <div className="input-group">
                    <input className="form-control border-end-0 border rounded-pill" value={searchKey} onChange={(e) => setSearchKey(e.target.value)} type="text" placeholder='Type here to search' id="search-input" />
                    <span className="input-group-append">
                        <button onClick={() => {
                            GET_SEARCH_RESULTS()
                        }} className="ml-10  btn-custom btn-outline-secondary bg-white  border rounded-pill ms-n3" type="button">
                            <i className="fa fa-search"></i>
                        </button>
                    </span>
                </div>
            </div>

            <div className="search-result-wrapper mt-20">
                {
                    searchKey && searchedFlag && !loading && <div>
                        <h4>Result for
                            <strong> {`    "${resultShowingKey}"`} </strong>
                        </h4>
                    </div>
                }


                <div className='result-container'>
                    <div className="grid-col-2">

                        <div class="left-section">
                            {
                                loading && <div>
                                    <div className='loading-skeleton'>
                                        <p>Results</p>
                                        <p>Title</p>
                                        <p>Title</p>
                                        <p>Title</p>

                                    </div>
                                </div>
                            }

                            {
                                resultShowingKey && searchedFlag && !loading && <div>
                                    <div> <p>Filter By</p></div>
                                    <div>
                                        <ul className='filter-list'>
                                            <li>
                                                <a href={null} className="active">Capabilities </a>
                                            </li>
                                            {/* <li>
                                                <a href={null} className="active">Industry </a>
                                            </li>
                                            <li><a href={null}>Sector </a></li>
                                            <li><a href={null}>Sub sector </a></li> */}
                                        </ul>
                                    </div>
                                </div>
                            }


                        </div>
                    </div>
                    <div className="grid-col-8">

                        <div className='right-section'>

                            {
                                loading && <div>
                                    <div className='loading-skeleton'>
                                        <p>Results</p>
                                        <p>Title</p>
                                        <div className="descripton-box">

                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                resultShowingKey && searchedFlag && !loading && <div>
                                    <p>All Results : <strong>{` ${searchResults.length} result `}found</strong> </p>
                                </div>
                            }

                            <div className="row">
                                <div className='col-md-12'>
                                    {
                                        !loading && searchResults && searchResults.length > 0 && <div className="results-cards">
                                            <ul className="results-cards-list">
                                                {
                                                    currentRecords.map((result, index) => <li key={index}>
                                                        <div className="list-item">
                                                            <div>
                                                                <h3>                                                                    <a href={null} target="_blank" onClick={() => navigateToCaps(result.capabilityName)}>{result.capabilityName}</a>
                                                                </h3>
                                                            </div>
                                                            <div dangerouslySetInnerHTML={{
                                                                __html: result.capabilityDescription.plaintext,
                                                            }}>
                                                            </div>
                                                        </div>
                                                    </li>)
                                                }

                                            </ul>
                                        </div>
                                    }
                                </div>
                            </div>

                            {/* pagination */}
                            {
                                !loading && searchResults && searchResults.length > 0 &&
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="pagination justify-content-center">
                                            {
                                                pageNumbers.map((pageNum, index) => (
                                                    <span key={index} className={pageNum === currentPage ? "cursor-pointer btn btn-rounded btn-primary text-white" : "btn btn-rounded btn-default rounded-full"} onClick={() => { setPage(pageNum) }}>
                                                        {pageNum}
                                                    </span>
                                                ))
                                            }
                                        </div>

                                    </div>
                                </div>

                            }

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
}

export default Search;