import React, { Component } from "react";
import { Alert } from "reactstrap";
import { LOG_TYPE } from "../../constants/logConstants";
import { logError } from "../../services/loggingService";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // console.log(error)
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error);
    console.log(info);
    const payload = {
      properties: {
        "cq:model": "/conf/DigiPrint/settings/dam/cfm/models/log",
        title: `${LOG_TYPE.UI_ERROR} - ${Date.now()}`,
        description: LOG_TYPE.UI_ERROR,
        elements: {
          logType: {
            value: LOG_TYPE.UI_ERROR,
          },
          loggedOn: {
            value: Date.now(),
          },
          logArea: {
            value: LOG_TYPE.UI_ERROR,
          },
          sender: {
            value: LOG_TYPE.UI_ERROR,
          },
          logMessage: {
            value: `
              ${JSON.stringify(info)}
              `,
          },
          loggedBy: {
            value: process.env.REACT_APP_AEM_USERNAME,
          },
          logDetails: {
            value: `
              ${error}
              `,
          },
        },
      },
    };
    logError(payload);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Alert style={{ marginTop: '70px' }} color="danger">Something Went Wrong.</Alert>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
