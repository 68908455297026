
export const GET_CONTACT_LIST = `
{
  contactgroupList(filter:{
    isActive:{
      _expressions:{
        value:true
      }
    }
    isDeleted:{
      _expressions:{
        value:false
      }
    }
    contacts:{
      _match:{
            isDeleted:{
              _expressions:{
                value:false
              }
            }
      }
    }
    
  }){
    items{
      contactGroupName
      contactGroupDescription{
        html
      }
      contacts{
        contactEmail
        contactTitle
        contactTelephoneNumber
        associatedFirm
        displayOrder
        _path
        displayOrder
        isActive
        isDeleted

        subSector{
          subsectorName
          sector{
            sectorName
            industry{
              industryName
            }
          }
        }


      }
      displayOrder
      isActive
    }
    
  }
}
`

export default {
  GET_CONTACT_LIST
}