import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./index";

const initialState = {};

const middleWare = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "dev"
    ? composeWithDevTools(applyMiddleware(...middleWare))
    : applyMiddleware(...middleWare)
);

export default store;
