export const TEST_TYPE = 'TEST_TYPE';
export const SET_ACTIVE_NAVIGATION = 'SET_ACTIVE_NAVIGATION';
export const SET_STOREFRONT_ACTIVE_TAB = 'SET_STOREFRONT_ACTIVE_TAB';
export const RESET_STOREFRONT_ACTIVE_TAB = 'RESET_STOREFRONT_ACTIVE_TAB';
export const SET_STOREFRONT_VIEW_TAB = 'SET_STOREFRONT_VIEW_TAB';
export const SET_MATURITY_LIST_DATA = 'SET_MATURITY_LIST_DATA';
export const SET_ACTIVE_VALUE_CHAIN = 'SET_ACTIVE_VALUE_CHAIN';
export const SET_ACTIVE_CARD_DATA = 'SET_ACTIVE_CARD_DATA';
export const SET_CURRENT_SUBSECTOR = 'SET_CURRENT_SUBSECTOR';
export const TECH_SET_MATURITY_LIST_DATA = 'TECH_SET_MATURITY_LIST_DATA';
export const DAP_SET_MATURITY_LIST_DATA = 'DAP_SET_MATURITY_LIST_DATA';
export const DC_SET_MATURITY_LIST_DATA = 'DC_SET_MATURITY_LIST_DATA';
export const ECO_SET_MATURITY_LIST_DATA = 'ECO_SET_MATURITY_LIST_DATA';
export const BV_SET_MATURITY_LIST_DATA = 'BV_SET_MATURITY_LIST_DATA';
export const IE_SET_MATURITY_LIST_DATA = 'IE_SET_MATURITY_LIST_DATA';
export const SET_ADMIN_MENU = 'SET_ADMIN_MENU';
export const SET_LOGGED_USER_DATA = 'SET_LOGGED_USER_DATA';
export const SET_IS_ADMIN = 'SET_IS_ADMIN';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_NEW_MATURITY_LIST_DATA = 'SET_NEW_MATURITY_LIST_DATA';
export const SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES = 'SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES'
export const  SET_ACTIVE_VALUECHAIN_SPECIFIC_CAPABILITIES = 'SET_ACTIVE_VALUECHAIN_SPECIFIC_CAPABILITIES'
export const SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES_DESCRIPTIONS = 'SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES_DESCRIPTIONS'
export const SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES_BENEFITS = 'SET_ACTIVE_SUBSECTOR_SPECIFIC_CAPABILITIES_BENEFITS'
export const SET_NEW_CAPABILITY_LIST_DATA = 'SET_NEW_CAPABILITY_LIST_DATA';
export const SET_NEW_BV_MATURITY_LIST_DATA = 'SET_NEW_BV_MATURITY_LIST_DATA';
export const SET_NEW_TECH_MATURITY_LIST_DATA = 'SET_NEW_TECH_MATURITY_LIST_DATA';
export const SET_NEW_DAP_MATURITY_LIST_DATA = 'SET_NEW_DAP_MATURITY_LIST_DATA';
export const SET_NEW_ECO_MATURITY_LIST_DATA = 'SET_NEW_ECO_MATURITY_LIST_DATA';
export const SET_BOOKMARK_LIST_DATA = 'SET_BOOKMARK_LIST_DATA';
export const SET_SUBSECTOR_LIST_DATA = 'SET_SUBSECTOR_LIST_DATA' 
export const SET_IS_BOOKMARK_CREATED = 'SET_IS_BOOKMARK_CREATED';
