import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ModalBody, Row, Col } from "reactstrap";
import { Group, Learnmore1Image } from "../../constants/images";
import { pageNames } from "../../constants/omnitureConstants";
import { removeSpaceWithHypens } from "../../utils";
import { actionTracking } from "../../utils/omniture";
import "./Modal.Component.scss";

function ModalComponent({ props }) {
  const navigate = useNavigate();
  const navReducer = useSelector((state) => ({
    email: state.navigationReducer.userEmail,
  }));
  const navigateTo = (valueChainName, capabilityName) => {
    let modifiedValuchainPath = removeSpaceWithHypens(valueChainName);
    let modifiedCapsPath = removeSpaceWithHypens(capabilityName);
    if (navReducer.email !== "") {
      actionTracking(
        pageNames.STOREFRONT_PAGE,
        navReducer.email,
        "Selected Capability : " + capabilityName
      );
    }
    navigate(`/capabilities/${modifiedValuchainPath}/${modifiedCapsPath}`);
  };

  return (
    <ModalBody className="modal-body-content">
      <div
        className="modal-description"
        dangerouslySetInnerHTML={{
          __html: props.valueChainDescription.html,
        }}
      ></div>
      <Row className="modal-tabs-layout">
        <Col lg="6">
          <p className="modal-tab-headings">Industry</p>
          <p className="modal-tab-title">
            {props?.subsector?.sector?.industry?.industryName}
          </p>
        </Col>
        <Col lg="6">
          <p className="modal-tab-headings">Sector</p>
          <p className="modal-tab-title">
            {props?.subsector?.sector?.sectorName}
          </p>
        </Col>
        <Col lg="6">
          <p className="modal-tab-headings">Sub Sector</p>
          <p className="modal-tab-title">{props.subsector?.subsectorName}</p>
        </Col>
      </Row>
      <div className="capabilities-layout">
        <div className="capabilities-title">Included Capabilities</div>
        <div className="capabilities">
          {props.capabilities.length > 0 &&
            props.capabilities.map((cap, i) => (
              <div
                key={`key-${i + 1 + i}`}
                onClick={() => navigateTo(props.valueChainName, cap.capabilityName)}
                className="capability-individual"
              >
                <div className="d-inline">
                  <img
                    src={cap.capabilityIcon?._imageBase64 || Group}
                    alt=""
                    width="40px"
                    height="40px"
                    className="capability-icon"
                  />
                </div>
                <div className="d-inline">
                  <span className="capability">
                    {i + 1}. {cap.capabilityName}
                  </span>
                </div>
                <div className="d-inline float-end">
                  <img
                    className="capability-link-arrow"
                    src={Learnmore1Image}
                    alt=""
                  />
                </div>
                {i !== props?.capabilities?.length - 1 && (
                  <hr className="capability-underline mb-0" />
                )}
              </div>
            ))}
        </div>
      </div>
    </ModalBody>
  );
}

export default ModalComponent;
