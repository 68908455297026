import React, { useState } from 'react'
import styles from './FaqSection.module.scss';
import downArrow from './../../assets/images/homePage/down-arrow.png';
import upArrow from './../../assets/images/homePage/up-arrow.png';
import { Collapse } from 'antd';
const { Panel } = Collapse;

const FaqSection = (props) => {
    const {title, description, faqs} = props

    const sortedFaqArr = [].concat(faqs)
    .sort((a,b) => a.displayOrder > b.displayOrder ? 1 : -1)
    .map((item) => {return item})
    
    const [activeKey, setActiveKey] = useState()
    const [fix, setFix] = useState(false)

    const changeColor = (key) => {
        setActiveKey(key[0])
    }

    window.addEventListener("scroll", setFixedSidebar)

    function setFixedSidebar() {
        if(window.scrollY >= 200) {
            setFix(true)
        }
        else {
            setFix(false)
        }
    }

    return (
       
        <div className={styles.mainDiv}>           
            <div className={fix ? styles.sticky : styles.notSticky}>
                <div style={{fontSize: '24px', fontFamily: 'Open Sans', marginBottom: '24px'}}><strong>{title}</strong></div>
                <div style={{fontSize: '14px', fontFamily: 'Open Sans'}}>{description}</div>
            </div>           
            <div style={{width: '580px', overflowY: 'auto', overflowX: 'hidden'}}>
                <Collapse accordion bordered={true} 
                expandIconPosition="end" 
                onChange={changeColor}
                style={{borderColor: 'transparent'}}
                expandIcon={({ isActive }) => isActive ? <img src={upArrow} width='10px' height='6px' alt='chevron-up'/> : <img src={downArrow} width='10px' height='6px' alt='chevron-down'/>}
            >
                {
                    sortedFaqArr?.map((item, index) => (
                        <Panel key={index} className={styles.panelStyle} header={<div className={(index===(+activeKey)) ? styles.activeQuestionStyle : styles.questionStyle}>{item.question}</div>}>
                            <div className={styles.answerStyle} dangerouslySetInnerHTML={{ __html: item.answer }}></div>
                        </Panel>
                    ))
                }
                </Collapse>
            </div>      
        </div>
        
    )
}

export default FaqSection