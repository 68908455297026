import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "../../components/error-boundary/ErrorBoundary";
import BannerSection from "./components/BannerSection";
import UseFullLinks from "./components/UseFullLinks";
import { POST_GRAPHQL } from "../../services/httpService";
import { GET_BANNERS_BY_PAGE } from "../../services/graphql-queries/banners";
import { GET_HOMEPAGE_ITEMS } from "../../services/graphql-queries/homepageItems";
import { GET_DIGIPRINT_INFO } from "../../services/graphql-queries/digiprintInfo";
import { GET_HOME_DIGIPRINT_COMES_TO_LIFE } from "../../services/graphql-queries/homeDigiprintComesToLife";
import { GET_READY_TO_GET_STARTED } from "../../services/graphql-queries/homeReadyToGetStarted";
import { urlToDiv } from "../../utils/utilities";
import { UsefullLinksQuery } from "../../services/graphql-queries";
import HomeDigiprintComesToLife from "./components/HomeDigiprintComesToLife/HomeDigiprintComesToLife";
import DigiprintInfo from "./components/DigiprintInfo/DigiprintInfo";
import ReadyToGetStarted from "./components/ReadyToGetStarted/ReadyToGetStarted";

function Home(props) {
  const [bannerData, setBannerData] = useState({});
  const [bannerImageList, setBannerImageList] = useState([]);
  const [homepageItemsList, setHomepageItemsList] = useState([]);
  const [digiprintComesToLifeList, setDigiprintComesToLifeList] = useState([]);
  const [digiprintInfoList, setDigiprintInfoList] = useState([]);
  const [readyTogetStartedInfo, setReadyTogetStartedInfo] = useState([]);
  const [homeloading, setBannerLoading] = useState(true);
  const [linksLoading, setLinksLoading] = useState(true);
  const [digiprintComesToLifeLoading, setDigiprintComesToLifeLoading] = useState(true);
  const [digiprintInfoLoading, setDigiprintInfoLoading] = useState(true);
  const [readyTogetStartedLoading, setReadyTogetStartedLoading] = useState(true);
  const [error, setError] = useState(false);
  const [usefulLinksList, setUsefulLinksList] = useState([]);
  const readyTogetStarted = useRef();
  const digiprintInfo = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    if (props.accessToken != undefined) {
      GET_HOMEPAGE_ELEMENTS();
      GET_DIGIPRINT_INTRO();
      GET_BANNERS();
      GET_READY_TO_GET_STARTED_INFO();
      GET_USEFULLINKS_DATA();
      GET_HOME_DIGIPRINT_COMES_TO_LIFE_DATA();
    }
  }, [props.accessToken]);
  const GET_HOMEPAGE_ELEMENTS = async () => {
    try {
      let responseData = await POST_GRAPHQL(
        GET_HOMEPAGE_ITEMS,
        {},
        { accessToken: props.accessToken },
        "GET_HOMEPAGE_ITEMS"
      );
      if (responseData && responseData.data && responseData.data.data) {
        setHomepageItemsList(responseData.data.data.homePageItemsList.items);
      }
      urlToDiv();
    } catch (err) {
      console.log("err ", err);
    }
  };
  const GET_DIGIPRINT_INTRO = async () => {
    try {
      let responseData = await POST_GRAPHQL(
        GET_DIGIPRINT_INFO,
        {},
        { accessToken: props.accessToken },
        "GET_DIGIPRINT_INFO"
      );
      if (responseData && responseData.data && responseData.data.data) {
        setDigiprintInfoList(responseData.data.data.digiprintInfoItemsList.items);
      }
      setDigiprintInfoLoading(false);
      urlToDiv();
    } catch (err) {
      setDigiprintInfoLoading(false);
      console.log("err ", err);
    }
  };
  const GET_READY_TO_GET_STARTED_INFO = async () => {
    try {
      let responseData = await POST_GRAPHQL(
        GET_READY_TO_GET_STARTED,
        {},
        { accessToken: props.accessToken },
        "GET_READY_TO_GET_STARTED"
      );
      if (responseData && responseData.data && responseData.data.data) {
        setReadyTogetStartedInfo(arrangeStepsAsDisplayOrder(responseData.data.data.homeGetStartedList.items));
        setReadyTogetStartedLoading(false);
      }
      urlToDiv();
    } catch (err) {
      setReadyTogetStartedLoading(false);
      console.log("err ", err);
    }
  };
  const GET_BANNERS = async () => {
    setBannerLoading(true);
    try {
      let responseData = await POST_GRAPHQL(
        GET_BANNERS_BY_PAGE,
        { page: "home-page" },
        { accessToken: props.accessToken },
        "GET_BANNERS_BY_PAGE"
      );
      if (responseData && responseData.data && responseData.data.data) {
        let bannersList = responseData.data.data.bannersList;

        if (bannersList && bannersList.items && bannersList.items.length > 0) {
          setBannerData(bannersList.items[0]);
          setBannerImageList(bannersList.items[0].image)
        }
      }

      setBannerLoading(false);
      urlToDiv();
    } catch (err) {
      setBannerLoading(false);
      setError(true);
      console.log("err ", err);
    }
  };

  const GET_USEFULLINKS_DATA = async () => {
    setLinksLoading(true);
    try {
      let responseData = await POST_GRAPHQL(
        UsefullLinksQuery.GET_USEFULL_LINKS,
        {},
        { accessToken: props.accessToken },
        "GET_USEFULL_LINKS"
      );

      if (responseData && responseData.data && responseData.data.data) {
        setUsefulLinksList(arrangeAsDisplayOrder(responseData.data.data.homeUsefulLinksList.items));
      }

      setLinksLoading(false);
      urlToDiv();
    } catch (err) {
      setLinksLoading(false);
      console.log("err ", err);
    }
  };

  const GET_HOME_DIGIPRINT_COMES_TO_LIFE_DATA = async () => {
    setDigiprintComesToLifeLoading(true);
    try {
      let responseData = await POST_GRAPHQL(
        GET_HOME_DIGIPRINT_COMES_TO_LIFE,
        {},
        { accessToken: props.accessToken },
        "GET_HOME_DIGIPRINT_COMES_TO_LIFE"
      );

      if (responseData && responseData.data && responseData.data.data) {
        setDigiprintComesToLifeList(arrangeAsDisplayOrder(responseData.data.data.homeDigiprintComesToLifeItemsList.items))
      }

      setDigiprintComesToLifeLoading(false);
      urlToDiv();
    } catch (err) {
      setDigiprintComesToLifeLoading(false);
      console.log("err ", err);
    }
  };

  const arrangeAsDisplayOrder = (list) => {
    var arrangedList = list.sort((a, b) => a.displayOrder - b.displayOrder)
    return arrangedList
  }

  const arrangeStepsAsDisplayOrder = (list) => {
    var arrangedList = list.sort((a, b) => a.displayorder - b.displayorder)
    return arrangedList
  }

  const scrollToReadyTogetStarted = (e) => {
    e.preventDefault();
    readyTogetStarted.current.scrollIntoView()
  }

  const scrollToDigiprintInfo = (e) => {
    e.preventDefault();
    digiprintInfo.current.scrollIntoView()
  }

  const navigateToFaqs = (e) => {
    e.preventDefault();
    navigate(`/faq`)
  }

  return (
    <>
      <ErrorBoundary>
        <BannerSection {...props} bannerImageList={bannerImageList} bannerData={bannerData} loading={homeloading} error={error} scrollToReadyTogetStarted={scrollToReadyTogetStarted} scrollToDigiprintInfo={scrollToDigiprintInfo} navigateToFaqs = {navigateToFaqs}/>
      </ErrorBoundary>
      <div ref={digiprintInfo}>
        <ErrorBoundary>
          <DigiprintInfo {...props} digiprintInfoList={digiprintInfoList} loading={digiprintInfoLoading} title={homepageItemsList[0]?.header} description={homepageItemsList[0]?.description?.plaintext} ></DigiprintInfo>
        </ErrorBoundary>
      </div>
        <ErrorBoundary>
          <HomeDigiprintComesToLife digiprintComesToLifeList={digiprintComesToLifeList} loading={digiprintComesToLifeLoading} title={homepageItemsList[1]?.header} description={homepageItemsList[1]?.description?.plaintext} ></HomeDigiprintComesToLife>
        </ErrorBoundary>
      <div ref={readyTogetStarted}>
        <ErrorBoundary>
          <ReadyToGetStarted  {...props} readyTogetStartedInfo={readyTogetStartedInfo} introGif={homepageItemsList[2]?.image?._publishUrl} loading={readyTogetStartedLoading} title={homepageItemsList[2]?.header} description={homepageItemsList[2]?.description?.plaintext} />
        </ErrorBoundary>
      </div>
      <ErrorBoundary>
        <UseFullLinks {...props} usefulLinksList={usefulLinksList} loading={linksLoading} title={homepageItemsList[3]?.header} description={homepageItemsList[3]?.description?.plaintext} />
      </ErrorBoundary>
    </>
  );
}

export default Home;
