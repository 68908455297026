import React from "react";
import styles from './ExpandCollapseIcon.module.scss';
import upArrow from './../../assets/images/storefront-view/arrow-up.png';
import upArrowLight from './../../assets/images/storefront-view/arrow-up-light.png';

const ExpandCollapseIcon=(props)=>{
    const { isActive, isDark } = props
    return isActive ? <div className={styles.roundBorder}><img className={styles.rotate} src={isDark ? upArrow : upArrowLight} alt="down-arrow" width="9px" height="6px" color="white"/></div> : <div className={styles.roundBorder}><img className={styles.rotateAnti} src={isDark ? upArrow : upArrowLight} alt="up-arrow" width="9px" height="6px"/></div>
    //return isActive ? <UpOutlined id='upoutlined' className={styles.rotate}/> : <UpOutlined id='upoutlined' className={styles.rotateAnti}/>

}

export default ExpandCollapseIcon;