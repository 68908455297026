import React from "react";
import classes from "./DigiprintInfo.module.scss";
import { Col, Row } from "reactstrap";

const DigiprintInfo = (props) => {
    const { title, loading, digiprintInfoList, description } = props;
    return (
        <div className={classes.digiprintInfoContainer}>
            <Row className={classes.titleSection}>
                <Row>{title && <p className={classes.digiprintTitle}>{title}</p>}</Row>
                <Row>{description && <p className={classes.digiprintDescription}>{description}</p>}</Row>
            </Row>
            {digiprintInfoList[2]?.isActive && <Row style={{ marginTop: 80 }}>
                <Col lg="6" className={classes.digiprintInfoItemTitleSection}>
                    <div className={classes.digiprintInfoItemTitle}>{digiprintInfoList[2]?.header}</div>
                    <div className={classes.digiprintInfoItemDescription}>{digiprintInfoList[2]?.description?.plaintext}</div>
                </Col>
                <Col lg="1" ></Col>
                <Col lg="5">
                    {digiprintInfoList[2]?.image?._publishUrl && <img
                        className={classes.digiprintInfoimage}
                        src={digiprintInfoList[2]?.image?._publishUrl}
                        alt=""
                    />}
                </Col>
            </Row>}
            {digiprintInfoList[0]?.isActive && <Row style={{ marginTop: 80 }}>
                <Col lg="6">
                    {digiprintInfoList[0]?.image?._publishUrl && <img
                        className={classes.digiprintInfoimage}
                        style={{ float: 'left' }}
                        src={digiprintInfoList[0]?.image?._publishUrl}
                        alt=""
                    />}
                </Col>
                <Col lg="1" ></Col>
                <Col lg="5" className={classes.digiprintInfoItemTitleSection} style={{ maxWidth: 480, marginLeft: 'auto', marginRight: 0 }}>
                    <div className={classes.digiprintInfoItemTitle}>{digiprintInfoList[0]?.header}</div>
                    <div className={classes.digiprintInfoItemDescription}>{digiprintInfoList[0]?.description?.plaintext}</div>
                </Col>
            </Row>}
            {digiprintInfoList[1]?.isActive && <Row style={{ marginTop: 80 }}>
                <Col lg="6" className={classes.digiprintInfoItemTitleSection}>
                    <div className={classes.digiprintInfoItemTitle}>{digiprintInfoList[1]?.header}</div>
                    <div className={classes.digiprintInfoItemDescription}>{digiprintInfoList[1]?.description?.plaintext}</div>
                </Col>
                <Col lg="1" ></Col>
                <Col lg="5">
                    {digiprintInfoList[1]?.image?._publishUrl && <img
                        className={classes.digiprintInfoimage}
                        src={digiprintInfoList[1]?.image?._publishUrl}
                        alt=""
                    />}
                </Col>
            </Row>}
        </div>
    )
}

export default DigiprintInfo;