export const GET_CAPABILITY_BY_NAME = `query getCapsByName($capabilityName: String!, $valueChainName: String!) {
  capabilityList(
    filter: {capabilityName: {_expressions: {value: $capabilityName}}, valuechain: {valueChainName: {_expressions: {value: $valueChainName}}}}
  ) {
    items {
      capabilityName
      capabilityDescription {
        html
        plaintext
      }
      capabilityShortDescription {
        html
        plaintext
      }
      benefitOrValue {
        html
        plaintext
      }
      capabilityType {
        capabilityTypeName
        isActive
      }
      capabilityIcon {
        ... on ImageRef {
          _authorUrl
          _publishUrl
        }
      }
      capabilityImage {
        ... on ImageRef {
          _authorUrl
          _publishUrl
        }
      }
      externalOnePager {
        plaintext
        html

      }
      externalSalesDeck {
        plaintext
        html
      }
      externalDemoVideo {
        plaintext
        html
      }
      internalTearSheets {
        plaintext
        html
      }
      internalOverviewDeck {
        plaintext
        html
      }
      internalDemoVideo {
        plaintext
        html
      }
      additionalLinksExternal {
        plaintext
        html
      }
      additionalLinksInternal {
        plaintext
        html
      }
      displayOrder
      isActive
      isDeleted
      valuechain {
        valueChainName
        valueChainIcon {
          ... on ImageRef {
            _publishUrl
            _authorUrl
          }
        }
        subsector {
          subsectorName
          subsectorIcon {
            ... on ImageRef {
              _publishUrl
              _authorUrl
            }
          }
          sector {
            sectorName
            sectorIcon {
              ... on ImageRef {
                _publishUrl
                _authorUrl
              }
            }
            industry {
              industryName
              industryIcon {
                ... on ImageRef {
                  _publishUrl
                  _authorUrl
                }
              }
            }
          }
        }
      }
      technologyEnablement {
        name
        description {
          html
        }
        icon {
          ... on ImageRef {
            _authorUrl
            _publishUrl
          }
        }
        isActive
        isDeleted
        displayOrder
      }
      deloitteAssets {
        assetName
        externalOnePager {
          plaintext
          html
        }
        externalSalesDeck {
          plaintext
          html
        }
        externalDemoVideo {
          plaintext
          html
        }
        internalTearSheets {
          plaintext
          html
        }
        internalOverviewDeck {
          plaintext
          html
        }
        internalDemoVideo {
          plaintext
          html
        }
        additionalLinksExternal {
          plaintext
          html
        }
        assetDescription {
          html
          plaintext
        }
        assetLinks {
          html
          plaintext
        }
        assetTeamEmailList
        modifiedDateTime
        assetName
        assetSystemId
        assetId
        deloitteAssetPortfolio {
          name
          description {
            html
          }
          icon {
            ... on ImageRef {
              _authorUrl
              _publishUrl
            }
          }
          isActive
          isDeleted
          displayOrder
        }
      }
      campaign {
        name
        description {
          html
        }
        icon {
          ... on ImageRef {
            _authorUrl
            _publishUrl
          }
        }
        isActive
        isDeleted
        displayOrder
      }
      maturityLevel {
        name
        description {
          html
        }
        icon {
          ... on ImageRef {
            _authorUrl
            _publishUrl
          }
        }
        isActive
        isDeleted
        displayOrder
      }
      ecoPartnerSolutions {
        ecoSystem {
          name
          description {
            html
          }
          icon {
            ... on ImageRef {
              _authorUrl
              _publishUrl
            }
          }
          isActive
          isDeleted
          displayOrder
        }
        title
        description {
          html
        }
        referenceLinkTitle
        referenceLink
        isActive
        isDeleted
      }
      businessValue {
        name
        description {
          html
        }
        icon {
          ... on ImageRef {
            _authorUrl
            _publishUrl
          }
        }
        isActive
        isDeleted
        displayOrder
      }
      salesCollateral {
        name
        description {
          html
          plaintext
        }
        referenceurl
        displayOrder
        isActive
        isDeleted
        icon {
          ... on ImageRef {
            _authorUrl
            _publishUrl
          }
        }
        document {
          ... on ImageRef {
            _authorUrl
            _publishUrl
            type
            _path
          }
          ... on DocumentRef {
            _authorUrl
            _publishUrl
            type
            _path
          }
          ... on MultimediaRef {
            _authorUrl
            _publishUrl
            type
            _path
          }
        }
      }
      supportingMaterials {
        title
        url
        document {
          ... on ImageRef {
            _authorUrl
            _publishUrl
            type
            _path
          }
          ... on DocumentRef {
            _authorUrl
            _publishUrl
            type
            _path
          }
          ... on MultimediaRef {
            _authorUrl
            _publishUrl
            type
            _path
          }
        }
        isActive
        isDeleted
        displayOrder
      }
    }
  }
}


`


export default {
  GET_CAPABILITY_BY_NAME
}