export const GET_HOME_DIGIPRINT_COMES_TO_LIFE = `query GetHomeDigiprintComesToLife{
  homeDigiprintComesToLifeItemsList(filter:{
    isActive:{
      _expressions:{
        value: true
      }
    }
  }){
    items{
      heading
      displayOrder
      step
      isActive
      description{
        plaintext
        html
      }
    }
  }
}`

export default {
  GET_HOME_DIGIPRINT_COMES_TO_LIFE
}